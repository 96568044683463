<template>
  <button
    :class="rootClasses"
    :disabled="props.disabled"
    v-bind="{
      type: 'button',
      ...$attrs,
    }"
    class="relative inline-flex justify-center items-center flex-nowrap focus:outline-none
      focus:ring-2 focus:ring-offset-2"
  >
    <AppTypography
      size="text-sm"
      weight="font-medium"
      :class="textClasses"
      class="flex flex-row items-center transition-opacity duration-100 ease-in-out
        whitespace-nowrap"
    >
      <slot>
        {{ props.text }}
      </slot>
    </AppTypography>
    <AppFadeTransition>
      <AppSpinner
        v-if="props.loading"
        size="sm"
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </AppFadeTransition>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import AppTypography from './AppTypography.vue'
import AppSpinner from './AppSpinner.vue'

import AppFadeTransition from '../mid-level/AppFadeTransition.vue'

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  radius: {
    type: String,
    validator: (val: string) => ['full', 'default'].includes(val),
    default: 'default',
  },
  size: {
    type: String,
    validator: (val: string) => ['sm', 'md', 'lg', 'custom'].includes(val),
    default: 'md',
  },
  variant: {
    type: String,
    validator: (val: string) =>
      [
        'custom',
        'fill-white',
        'fill-white-blue-accent',
        'fill-blue',
        'fill-red',
        'fill-blue-faded',
        'fill-gray-faded',
        'outline-gray',
        'outline-red',
        'outline-blue-accent',
        'outline-red-accent',
        'outline-inverted',
      ].includes(val),
    default: 'fill-blue',
  },
})

const rootClasses = computed(() => {
  return [
    ...{
      custom: [],
      'fill-white': [
        'border shadow-sm border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
      ],
      'fill-white-blue-accent': [
        'border shadow-sm border-gray-300 bg-white text-blue-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
      ],
      'fill-blue': [
        'border shadow-sm bg-blue-600 border-transparent text-white hover:bg-blue-700 focus:ring-blue-500',
      ],
      'fill-red': [
        'border shadow-sm bg-red-600 border-transparent text-white hover:bg-red-700 focus:ring-red-500',
      ],
      'fill-blue-faded': [
        'border shadow-sm bg-blue-100 border-transparent text-blue-700 hover:bg-blue-200 focus:ring-blue-500',
      ],
      'fill-gray-faded': [
        'border bg-gray-100 border-transparent text-gray-700 hover:bg-gray-200 focus:ring-gray-500',
      ],
      'outline-gray': [
        'border border-gray-300 text-gray-700 hover:bg-gray-50 focus:ring-gray-500',
      ],
      'outline-blue-accent': [
        'border border-gray-300 text-blue-600 hover:bg-gray-50 focus:ring-gray-500',
      ],
      'outline-red-accent': [
        'border border-gray-300 text-red-600 hover:bg-gray-50 focus:ring-gray-500',
      ],
      'outline-red': [
        'border border-red-400 text-red-500 hover:bg-red-50 focus:ring-red-500',
      ],
      'outline-inverted': [
        'border border-white/50 text-white hover:bg-white/20',
      ],
    }[props.variant],
    ...{
      full: ['rounded-full'],
      default: ['rounded-md'],
    }[props.radius],
    ...{
      sm: ['py-1 px-3'],
      md: ['py-2 px-4'],
      lg: ['py-3 px-4'],
      custom: [],
    }[props.size],
    ...{
      true: ['opacity-50 cursor-not-allowed'],
      false: [],
    }[String(props.disabled)],
  ]
})

const textClasses = computed(() => {
  return [
    {
      true: ['opacity-0'],
      false: [],
    }[String(props.loading)],
  ]
})
</script>
