<template>
  <AppStack direction="flex-col">
    <AppStack
      v-if="name"
      align-items="items-baseline"
      class="sticky top-0 pt-2 pb-2 gap-[6px] px-7 bg-bg-elevation-2 z-[1] border-b-[0.5px]
        border-solid border-t-0 border-l-0 border-r-0
        border-border-elevation-2-secondary"
    >
      <div
        class="bg-bg-elevation-2-breaker opacity-40 absolute top-0 left-0 w-full h-full"
      />
      <AppTypography
        :text="props.name"
        color="text-fg-elevation-2-primary"
        name="text-sm-5-bold"
        class="relative"
      />
      <AppTypography
        v-if="props.batch?.batch?.createdAt"
        :text="DateFnsFormat(new Date(props.batch.batch.createdAt), 'HH:mm')"
        color="text-fg-elevation-2-tertiary"
        name="text-sm-5-bold"
        class="relative"
      />
    </AppStack>
    <OrderModalMenuitemsList
      :items="props.batch.menuItems"
      class="flex-1"
      :quantity-changable="props.quantityChangable"
      :deletable="props.deletable"
      @increase-quantity="emit('increase-quantity', $event)"
      @decrease-quantity="emit('decrease-quantity', $event)"
      @delete="emit('delete', $event)"
      @add-comment="emit('add-comment', $event)"
    />
  </AppStack>
</template>

<script setup lang="ts">
import { format as DateFnsFormat } from 'date-fns'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import OrderModalMenuitemsList from './OrderModalSidebarMenuitemsList.vue'

const emit = defineEmits([
  'increase-quantity',
  'decrease-quantity',
  'delete',
  'add-comment',
])
const props = defineProps({
  batch: {
    type: Object,
    default: () => ({}),
  },
  name: {
    type: String,
    default: '',
  },
  quantityChangable: {
    type: Boolean,
    default: false,
  },
  deletable: {
    type: Boolean,
    default: false,
  },
})
</script>
