<template>
  <ion-content :style="sidebarStyles" :scroll-y="false">
    <AppStack
      v-if="props.loading"
      align-items="items-center"
      justify-content="justify-center"
      flex="flex-1"
      class="h-full"
    >
      <ion-spinner :style="{ '--color': 'var(--rf-fg-elevation-0-primary)' }" />
    </AppStack>
    <template v-else-if="props.order">
      <PromotionsModalSidebarHeader :order="props.order" />
      <AppStack
        v-if="
          Object.keys(props?.order?.appliedPromotions?.promotions || {}).length
        "
        direction="flex-col"
        class="h-full transition-transform duration-300 ease-in-out safe-bottom-max px-7"
      >
        <PromotionsModalSidebarPromotionItem
          v-for="item in promotionsList"
          :key="item._id"
          :title="item.title"
          :description="item.description"
          class="border-solid border-b-[1px] border-t-0 border-x-0 last:border-b-0
            border-border-elevation-3-secondary"
          @remove="onAppliedPromotionRemove(item._id)"
        />
      </AppStack>
      <OrderModalSidebarEmpty
        v-else
        :text="$t('promotions.sidebarPlaceholder')"
        class="absolute top-1/2 left-0 -translate-y-1/2"
      />
      <PromotionsModalSidebarFooter
        :order="order"
        class="absolute bottom-0 left-0 w-full safe-bottom-max"
        @done="onDone"
      />
    </template>
    <AppStack
      v-else
      align-items="items-center"
      justify-content="justify-center"
      flex="flex-1"
      class="h-full"
    >
      Could not load order
    </AppStack>
  </ion-content>
</template>

<script lang="ts">
export default { name: 'PromotionsModalSidebar' }
</script>

<script setup lang="ts">
import { IonSpinner, IonContent } from '@ionic/vue'
import { computed, PropType } from 'vue'
import { useEventBus } from '@vueuse/core'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import PromotionsModalSidebarHeader from './PromotionsModalSidebarHeader.vue'
import PromotionsModalSidebarPromotionItem from './PromotionsModalSidebarPromotionItem.vue'
import PromotionsModalSidebarFooter from './PromotionsModalSidebarFooter.vue'
import OrderModalSidebarEmpty from '../../OrderModal/OrderModalSidebar/OrderModalSidebarEmpty.vue'
import useStores, { type Stores } from '~/composables/useStores'
import useAppHelpers from '~/composables/useAppHelpers'

const props = defineProps({
  loading: {
    type: Boolean,
    required: false,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const { formatPrice } = useAppHelpers()
const { orders: OrdersStore, promotions: PromotionsStore } = useStores()
const bus = useEventBus<'promotions-modal-dismiss', unknown>('app')

const sidebarStyles = computed(() => ({
  '--padding-top': '30px',
  'min-width': '370px',
  'max-width': '370px',
  '--background': 'var(--rf-bg-elevation-2)',
  'border-right': '1px solid var(--rf-border-elevation-2-secondary)',
}))

const promotionsList = computed(() => {
  const ids = [...Object.keys(props.order?.appliedPromotions?.promotions || {})]

  if (!ids.length) return []

  return ids.map((id) => {
    const promotion = PromotionsStore.getFromStore(id)

    const discount =
      props?.order?.appliedPromotions?.promotions?.[
        promotion.value?._id
      ]?.discount.toFixed(2)

    return {
      _id: promotion.value?._id,
      title: promotion.value?.name,
      description: formatPrice(discount ? -discount : 0),
    }
  })
})

const onDone = () => {
  bus.emit('promotions-modal-dismiss')
}

const onAppliedPromotionRemove = (id) => {
  if (!props.order) return

  return OrdersStore.patch(props.order._id, {
    $pull: { promotionIds: id },
  }).catch(console.log)
}
</script>
