<template>
  <AppStack direction="flex-col">
    <template v-if="displayBatchedUi">
      <AppStack
        v-for="(batch, index) in Object.values(batches)"
        :key="batch.batch.id + batch.batch.createdAt"
        direction="flex-col"
      >
        <AppStack
          align-items="items-center"
          class="sticky top-0 py-2 gap-2 px-7 bg-bg-elevation-2 z-[1] border-b-[1px] border-solid
            border-t-0 border-l-0 border-r-0 border-border-elevation-2-secondary"
        >
          <div
            class="bg-bg-elevation-2-breaker opacity-40 absolute top-0 left-0 w-full h-full"
          />
          <AppTypography
            :text="`${$t('order.batch')} #${index + 1}`"
            color="text-fg-elevation-2-primary"
            name="text-sm-5-bold"
            class="relative"
          />
          <AppTypography
            text="16:08"
            color="text-fg-elevation-2-tertiary"
            name="text-sm-5-bold"
            class="relative"
          />
        </AppStack>
        <OrderModalMenuitemsList
          is-checkout
          :items="batch.menuItems"
          class="flex-1"
        />
      </AppStack>
    </template>
    <AppStack v-else direction="flex-col">
      <OrderModalMenuitemsList :items="menuItems" class="flex-1" />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import { type Stores } from '~/composables/useStores'
import useAppHelpers from '~/composables/useAppHelpers'
import { useAppStore } from '~/stores/app'
import OrderModalMenuitemsList from './OrderModalSidebarMenuitemsList.vue'

const emit = defineEmits(['start-batch-tap'])
const props = defineProps({
  batch: {
    type: Object,
    default: null,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result']>,
    required: true,
  },
})

const { mapMenuItem } = useAppHelpers()
const AppStore = useAppStore()

const displayBatchedUi = computed(() => {
  if (props.order?.menuItems.length && !props.order.batches?.length) {
    return false
  }

  return AppStore?.selectedStorefront.inHouseService.setup.batches
})

const batches = computed(() => {
  const batchesAsMap = props.order.batches.reduce(
    (acc: Record<string, Stores['orders']['Result']['batches'][0]>, batch) => {
      if (!acc[batch.id]) acc[batch.id] = batch

      return acc
    },
    {},
  )

  const menuItemsByBatch = props.order?.menuItems.reduce(
    (
      acc: Record<
        string,
        {
          batch: any
          menuItems: any[]
        }
      >,
      menuItem,
    ) => {
      if (!menuItem || !menuItem?.batchId) return acc

      if (!acc[menuItem?.batchId])
        acc[menuItem?.batchId] = {
          batch: null,
          menuItems: [],
        }

      acc[menuItem?.batchId].batch = batchesAsMap[menuItem.batchId]
      acc[menuItem?.batchId].menuItems.push(
        mapMenuItem(menuItem, { short: true }),
      )

      return acc
    },
    {},
  )

  return menuItemsByBatch
})

const menuItems = computed(() => {
  return props.order?.menuItems.map((menuItem) =>
    mapMenuItem(menuItem, { short: true }),
  )
})
</script>
