<template>
  <ion-app>
    <AppStack
      v-if="initialAuthenticationInProgress"
      direction="flex-col"
      flex="flex-1"
      class="h-full"
    >
      <AppStack
        align-items="items-center"
        justify-content="justify-center"
        flex="flex-1"
        class="h-full"
      >
        <ion-spinner
          :style="{ '--color': 'var(--rf-fg-elevation-0-primary)' }"
        />
      </AppStack>
    </AppStack>
    <template v-else>
      <AppFadeTransition
        duration="duration-200"
        translate-y="translate-y-[20px]"
      >
        <TabsLayout v-if="!loginModalIsOpen" ref="rootElementRef" />
      </AppFadeTransition>
      <ion-modal
        :is-open="loginModalIsOpen"
        :backdrop-dismiss="false"
        :style="{
          '--backdrop-opacity': 0.9,
          '--min-height': '100%',
          '--min-width': '100%',
          '--border-radius': '16px 16px 0px 0px',
          '--background': 'var(--rf-bg-elevation-2)',
        }"
      >
        <LoginPage @login="AppStore.setLoginSheetVisibility(false)" />
      </ion-modal>
      <OrderModalLayout
        v-if="rootElementRef"
        ref="orderModalLayoutRef"
        :order-id="orderModalActiveOrderId"
        :object-id="orderModalActiveObjectId"
        :order-type="orderModalActiveType"
        :is-open="orderModalIsOpen"
        :presenting-element="rootElementRef.$el"
        @dismiss="onOrderModalDismiss"
        @update="onOrderModalUpdate"
      />
      <QuickOrderCheckoutModalLayout
        v-if="rootElementRef"
        :order-id="quickOrderCheckoutModalActiveOrderId"
        :is-open="quickOrderCheckoutModalIsOpen"
        :presenting-element="rootElementRef.$el"
        @dismiss="onQuickOrderCheckoutModalDismiss"
        @update="onQuickOrderCheckoutModalUpdate"
      />
      <PromotionsModalLayout
        v-if="orderModalLayoutRef"
        :order-id="propmotionsModalActiveOrderId"
        :object-id="propmotionsModalActiveObjectId"
        :is-open="propmotionsModalIsOpen"
        :presenting-element="orderModalLayoutRef.$el"
        @dismiss="onPromotionsModalDismiss"
        @update="onOrderModalUpdate"
      />
      <PickupModal
        :modal-id="PickupModalId"
        :order-id="pickupModalActiveOrderId"
      />
      <ion-menu
        content-id="menu-content"
        side="end"
        menu-id="menu-id"
        :style="{
          '--width': '450px',
          '--backdrop-opacity': 0.9,
        }"
        @ion-did-close="onMenuClose"
      >
        <StockOrderModal
          v-if="productsWithLowStock || stockOrderId"
          v-model="productsWithLowStock"
          :stock-order-id="stockOrderId"
          @dismiss="onMenuClose"
        />
        <StockCreateGoodsModal
          v-else-if="goodsId"
          :goods-id="goodsId"
          @dismiss="onMenuClose"
        />
      </ion-menu>
      <ion-page id="menu-content" class="pointer-events-none" />
    </template>
    <IonAlert
      :is-open="reloadAlertIsOpen"
      header="New version avaliable"
      message="Update to new version?"
      :buttons="alertButtons"
    />
  </ion-app>
</template>

<script setup lang="ts">
import {
  IonApp,
  menuController,
  IonModal,
  IonSpinner,
  IonMenu,
  IonPage,
  IonAlert,
  toastController,
} from '@ionic/vue'
import { useEventBus } from '@vueuse/core'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { ref, computed, watch, onMounted } from 'vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import usePreferredColorScheme from '@restify/packages/composables/usePreferredColorScheme'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'
import useTime from '@restify/packages/composables/useTime'
import { useProvideAppUIState } from '@restify/packages/composables/useAppUiState'
import { reloadStoredEntities as HelpersAppReloadStoredEntities } from '@restify/packages/helpers/app'
import { addEventListener as HelpersVisibilityAddEventListener } from '@restify/packages/helpers/visibility'
import { getUniqueValues } from '@restify/packages/helpers/array'
import OrderModalLayout from '~/views/OrderModalLayout.vue'
import PromotionsModalLayout from '~/views/PromotionsModalLayout.vue'
import LoginPage from '~/views/LoginPage.vue'
import TabsLayout from '~/views/TabsLayout.vue'
import { differenceInSeconds } from 'date-fns'
import useFeathers from '~/composables/useFeathers'
import useStores, { type Stores } from '~/composables/useStores'
import { useAuthStore } from '~/stores/auth'
import { useAppStore } from '~/stores/app'
import PickupModal from './views/Pickup/PickupModal.vue'
import StockOrderModal from './views/Stock/StockOrderModal.vue'
import StockCreateGoodsModal from './views/Stock/StockCreateGoodsModal.vue'
import QuickOrderCheckoutModalLayout from './views/QuickOrderPage/QuickOrderCheckoutModalLayout.vue'

const { io: socket } = useFeathers()
const { apps: AppsStore, menus: MenusStore } = useStores()
const AuthStore = useAuthStore()
const AppStore = useAppStore()

socket.on('disconnect', function () {
  AppStore.setNetworkConnectionStatus('reconnecting')
  bus.emit('app-hidden')
})

socket.on('reconnecting', function () {
  AppStore.setNetworkConnectionStatus('reconnecting')
})

socket.on('connect', function () {
  AppStore.setNetworkConnectionStatus('connected')
  bus.emit('app-visible')
})

const bus = useEventBus<
  | 'order-tap'
  | 'table-tap'
  | 'order-modal-dismiss'
  | 'app-visible'
  | 'app-hidden',
  unknown
>('app')

bus.on(onAppEvent)

useProvideAppUIState()

HelpersVisibilityAddEventListener((status: boolean) => {
  if (status) {
    bus.emit('app-hidden')
  } else if (AppStore.networkConnectionStatus === 'connected') {
    bus.emit('app-visible')
  }
})

const { needRefresh, updateServiceWorker } = useRegisterSW()

const alertButtons = [
  {
    text: 'Reload',
    role: 'confirm',
    handler: () => {
      return updateServiceWorker()
    },
  },
]

const { time } = useTime()
const appColorScheme = usePreferredColorScheme()

const authInProgress = ref(false)
const reloadAlertIsOpen = ref(false)

const rootElementRef = ref<unknown | null>(null)

const orderModalIsOpen = ref(false)
const orderModalActiveOrderId = ref<string | null>(null)
const orderModalActiveObjectId = ref<string | null>(null)
const orderModalActiveType = ref<string | null>(null)
const orderModalLayoutRef = ref<unknown | null>(null)

const propmotionsModalIsOpen = ref(false)
const propmotionsModalActiveOrderId = ref<string | null>(null)
const propmotionsModalActiveObjectId = ref<string | null>(null)
const pickupModalActiveOrderId = ref<string | null>(null)

const quickOrderCheckoutModalIsOpen = ref(false)
const quickOrderCheckoutModalActiveOrderId = ref<string | null>(null)

const initialAuthenticationInProgress = ref(true)
const productsWithLowStock = ref(null)
const stockOrderId = ref<null | string>(null)
const goodsId = ref<null | string>(null)

const closeAllModals = () => {
  orderModalIsOpen.value = false
  orderModalActiveOrderId.value = null
  orderModalActiveObjectId.value = null
  orderModalActiveType.value = null

  propmotionsModalIsOpen.value = false
  propmotionsModalActiveOrderId.value = null
  propmotionsModalActiveObjectId.value = null
  pickupModalActiveOrderId.value = null

  quickOrderCheckoutModalIsOpen.value = false
  quickOrderCheckoutModalActiveOrderId.value = null
}

const loginModalIsOpen = computed(() => {
  return AppStore.loginSheetVisibility
})

const differenceSinceLastActivity = computed(() => {
  const seconds = String(
    differenceInSeconds(time.value, AuthStore.lastActivity),
  )

  return parseInt(seconds.length === 1 ? '0' : seconds.split('')[0])
})

const authenticate = async (data = {}) => {
  const urlToken = null
  const token = window.localStorage.getItem('accessToken') || urlToken

  if (token) {
    authInProgress.value = true

    await AuthStore.authenticate({
      strategy: 'jwt',
      accessToken: token,
      ...data,
    })

    authInProgress.value = false
  }
}

const reloadStoredEntities = () => {
  return HelpersAppReloadStoredEntities(AuthStore, AppsStore).catch(
    async (error) => {
      const toast = await toastController.create({
        message: `Error: ${error.name}. ${error.message}`,
        duration: 3000,
        cssClass: 'toast text-lg-7-semibold',
        position: 'bottom',
        mode: 'ios',
      })

      await toast.present()
    },
  )
}

function onAppEvent(
  event:
    | 'order-tap'
    | 'table-tap'
    | 'new-order-tap'
    | 'order-modal-dismiss'
    | 'quick-order-checkout-tap'
    | 'quick-order-checkout-dismiss'
    | 'promotions-tap'
    | 'promotions-modal-dismiss'
    | 'pickup-order-tap'
    | 'pickup-modal-dismiss'
    | 'new-stock-order-tap'
    | 'stock-order-tap'
    | 'goods-item-tap'
    | 'app-hidden'
    | 'app-visible',
  payload: Stores['orders']['Result'] | unknown,
) {
  if (event === 'order-tap') {
    const order = payload as Stores['orders']['Result']

    orderModalIsOpen.value = true
    orderModalActiveOrderId.value = orderModalIsOpen.value ? order._id : null
    orderModalActiveObjectId.value = null
  } else if (event === 'table-tap') {
    const {
      object: { object },
    } = payload as {
      object: { object: Stores['storefrontPlaces']['Result'] }
    }

    orderModalIsOpen.value = true
    orderModalActiveObjectId.value = object._id
    orderModalActiveOrderId.value = null
    orderModalActiveType.value = 'inHouseService'
  } else if (event === 'new-order-tap') {
    const { type, orderId } = payload as { type: string; orderId: string }

    orderModalIsOpen.value = true
    orderModalActiveOrderId.value = orderId
    orderModalActiveObjectId.value = null
    orderModalActiveType.value = type
  } else if (event === 'quick-order-checkout-tap') {
    const order = payload as Stores['orders']['Result']

    quickOrderCheckoutModalIsOpen.value = true
    quickOrderCheckoutModalActiveOrderId.value = order._id
  } else if (event === 'promotions-tap') {
    const order = payload as Stores['orders']['Result']

    propmotionsModalIsOpen.value = true
    propmotionsModalActiveOrderId.value = propmotionsModalIsOpen.value
      ? order._id
      : null
  } else if (event === 'pickup-order-tap') {
    const { order } = payload as { order: Stores['orders']['Result'] }
    pickupModalActiveOrderId.value = order._id
    menuController.open(PickupModalId)
  } else if (event === 'new-stock-order-tap') {
    productsWithLowStock.value = payload.productsWithLowStock
    menuController.open('menu-id')
  } else if (event === 'stock-order-tap') {
    stockOrderId.value = payload.id
    menuController.open('menu-id')
  } else if (event === 'goods-item-tap') {
    goodsId.value = payload.id
    menuController.open('menu-id')
  } else if (event === 'pickup-modal-dismiss') {
    return onPickupModalDismiss()
  } else if (event === 'order-modal-dismiss') {
    return onOrderModalDismiss()
  } else if (event === 'quick-order-checkout-dismiss') {
    return onQuickOrderCheckoutModalDismiss()
  } else if (event === 'app-visible') {
    reloadStoredEntities()
  }
}

const onOrderModalDismiss = () => {
  orderModalIsOpen.value = false
  orderModalActiveOrderId.value = null
  orderModalActiveObjectId.value = null
  orderModalActiveType.value = null
}

const onOrderModalUpdate = ({
  orderId,
  objectId,
}: {
  orderId?: string
  objectId?: string
}) => {
  if (orderId) {
    orderModalActiveOrderId.value = orderId
  }

  if (objectId) {
    orderModalActiveObjectId.value = objectId
  }
}

const onQuickOrderCheckoutModalDismiss = () => {
  quickOrderCheckoutModalIsOpen.value = false
  quickOrderCheckoutModalActiveOrderId.value = null
}
const onQuickOrderCheckoutModalUpdate = ({ orderId }: { orderId?: string }) => {
  if (orderId) {
    orderModalActiveOrderId.value = orderId
  }
}

const onPromotionsModalDismiss = () => {
  propmotionsModalIsOpen.value = false
  propmotionsModalActiveOrderId.value = null
  propmotionsModalActiveObjectId.value = null
}

const PickupModalId = 'pickup-modal'

const onPickupModalDismiss = () => {
  menuController.close(PickupModalId)
  pickupModalActiveOrderId.value = null
}

const onMenuClose = () => {
  menuController.close('menu-id')
  productsWithLowStock.value = null
  stockOrderId.value = null
  goodsId.value = null
}

watch(differenceSinceLastActivity, () => {
  const role = AuthStore.reactiveUser?.profile?.role

  if (
    role === 'waiter' &&
    differenceSinceLastActivity.value >= 5 &&
    !loginModalIsOpen.value
  ) {
    closeAllModals()
    AppStore.setLoginSheetVisibility(true)
  }
})

watch(
  computed(() => AuthStore.isUserAuthenticated),
  (newValue) => {
    if (!newValue) AppStore.setLoginSheetVisibility(true)
  },
)

watch(
  computed(() => AuthStore.reactiveUser),
  (newValue) => {
    const locale = newValue?.profile.preferredAppLanguage

    if (locale) {
      AppStore.setLocale(locale)
    }
  },
)

watch(
  computed(() => appColorScheme.value),
  (newValue) => {
    if (!Capacitor.isNativePlatform()) return
    if (newValue === 'light') {
      StatusBar.setStyle({ style: Style.Light })
    } else {
      StatusBar.setStyle({ style: Style.Dark })
    }
  },
  { immediate: true },
)

watch(
  computed(() => needRefresh.value),
  (newVal) => newVal && (reloadAlertIsOpen.value = true),
  { immediate: true },
)

onMounted(() => {
  initialAuthenticationInProgress.value = true

  return authenticate()
    .then(() => {
      if (!AuthStore.isUserAuthenticated) {
        AppStore.setLoginSheetVisibility(true)
      } else {
        AppStore.setLoginSheetVisibility(false)
      }

      initialAuthenticationInProgress.value = false
    })
    .then(() => fetchMenus())
    .catch(console.log)
})

const fetchMenus = () => {
  return MenusStore.find({
    query: {
      _id: {
        $in: getUniqueValues([
          ...AppStore.selectedStorefront.inHouseService.menuIds,
          ...AppStore.selectedStorefront.inHouseDelivery.menuIds,
          ...AppStore.selectedStorefront.delivery.menuIds,
          ...AppStore.selectedStorefront.pickup.menuIds,
        ]),
      },
      $skip: 0,
      $limit: 100,
    },
  })
}
</script>
