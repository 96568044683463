<template>
  <div class="to-transparent" :class="rootClasses">
    <slot />
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

const GeneralPropType = [String, Array] as PropType<string | string[]>

export default defineComponent({
  name: 'StationFadeGradient',
  props: {
    color: {
      type: GeneralPropType,
      default: 'from-bg-elevation-2',
    },
    direction: {
      type: GeneralPropType,
      default: 'bg-gradient-to-l',
    },
  },
  setup(props) {
    const rootClasses = computed(() => [props.direction, props.color])
    return {
      rootClasses,
    }
  },
})
</script>
