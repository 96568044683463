<template>
  <AppStack
    direction="row"
    align-items="items-start"
    justify-content="justify-around"
    class="px-10 gap-5"
  >
    <AppStack
      v-if="loading"
      align-items="items-center"
      justify-content="justify-center"
      flex="flex-1"
      direction="flex-col"
      class="h-full gap-3"
    >
      <ion-spinner
        :style="{ '--color': 'var(--rf-fg-elevation-0-tertiary)' }"
        class="w-10 h-10"
      />
      <AppTypography
        text="Loading board.."
        name="text-lg-6-medium"
        color="text-fg-elevation-0-tertiary"
        class="mt-2"
      />
    </AppStack>
    <template v-else>
      <AppStack
        v-for="user in users"
        :key="user.email"
        direction="flex-col"
        class="min-w-0 flex-1 relative select-none transition-opacity duration-150 ease-in-out
          gap-8"
      >
        <AppStack
          direction="flex-col"
          justify-content="justify-center"
          @click="emit('login-user', user.email)"
        >
          <AppStack
            direction="flex-col"
            align-items="items-center"
            justify-content="justify-center"
            class="bg-bg-elevation-0-alt text-center rounded pb-3"
          >
            <span class="text-[40px]">{{ user.profile.emoji }}</span>
            <AppTypography
              :text="user.profile.firstName"
              size="text-lg-6-semibold"
              color="text-fg-elevation-0-primary"
              class="text-center -mt-1"
            />
          </AppStack>
        </AppStack>
        <AppStack
          v-for="order in ordersByUser[user._id]"
          :key="order._id"
          class="gap-4"
          direction="flex-col"
        >
          <AppStack
            align-items="items-baseline"
            class="sticky bg-bg-elevation-0"
          >
            <AppTypography
              :text="getOrderPlaceName(order)"
              size="text-lg-6-semibold"
              color="text-fg-elevation-0-primary"
              class="mr-2"
            />
            <AppTypography
              :text="'#' + order.publicId"
              size="text-lg-6-semibold"
              color="text-fg-elevation-0-tertiary"
              class="uppercase"
            />
            <AppStack
              class="flex-1"
              align-items="flex-baseline"
              justify-content="justify-end"
            >
              <AppTypography
                :text="getOrderTimeText(order.createdAt)"
                size="text-lg-6-medium"
                color="text-fg-elevation-0-tertiary"
                class="ml-1"
              />
              <AppTypography
                v-if="order.type === 'pickup'"
                :text="order.pickupTime"
                size="text-lg-6-semibold"
                color="text-fg-yellow"
                class="ml-1"
              />
            </AppStack>
          </AppStack>
          <AppStack direction="flex-col" class="gap-3">
            <AppLoginOrderBoardItem
              v-for="menuItem in order.menuItems"
              :order-id="order._id"
              :menu-item="menuItem"
            />
          </AppStack>
        </AppStack>
      </AppStack>
    </template>
  </AppStack>
</template>

<script setup lang="ts">
import { IonSpinner } from '@ionic/vue'
import { PropType, onMounted, onDeactivated, computed, ref } from 'vue'
import { getUniqueValues as HelpersArrayGetUniqueValues } from '@restify/packages/helpers/array'
import {
  subMinutes as DateFnsSubMinutes,
  format as DateFnsFormat,
  isSameDay as DateFnsIsSameDay,
} from 'date-fns'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppLoginOrderBoardItem from './AppLoginOrderBoardItem.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { useAppStore } from '~/stores/app'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['add-user', 'login-user', 'remove-user'])
defineProps({
  users: {
    type: Object as PropType<Record<string, Stores['users']['Result']>>,
    default: () => [],
  },
})

const accessibleUnitIds = ['C3l-fhRLhBF0Z1EAgT5Tc', 'nz3NzqitiUm6VJh_epmgS']
const { t } = useI18n()
const {
  orders: OrdersStore,
  storefrontPlaces: StorefrontPlacesStore,
  menus: MenusStore,
} = useStores()
const AppStore = useAppStore()

const loading = ref(false)
const time = ref(Date.now())
let interval: ReturnType<typeof setInterval> | null = null

const ordersByUser = computed(() => {
  const until = time.value
  const from = DateFnsSubMinutes(time.value, 240).getTime()

  return OrdersStore.findInStore({
    'menuItems.status.name': 'ready',
    'menuItems.status.readyAt': {
      $lte: until,
      $gte: from,
    },
  }).reduce((acc: Record<string, Stores['orders']['Result'][]>, order) => {
    const user = order.value?.staff.find((staff) => {
      return staff.role === 'waiter'
    })

    if (!user?.staffId || !order.value) return acc

    if (!acc[user?.staffId]) acc[user?.staffId] = []

    const menuItems = order.value.menuItems.filter((item) => {
      return (
        ['ready'].includes(item.status.name) &&
        item.menuItemId in accessibleMenuItemIds.value[order.value.type] &&
        item.status.readyAt &&
        item.status.readyAt <= until &&
        item.status.readyAt >= from
      )
    })

    if (!menuItems.length) return acc

    acc[user?.staffId].push({
      ...order.value,
      menuItems,
    })

    return acc
  }, {})
})

const accessibleMenuItemIds = computed(() => {
  const activeStorefront = AppStore.selectedStorefront
  const userAccessibleUnitIds = accessibleUnitIds
  const fallbackUnit = activeStorefront.units.find((unit) => unit.fallback)

  const userAccessibleUnitMap = userAccessibleUnitIds.reduce(
    (acc, unitId: string) => {
      const unit = activeStorefront.units.find((unit) => unit.id === unitId)

      if (unit) acc[unit.id] = unit

      return acc
    },
    {},
  )

  const storefrontToMenuItemsMapper = (type) => (acc, menuId) => {
    const menu = MenusStore.getFromStore(menuId).value

    return {
      ...acc,
      ...(menu?.menuItems || []).reduce((acc2, menuItem) => {
        const categoryUnit =
          activeStorefront[type]?.unitMapping?.categories?.[menuItem.categoryId]
        const menuItemUnit =
          activeStorefront[type]?.unitMapping?.menuItems?.[menuItem.menuItemId]
        let finalUnit = menuItemUnit || categoryUnit

        if (!categoryUnit && !menuItemUnit && fallbackUnit) {
          finalUnit = fallbackUnit.id
        }

        if (
          userAccessibleUnitMap[finalUnit] ||
          !Object.keys(userAccessibleUnitMap).length
        ) {
          acc2[menuItem.menuItemId] = menuItem
        }

        return acc2
      }, {}),
    }
  }

  return {
    inHouseService: activeStorefront.inHouseService.menuIds.reduce(
      storefrontToMenuItemsMapper('inHouseService'),
      {},
    ),
    inHouseDelivery: activeStorefront.inHouseDelivery.menuIds.reduce(
      storefrontToMenuItemsMapper('inHouseDelivery'),
      {},
    ),
    delivery: activeStorefront.delivery.menuIds.reduce(
      storefrontToMenuItemsMapper('delivery'),
      {},
    ),
    pickup: activeStorefront.pickup.menuIds.reduce(
      storefrontToMenuItemsMapper('pickup'),
      {},
    ),
  }
})

onMounted(() => {
  interval = setInterval(() => {
    time.value = Date.now()
  }, 1000)
})

onDeactivated(() => {
  if (interval !== null) {
    clearInterval(interval)
  }
})

const getOrderPlaceName = (order: Stores['orders']['Result']) => {
  if (order.type === 'pickup') {
    return 'Pickup'
  } else if (order.type === 'delivery') {
    return 'Delivery'
  } else if (order.type === 'inHouseDelivery') {
    return 'In House Delivery'
  } else if (order.type === 'inHouseService') {
    const placeId = order.storefrontPlaceIds?.[0]

    if (placeId) {
      const place: any = StorefrontPlacesStore.getFromStore(placeId).value
      return place?.config.name
        ? `${t('kitchen.table')} ${place?.config.name}`
        : t('kitchen.table')
    } else {
      return t('kitchen.noTable')
    }
  }
}

const getOrderTimeText = (createdAt) => {
  const sameDay = DateFnsIsSameDay(new Date(createdAt), new Date())

  if (!sameDay) return DateFnsFormat(new Date(createdAt), 'do LLL, kk:mm')

  return DateFnsFormat(new Date(createdAt), 'kk:mm')
}

const init = (silent = false) => {
  if (!silent) loading.value = true

  return Promise.all([
    MenusStore.find({
      query: {
        _id: {
          $in: HelpersArrayGetUniqueValues([
            ...AppStore.selectedStorefront.inHouseService.menuIds,
            ...AppStore.selectedStorefront.inHouseDelivery.menuIds,
            ...AppStore.selectedStorefront.delivery.menuIds,
            ...AppStore.selectedStorefront.pickup.menuIds,
          ]),
        },
        $skip: 0,
        $limit: 100,
      },
    }),
    StorefrontPlacesStore.find({
      query: {
        storefrontId: AppStore.selectedStorefront._id,
        orderType: 'inHouseService',
        $skip: 0,
        $limit: 100,
      },
    }),
    OrdersStore.find({
      query: {
        'status.name': {
          $in: ['created', 'checking-out', 'checked-out'],
        },
        $skip: 0,
        $limit: 100,
        $sort: {
          createdAt: -1,
        },
      },
    }),
  ]).finally(() => {
    loading.value = false
  })
}

onMounted(() => init(true))
</script>
