<template>
  <AppStack
    class="w-full"
    direction="flex-col"
    justify-content="justify-between"
  >
    <AppStack
      class="w-full pt-[31px] px-7 pb-3 border-solid border-t-0 border-x-0
        border-border-elevation-2-secondary gap-5"
      justify-content="justify-between"
      align-items="items-center"
    >
      <div class="relative w-full">
        <input
          id="input"
          ref="searchRef"
          v-model="textInput"
          type="text"
          class="text-lg-7-medium text-fg-elevation-2-primary pl-[60px] py-[12.5px] pr-5
            rounded-xl bg-bg-elevation-2-alt border-0 w-full focus:ring-0"
          :placeholder="$t('promotions.searchInputPlaceholder')"
        />
        <AppIcon
          icon="search"
          stroke-width="1.5"
          color="text-fg-elevation-2-tertiary"
          class="w-8 h-8 absolute left-4 top-[50%] translate-y-[-50%]"
        />
      </div>
      <AppTypography
        name="text-lg-7-medium"
        color="text-fg-blue"
        :text="$t('promotions.searchCancel')"
        @click="() => emit('cancel-tap')"
      />
    </AppStack>
    <template v-if="textInput">
      <AppStack
        v-if="loading"
        align-items="items-center"
        justify-content="justify-center"
        flex="flex-1"
        class="h-full"
      >
        <ion-spinner
          :style="{ '--color': 'var(--rf-fg-elevation-0-primary)' }"
        />
      </AppStack>
      <AppStack
        v-else
        class="w-full h-full pt-10 px-[100px]"
        flex="1"
        direction="flex-col"
        justify-content="justify-start"
        align-items="items-start"
      >
        <AppTypography
          v-if="promotions.length === 0"
          name="text-lg-7-medium"
          color="text-fg-elevation-2-secondary"
          text="No items found."
          class="w-full text-center"
        />
        <template v-else>
          <AppTypography
            name="text-lg-7-medium"
            color="text-fg-elevation-2-secondary"
            text="Search results"
          />
          <AppStack
            direction="flex-col"
            class="w-full mt-3 border-[1px] border-solid border-border-elevation-3-secondary
              rounded-lg overflow-hidden"
          >
            <PromotionsModalMatchedPromotion
              v-for="promotion in promotions"
              :key="promotion._id"
              :title="promotion.name"
              :description="promotion.description"
              :applied="
                promotion._id in
                (props.order?.appliedPromotions?.promotions || {})
              "
              class="border-solid last:border-b-0 border-b-[1px] border-t-0 border-l-0 border-r-0
                border-border-elevation-3-secondary"
              @apply="onPromotionApply(promotion._id)"
            />
          </AppStack>
        </template>
      </AppStack>
    </template>
    <AppStack
      v-else
      class="w-full h-full px-[100px]"
      flex="1"
      direction="flex-col"
      justify-content="justify-center"
      align-items="items-center"
    >
      <AppIcon
        icon="search"
        stroke-width="1.5"
        color="text-fg-elevation-2-tertiary"
        class="w-16 h-16 opacity-30"
      />
      <AppTypography
        class="max-w-[280px] text-center"
        name="text-lg-7-medium"
        color="text-fg-elevation-2-secondary"
        :text="$t('promotions.searchPlaceholder')"
      />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { IonSpinner } from '@ionic/vue'
import { computed, onMounted, ref, watch, PropType } from 'vue'
import LodashDebounce from 'lodash.debounce'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import PromotionsModalMatchedPromotion from '../PromotionsModalMatchedPromotion.vue'
import useStores, { type Stores } from '~/composables/useStores'

const emit = defineEmits(['cancel-tap'])

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const { orders: OrdersStore, promotions: PromotionsStore } = useStores()

const textInput = ref('')
const promotions = ref([] as Stores['promotions']['Result'][])
const loading = ref(false)
const searchRef = ref<HTMLElement | null>(null)

const fetchData = async () => {
  if (!props.order) return

  // TODO: add error handling
  const result = await PromotionsStore.find({
    query: {
      name: {
        $regex: textInput.value,
        $options: 'i',
      },
      orderType: props.order.type,
      status: 'public',
    },
  })

  promotions.value = result.data.map((item) => item.value)
  loading.value = false

  return result
}

const debouncedFetchData = LodashDebounce(() => fetchData(), 500)

const onPromotionApply = (id: string) => {
  if (!props.order?._id) return

  return OrdersStore.patch(props.order._id, {
    ...(!props.order.promotionIds.includes(id)
      ? { $push: { promotionIds: id } }
      : {}),
  })
}

watch(
  computed(() => textInput.value),
  (newVal) => {
    if (!newVal) return

    loading.value = true

    return debouncedFetchData()
  },
)

onMounted(() => {
  if (searchRef.value) {
    searchRef.value.focus()
  }
})
</script>
