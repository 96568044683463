<template>
  <IonContent
    :scroll-x="false"
    :scroll-y="true"
    :style="contentStyles"
    ref="slide"
    class="flex-1"
  >
    <AppStack ref="slideContent" direction="flex-col" class="flex-1">
      <OrderModalSidebarHeader
        :order="props.order"
        :injected-object-id="props.injectedObjectId"
        class="sticky top-0"
      />
      <template v-if="order">
        <AppStack
          :style="actionsStyles"
          direction="flex-col"
          class="transition-transform duration-300 ease-in-out safe-bottom-max flex-1"
        >
          <OrderModalMenuItemsWrapper
            :order="order"
            :batch-items="menuItems"
            :is-menu-items="props.isMenuItems"
            class="flex-1"
            @start-batch-tap="emit('start-batch-tap')"
            @increase-quantity="emit('increase-quantity', $event)"
            @decrease-quantity="emit('decrease-quantity', $event)"
            @delete="emit('delete-batch-item', id)"
            @add-comment="onComment"
          />
          <div class="h-[180px] min-h-[180px]" />
          <div
            :class="
              actionsAreShown
                ? ['opacity-60']
                : ['opacity-0', 'pointer-events-none']
            "
            class="absolute top-0 left-0 w-full h-full z-[6] bg-bg-elevation-2 transition-opacity
              duration-200 ease-in-out"
            @click="actionsAreShown && emit('dismiss-actions')"
          />
        </AppStack>
        <div
          :style="actionsStyles"
          class="transition-transform duration-300 ease-in-out z-[10] sticky bottom-0 left-0
            w-[370px] safe-bottom-max bg-bg-elevation-2"
        >
          <div
            class="absolute top-[-100px] left-0 w-full h-[100px] pointer-events-none
              bg-gradient-to-b to-bg-elevation-2 from-transparent"
          />
          <OrderModalFooter
            :order="order"
            :batched-ui="displayBatchedUi"
            :batch-items="(!displayBatchedUi && menuItems) || []"
            :send-to-work-in-progress="sendToWorkInProgress"
            @actions-tap="emit('show-actions')"
            @clear-tap="emit('clear-batch')"
            @send-tap="onSendTap"
            @checkout-tap="emit('checkout-tap')"
          />
          <div
            :class="
              actionsAreShown
                ? ['opacity-60']
                : ['opacity-0', 'pointer-events-none']
            "
            class="absolute top-0 left-0 w-full h-full z-[10] bg-bg-elevation-2 transition-opacity
              duration-200 ease-in-out"
            @click="actionsAreShown && emit('dismiss-actions')"
          />
        </div>
      </template>
    </AppStack>
  </IonContent>
</template>

<script setup lang="ts">
import { ref, computed, type PropType } from 'vue'
import { alertController, IonContent } from '@ionic/vue'
import { cleanCopy as HelpersObjectCleanCopy } from '@restify/packages/helpers/object'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import { useBottomScroll } from '@restify/packages/composables/useBottomScroll'
import OrderModalSidebarHeader from './OrderModalSidebarHeader.vue'
import OrderModalMenuItemsWrapper from './OrderModalSidebarMenuItemsWrapper.vue'
import OrderModalFooter from './OrderModalSidebarFooter.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { useAppStore } from '~/stores/app'
import useAppHelpers from '~/composables/useAppHelpers'

const emit = defineEmits([
  'start-batch-tap',
  'checkout-tap',
  'dismiss-actions',
  'show-actions',
  'clear-batch',
  'increase-quantity',
  'decrease-quantity',
  'delete-batch-item',
  'update-batch-item',
])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: undefined,
  },
  batchMenuItems: {
    type: Object,
    default: () => ({}),
  },
  injectedObjectId: {
    type: String,
    default: undefined,
  },
  injectedIsOpen: {
    type: Boolean,
    default: false,
  },
  actionsAreShown: {
    type: Boolean,
    default: false,
  },
  actionsStyles: {
    type: Object,
    default: () => ({}),
  },
  isMenuItems: {
    type: Boolean,
    default: false,
  },
})

const { orders: OrdersStore } = useStores()
const AppStore = useAppStore()
const { mapMenuItem } = useAppHelpers()

const slide = ref<null | HTMLElement>(null)
const slideContent = ref<null | HTMLElement>(null)
const sendToWorkInProgress = ref(false)

useBottomScroll(slide, slideContent)

const contentStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
}))

const menuItems = computed(() =>
  Object.values(props.batchMenuItems).map((item) => ({
    ...mapMenuItem(item),
    _id: item._id,
    status: null,
  })),
)

const displayBatchedUi = computed(() => {
  if (!props.order) return false

  if (props.order.menuItems.length && !props.order?.batches?.length) {
    return false
  }

  return AppStore?.selectedStorefront.inHouseService.setup.batches
})

const onSendTap = () => {
  if (
    !Object.keys(props.batchMenuItems).length ||
    !props.order?._id ||
    props.order.status.name !== 'created'
  ) {
    return
  }

  sendToWorkInProgress.value = true

  const update: any = {
    $push: {
      menuItems: {
        $each: [],
      },
    },
  }

  Object.keys(props.batchMenuItems).forEach((id) => {
    update.$push.menuItems.$each.push({
      ...props.batchMenuItems[id],
      status: {
        ...props.batchMenuItems[id].status,
        name: 'idle',
        idleAt: new Date().getTime(),
      },
    })
  })

  return OrdersStore.patch(props.order?._id, update)
    .then(() => emit('clear-batch'))
    .finally(() => {
      sendToWorkInProgress.value = false
    })
}

const onComment = async (index: number, callback) => {
  const id = Object.values(props.batchMenuItems)[index]._id
  const item = HelpersObjectCleanCopy(props.batchMenuItems[id])

  const alert = await alertController.create({
    header: 'Enter your comment',
    backdropDismiss: false,
    buttons: [
      {
        text: 'Cancel',
      },
      {
        text: item.comment ? 'Update' : 'Add',
        handler: (value) => {
          if (value[0] === '') {
            delete item.comment
          } else {
            item.comment = value[0]
          }

          emit('update-batch-item', {
            item,
            id,
          })

          if (callback) callback()
        },
      },
    ],
    inputs: [
      {
        type: 'textarea',
        placeholder: 'Enter text here',
        value: item.comment,
      },
    ],
  })

  await alert.present().then(() => {
    document.querySelector('ion-alert textarea')?.focus()
  })
}
</script>
