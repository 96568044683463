<template>
  <AppStack
    align-items="items-center"
    justify-content="justify-between"
    class="z-10 w-full max-h-[96px]"
  >
    <AppStack align-items="items-center" class="gap-7">
      <AppTypography
        :text="props.title"
        tag="h2"
        class="heading-205xl-8-bold capitalize whitespace-nowrap overflow-hidden min-w-fit m-0"
        color="text-fg-elevation-0-primary"
      />
      <AppHeaderTabs
        v-if="props.tabs.length"
        :active-tab="props.activeTab"
        :tabs="props.tabs"
        :size="props.size"
        @update:active-tab="emit('update:tab', $event)"
      />
    </AppStack>
    <AppStack align-items="items-center" class="gap-7">
      <slot name="actions-right" />
    </AppStack>
  </AppStack>
</template>

<script lang="ts">
export default { name: 'AppHeader' }
</script>
<script setup lang="ts">
import { PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

import AppHeaderTabs from './AppHeaderTabs.vue'

const emit = defineEmits(['update:tab'])
const props = defineProps({
  tabs: {
    type: Array as PropType<
      {
        name: string
        value: string
      }[]
    >,
    default: () => [],
  },
  activeTab: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<'lg' | 'xl'>,
    required: true,
  },
})
</script>
