<template>
  <AppStack direction="flex-col" class="px-7">
    <AppStack direction="flex-col">
      <AppStack
        direction="flex-col"
        align-items="items-baseline"
        class="mb-6 gap-1"
      >
        <AppStack
          v-for="(caption, index) in captionsList"
          :key="index"
          justify-content="justify-between"
          align-items="items-baseline"
          class="w-full"
        >
          <AppTypography
            :text="caption.key"
            name="text-lg-7-medium"
            color="text-fg-elevation-2-secondary"
          />
          <AppTypography
            :text="caption.value"
            name="text-lg-7-medium"
            color="text-fg-elevation-2-secondary"
            class="ml-3"
          />
        </AppStack>
      </AppStack>
      <AppStack align-items="items-baseline" class="mb-6">
        <AppTypography
          :text="formatPrice(orderTotal)"
          name="heading-205xl-8-bold"
          color="text-fg-elevation-2-primary"
        />
        <AppTypography
          :text="quantityText"
          name="heading-2xl-7-semibold"
          color="text-fg-elevation-2-secondary"
          class="ml-3"
        />
      </AppStack>
    </AppStack>
    <AppStack class="gap-3">
      <button
        v-if="['backlog'].includes(pickupOrderStatus)"
        variant="custom"
        class="py-[18px] relative rounded-lg overflow-hidden flex-1"
        @click="bus.emit('mark-as-in-progress')"
      >
        <div
          class="bg-bg-blue absolute top-0 left-0 w-full h-full active:brightness-90"
        />
        <AppTypography
          color="text-universal-fg-opposite"
          text="Mark as In Progress"
          name="text-lg-7-semibold"
          class="relative"
        />
      </button>
      <button
        v-else-if="['inProgress'].includes(pickupOrderStatus)"
        variant="custom"
        class="py-[18px] relative rounded-lg overflow-hidden flex-1"
        @click="isCooked && bus.emit('mark-as-ready')"
      >
        <div
          :class="!isCooked ? ['bg-bg-elevation-2-alt'] : ['bg-bg-blue']"
          class="absolute top-0 left-0 w-full h-full active:brightness-90"
        />
        <AppTypography
          :color="
            !isCooked
              ? 'text-fg-elevation-2-secondary'
              : 'text-universal-fg-opposite'
          "
          text="Mark as Ready"
          name="text-lg-7-semibold"
          class="relative"
        />
      </button>
      <button
        v-else-if="['ready'].includes(pickupOrderStatus)"
        variant="custom"
        class="py-[18px] relative rounded-lg overflow-hidden flex-1"
        @click="
          bus.emit(
            props.order?.type === 'delivery'
              ? 'mark-as-delivery'
              : 'mark-as-picked-up',
          )
        "
      >
        <div
          class="bg-bg-blue absolute top-0 left-0 w-full h-full active:brightness-90"
        />
        <AppTypography
          color="text-universal-fg-opposite"
          :text="
            props.order?.type === 'delivery'
              ? 'Mark as Delivery'
              : 'Mark as Picked-up'
          "
          name="text-lg-7-semibold"
          class="relative"
        />
      </button>

      <button
        variant="custom"
        class="px-4 relative rounded-lg overflow-hidden border-[1px]
          border-border-elevation-3-secondary border-solid"
        @click="emit('actions-tap')"
      >
        <div
          class="absolute top-0 left-0 w-full h-full opacity-100 bg-bg-elevation-3"
        />
        <AppIcon
          color="text-fg-elevation-3-secondary"
          icon="more-horiz"
          size="custom"
          class="w-[32px] h-[32px] relative"
          stroke-width="1.8"
        />
      </button>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { getPickupOrderStatus, isOrderCooked } from '~/helpers/get-order-status'
import { useEventBus } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import useAppHelpers from '~/composables/useAppHelpers'

const emit = defineEmits([
  'actions-tap',
  'start-checkout',
  'batch-send-to-work',
  'send-tap',
  'clear-tap',
])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const { formatPrice } = useAppHelpers()
const bus = useEventBus<string>('pickup')

const { promotions: PromotionsStore } = useStores()

const pickupOrderStatus = computed(() => {
  if (!props.order) return 'backlog'
  return getPickupOrderStatus(props.order)
})
const isCooked = computed(() => {
  if (!props.order) return false
  return isOrderCooked(props.order)
})

const promotionsList = computed(() => {
  const ids = [...Object.keys(props.order?.appliedPromotions?.promotions || {})]

  if (!ids.length) return []

  return ids.map((id) => {
    return PromotionsStore.getFromStore(id).value
  })
})

const orderTotal = computed(() => {
  return typeof props?.order?.appliedPromotions?.total === 'number'
    ? props.order?.appliedPromotions?.total || 0
    : props.order?.total || 0
})

const { t } = useI18n()
const quantityText = computed(() => {
  const quantity = props.order?.menuItems.reduce((acc: number, item) => {
    if (!props.order) return 0

    acc = acc + item.quantity

    return acc
  }, 0)

  return t('order.items', quantity)
})

const captionsList = computed(() => {
  return [
    {
      key: 'Total',
      value: formatPrice(props.order?.total),
    },
    ...promotionsList.value.map((promotion) => ({
      key: promotion.name,
      value: formatPrice(
        props.order?.appliedPromotions?.promotions?.[promotion._id]?.discount
          ? -props.order?.appliedPromotions?.promotions?.[promotion._id]
              ?.discount
          : 0,
      ),
    })),
  ]
})
</script>
