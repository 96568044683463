import { feathers, type ClientService } from '@feathersjs/feathers'
import authenticationClient from '@feathersjs/authentication-client'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import { type ServiceStores } from '@restify/packages/composables/useStore'

const host = import.meta.env.VITE_APP_API_BASE as string

// Use Rest for the SSR Server and socket.io for the browser
const connection = socketio(
  io(host, { timeout: 10000, transports: ['websocket'] }),
)

export interface ServiceTypes
  extends Record<keyof ServiceStores, ClientService> {
  ['auth-management']: ClientService
  ['process']: ClientService
  ['stock-levels']: ClientService
  ['checkout']: ClientService
}

export const feathersClient = feathers<ServiceTypes>()
  .configure(connection)
  .configure(
    authenticationClient({
      storage: window.localStorage,
      storageKey: 'accessToken',
    }),
  )

export default () => {
  return feathersClient
}
