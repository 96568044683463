// @ts-nocheck

import { ref, onMounted, onDeactivated } from 'vue'

export default function useMouseHold() {
  const holdInterval = ref<null | number>(null)

  const onDocumentMouseUp = () => {
    holdInterval.value !== null && clearInterval(holdInterval.value)
  }

  const onTouchEnd = ($event) => {
    document.removeEventListener('mouseup', onDocumentMouseUp)
    $event?.target?.removeEventListener?.('touchend', onTouchEnd)
    clearInterval(holdInterval.value)
  }

  const onMouseHold = (callback: () => void, $event?) => {
    holdInterval.value = window.setInterval(callback, 150)

    if ($event?.type === 'touchstart') {
      $event.target.addEventListener('touchend', onTouchEnd)
      callback()
      $event.preventDefault()
    }
  }

  onMounted(() => {
    document.addEventListener('mouseup', onDocumentMouseUp)
  })

  onDeactivated(() => {
    document.removeEventListener('mouseup', onDocumentMouseUp)
  })

  return {
    onMouseHold,
  }
}
