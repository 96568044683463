<template>
  <ion-toolbar :style="sidebarToolbarStyles">
    <AppTypography
      text="Order"
      name="heading-2xl-7-semibold"
      color="text-fg-elevation-2-primary"
    />
    <AppTypography
      v-if="props.order?.publicId"
      :text="` ${props.order.publicId}`"
      name="heading-2xl-7-semibold"
      color="text-fg-elevation-2-secondary"
    />
  </ion-toolbar>
</template>

<script setup lang="ts">
import { IonToolbar } from '@ionic/vue'
import { PropType, computed } from 'vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import { type Stores } from '~/composables/useStores'

const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const sidebarToolbarStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
  'border-bottom': '1px solid var(--rf-border-elevation-2-secondary)',
  '--padding-top': '24px',
  '--padding-bottom': '24px',
  'padding-left': '24px',
  'padding-right': '24px',
}))
</script>
