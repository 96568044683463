<template>
  <AppStack direction="flex-col">
    <OrderModalSidebarHeader
      is-finalise
      :order="props.order"
      :injected-object-id="props.injectedObjectId"
      class="sticky top-0"
    />
    <template v-if="order">
      <AppStack
        :style="actionsStyles"
        direction="flex-col"
        class="transition-transform duration-300 ease-in-out safe-bottom-max"
      >
        <OrderModalMenuitemsCheckout :order="order" class="flex-1" />
      </AppStack>
      <div
        :style="actionsStyles"
        class="transition-transform duration-300 ease-in-out z-[10] fixed bottom-0 left-0
          w-[370px] safe-bottom-max bg-bg-elevation-2"
      >
        <div
          class="absolute top-[-100px] left-0 w-full h-[100px] pointer-events-none
            bg-gradient-to-b to-bg-elevation-2 from-transparent"
        />
        <OrderModalSidebarFooterFinalise :order="order" />
      </div>
    </template>
  </AppStack>
</template>

<script setup lang="ts">
import { ref, type PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import OrderModalSidebarHeader from './OrderModalSidebarHeader.vue'
import OrderModalMenuitemsCheckout from './OrderModalSidebarMenuitemsCheckout.vue'
import OrderModalSidebarFooterFinalise from './OrderModalSidebarFooterFinalise.vue'
import { type Stores } from '~/composables/useStores'

const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: undefined,
  },
  injectedObjectId: {
    type: String,
    default: undefined,
  },
})

const actionsStyles = ref({})
</script>
