<template>
  <AppStack direction="flex-col" class="px-7 gap-5">
    <AppStack direction="flex-col" class="gap-3">
      <button
        v-for="(action, index) in actions"
        :key="index"
        variant="custom"
        class="h-[64px] px-4 relative rounded-lg overflow-hidden border-[1px]
          border-border-elevation-3-secondary border-solid"
        @click="action?.onClick()"
      >
        <div
          class="absolute top-0 left-0 w-full h-full opacity-100 bg-bg-elevation-3"
        />
        <AppTypography
          :text="action.text"
          :color="
            action.variant === 'danger'
              ? 'text-fg-red'
              : 'text-fg-elevation-2-primary'
          "
          name="text-lg-7-semibold"
          class="relative"
        />
      </button>
    </AppStack>
    <button
      variant="custom"
      class="h-[64px] px-4 relative rounded-lg overflow-hidden"
      @click="emit('dismiss')"
    >
      <div
        class="absolute top-0 left-0 w-full h-full opacity-100 bg-bg-elevation-2
          active:bg-bg-elevation-2-alt active:opacity-50"
      />
      <AppTypography
        :text="$t('order.actionsClose')"
        color="text-fg-elevation-2-secondary"
        name="text-lg-7-semibold"
        class="relative"
      />
    </button>
    <IonAlert
      :is-open="confirmCancelAlertIsOpen"
      header="Cancelling order."
      message="Please confirm to cancel the order."
      :buttons="confirmCancelButtons"
    />
  </AppStack>
</template>

<script setup lang="ts">
import { computed, type PropType, ref } from 'vue'
import { IonAlert } from '@ionic/vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '~/stores/auth'
import { useShiftsLogic } from '@restify/packages/composables/useShiftsLogic'

const { t } = useI18n()

type Action = {
  text: string
  variant: string
  onClick: () => void
}

const emit = defineEmits(['dismiss', 'order-cancelled'])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const { orders: OrdersStore } = useStores()
const AuthStore = useAuthStore()
const { shift, shiftIsHappening } = useShiftsLogic(AuthStore)

const confirmCancelAlertIsOpen = ref(false)

const currentWaiterId = computed(() => {
  return props.order?.staff.find((staff) => staff.role === 'waiter')?.staffId
})

const orderIsCancellable = computed(() => {
  return (
    props.order.status.name === 'created' &&
    props.order?.menuItems.every((item) => {
      return ['cancelled', 'idle'].includes(item.status.name)
    })
  )
})

const actions = computed<Action[]>(() => {
  return [
    // {
    //   text: 'Print Summary',
    //   variant: 'primary',
    //   onClick: () => {
    //     console.log('print summary')
    //   },
    // },
    {
      text: t('order.createdOrderForThisTable'),
      variant: 'primary',
      onClick: () => {
        if (!props.order?.storefrontPlaceIds?.[0]) {
          console.warn('No storefront place to create order')

          return
        }

        if (!AuthStore.reactiveUser) {
          console.warn('No active user')

          return
        }

        OrdersStore.create({
          status: {
            name: 'created',
            createdAt: new Date().getTime(),
          },
          menuItems: [],
          batches: [],
          tabName: '',
          fees: [],
          promotionIds: [],
          type: 'inHouseService',
          storefrontPlaceIds: [props.order?.storefrontPlaceIds[0]],
          staff: [
            {
              shiftId:
                shift.value && shiftIsHappening.value
                  ? shift.value._id
                  : undefined,
              staffId: AuthStore.reactiveUser?._id,
              role: AuthStore.reactiveUser.profile.role as 'waiter',
              processed: false,
            },
          ],
        }).then(() => emit('dismiss'))
      },
    },
    ...(AuthStore.reactiveUser?.profile.role === 'waiter' &&
    currentWaiterId.value !== AuthStore.reactiveUser?._id
      ? [
          {
            text: t('order.changeWaiter'),
            variant: 'secondary',
            onClick: () => {
              if (!props.order || !AuthStore.reactiveUser) return

              const newStaff = {
                shiftId:
                  shift.value && shiftIsHappening.value
                    ? shift.value._id
                    : undefined,
                staffId: AuthStore.reactiveUser._id,
                role: AuthStore.reactiveUser.profile.role,
                processed: false,
              }

              OrdersStore.patch(
                props.order._id,
                currentWaiterId.value
                  ? {
                      $set: {
                        'staff.$': newStaff,
                      },
                    }
                  : {
                      $push: {
                        staff: newStaff,
                      },
                    },
                currentWaiterId.value
                  ? {
                      query: {
                        'staff.staffId': currentWaiterId.value,
                      },
                    }
                  : {},
              )

              emit('dismiss')
            },
          },
        ]
      : []),
    ...(orderIsCancellable.value
      ? [
          {
            text: t('order.cancelOrder'),
            variant: 'danger',
            onClick: () => {
              confirmCancelAlertIsOpen.value = true
            },
          },
        ]
      : []),
  ]
})

const confirmCancelButtons = [
  {
    text: 'Yes',
    role: 'destructive',
    handler: () => {
      if (!props.order?._id) return

      OrdersStore.patch(props.order._id, {
        'status.name': 'cancelled',
        'status.cancelledAt': new Date().getTime(),
      }).then(() => {
        confirmCancelAlertIsOpen.value = false
        emit('order-cancelled', props.order._id)
      })
    },
  },
  {
    text: 'No',
    role: 'cancel',
    handler: () => {
      confirmCancelAlertIsOpen.value = true
    },
  },
]
</script>
