<template>
  <AppStack
    class="w-full overflow-hidden"
    direction="flex-col"
    justify-content="justify-between"
  >
    <AppStack
      class="w-full pt-[40px] px-7 pb-5 border-solid border-t-0 border-x-0
        border-border-elevation-2-secondary"
      justify-content="justify-between"
      align-items="items-center"
    >
      <div class="w-9" />
      <AppTypography
        :text="$t('promotions.contentTitle')"
        name="heading-2xl-7-bold"
        color="text-fg-elevation-2-primary"
      />
      <AppIcon
        icon="search"
        stroke-width="1.5"
        color="text-fg-blue"
        class="w-9 h-9"
        @click="emit('search-tap')"
      />
    </AppStack>
    <AppStack
      class="w-full h-full px-[100px]"
      flex="1"
      direction="flex-col"
      justify-content="justify-center"
      align-items="items-start"
    >
      <!-- <AppTypography
        name="text-lg-7-medium"
        color="text-fg-elevation-2-secondary"
        text="Scan voucher code"
      />
      <AppStack class="gap-4 w-full mt-3 bg-8">
        <PromotionsModalScanButton
          icon-name="text-box"
          title="Scan Text"
        />
        <PromotionsModalScanButton
          icon-name="scan-barcode"
          title="Scan Barcode"
        />
        <PromotionsModalScanButton
          icon-name="scan-qr-code"
          title="Scan QR"
        />
      </AppStack> -->
      <AppTypography
        name="text-lg-7-medium"
        color="text-fg-elevation-2-secondary"
        :text="$t('promotions.matchingPromotions')"
      />
      <AppStack
        v-if="promotionsList.length"
        direction="flex-col"
        class="w-full mt-3 border-[1px] border-solid border-border-elevation-3-secondary
          rounded-lg overflow-hidden"
      >
        <PromotionsModalMatchedPromotion
          v-for="promotion in promotionsList"
          :key="promotion._id"
          :title="promotion.name"
          :description="promotion.description"
          :applied="
            promotion._id in (props.order?.appliedPromotions?.promotions || {})
          "
          class="border-solid last:border-b-0 border-b-[1px] border-t-0 border-l-0 border-r-0
            border-border-elevation-3-secondary"
          @apply="onPromotionApply(promotion._id)"
        />
      </AppStack>
      <AppStack
        v-else
        direction="flex-col"
        justify-content="justify-center"
        align-items="items-center"
        class="w-full mt-3 p-8 border-[1px] border-solid border-border-elevation-3-secondary
          rounded-lg overflow-hidden"
      >
        <AppStack
          direction="flex-col"
          class="w-full"
          justify-content="justify-center"
          align-items="items-center"
        >
          <AppTypography
            text="No promotions suggested"
            color="text-fg-elevation-2-tertiary"
            name="text-lg-7-medium"
            class="text-center"
          />
        </AppStack>
      </AppStack>
    </AppStack>
    <AppStack
      class="w-full"
      flex="1"
      justify-content="justify-center"
      align-items="items-center"
    />
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import PromotionsModalScanButton from '../PromotionsModalScanButton.vue'
import PromotionsModalMatchedPromotion from '../PromotionsModalMatchedPromotion.vue'
import useStores, { type Stores } from '~/composables/useStores'

const emit = defineEmits(['search-tap'])
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const { orders: OrdersStore, promotions: PromotionsStore } = useStores()

const promotionsList = computed(() => {
  if (!props.order) return []

  return PromotionsStore.findInStore({
    orderType: props.order.type,
    status: 'public',
  }).map((item) => item.value)
})

const onPromotionApply = (id: string) => {
  if (!props.order?._id) return

  return OrdersStore.patch(props.order._id, {
    ...(!props.order.promotionIds.includes(id)
      ? { $push: { promotionIds: id } }
      : {}),
  })
}
</script>
