<template>
  <AppStack
    :class="ordersAtThisTableWithFallback.length > 1 ? ['pb-6'] : []"
    direction="flex-col"
    class="flex-1 relative"
  >
    <AppStack
      :class="
        actionsAreShown
          ? ['overflow-hidden']
          : ['overflow-x-auto overflow-y-hidden']
      "
      ref="scrollview"
      direction="flex-row"
      class="scrollview flex-1 snap-x snap-mandatory"
    >
      <template
        v-for="order in ordersAtThisTableWithFallback"
        :key="order?._id"
      >
        <OrderModalSidebarOrderSlide
          ref="slides"
          :order="order"
          :data-id="order?._id"
          :actionsAreShown="actionsAreShown"
          :actionsStyles="actionsStyles"
          :batch-menu-items="batchMenuItems"
          class="relative min-w-[370px] snap-center snap-always"
          @start-batch-tap="emit('start-batch-tap', $event)"
          @checkout-tap="emit('checkout-tap', $event)"
          @show-actions="onActionsShow"
          @dismiss-actions="onActionsDismiss"
          @increase-quantity="onIncreaseQuantity"
          @decrease-quantity="onDecreaseQuantity"
          @clear-batch="onClearTap"
          @delete-batch-item="onDelete"
          @update-batch-item="onUpdateBatchItem"
        />
      </template>
      <div
        :style="actionsStyles"
        class="z-[6] absolute bottom-0 left-0 w-[370px] pointer-events-none
          transition-[transform,opacity] duration-300 ease-in-out"
      >
        <div
          ref="actionsRef"
          class="relative translate-y-full safe-bottom-max bg-bg-elevation-2"
        >
          <OrderModalActions
            :order="props.order"
            class="pointer-events-auto safe-top-max border-t-[0.5px] border-solid border-b-0
              border-x-0 border-border-elevation-2-secondary"
            @dismiss="onActionsDismiss"
            @order-cancelled="onOrderCancelled"
          />
        </div>
      </div>
    </AppStack>
    <AppStack
      v-if="ordersAtThisTableWithFallback.length > 1"
      :style="actionsStyles"
      justify-items="justify-center"
      class="transition-transform duration-300 pb-5 gap-2 absolute left-0 bottom-0 w-full
        bg-bg-elevation-2"
    >
      <div
        v-for="order in ordersAtThisTableWithFallback"
        :key="order?._id"
        :class="
          actionsAreShown
            ? order?._id === props.order?._id
              ? ['opacity-40']
              : ['opacity-10']
            : order?._id === props.order?._id
              ? ['opacity-100']
              : ['opacity-30']
        "
        class="w-[9px] h-[9px] bg-bg-opposite rounded-full transition-opacity duration-300"
      />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  watch,
  reactive,
  type PropType,
  type ComputedRef,
  onMounted,
  onBeforeUnmount,
  inject,
} from 'vue'
import { useEventBus } from '@vueuse/core'
import { cleanCopy as HelpersObjectCleanCopy } from '@restify/packages/helpers/object'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import OrderModalActions from './OrderModalSidebarActions.vue'
import OrderModalSidebarOrderSlide from './OrderModalSidebarOrderSlide.vue'
import useStores, { type Stores } from '~/composables/useStores'
import useAppHelpers from '~/composables/useAppHelpers'

type OrderMenuItem = Stores['orders']['Result']['menuItems'][number]

const emit = defineEmits([
  'start-batch-tap',
  'checkout-tap',
  'update:currently-previewed-order-id',
])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: undefined,
  },
  injectedObjectId: {
    type: String,
    default: undefined,
  },
  injectedIsOpen: {
    type: Boolean,
    default: false,
  },
  isMenuItems: {
    type: Boolean,
    default: false,
  },
})

const BatchBus = useEventBus<'batch-add'>('batch')
const AppBus = useEventBus<
  'order-modal-dismiss' | 'order-modal-update',
  unknown
>('app')
const injectedOrderId = inject<ComputedRef<string>>('orderId')
const injectedObjectId = inject<ComputedRef<string> | null>('objectId', null)

BatchBus.on(onBatchEvent)

const {
  users: UsersStore,
  storefrontPlaces: StorefrontPlacesStore,
  orders: OrdersStore,
} = useStores()
const { mapMenuItem } = useAppHelpers()

const observer = ref<null | IntersectionObserver>(null)
const scrollview = ref<null | HTMLElement>(null)
const slides = ref<(null | HTMLElement)[]>([])
const loading = ref(false)
const actionsAreShown = ref(false)
const actionsRef = ref<HTMLElement | null>(null)
const actionsStyles = ref({})
let batchMenuItems = reactive<
  Record<string, Stores['orders']['Result']['menuItems'][number]>
>({})

const menuItems = computed(() =>
  Object.values(batchMenuItems).map((item) => ({
    ...mapMenuItem(item),
    _id: item._id,
    status: null,
  })),
)

const ordersAtThisTableWithFallback = computed(() => {
  if (injectedObjectId?.value) {
    return menuItems.value.length
      ? [props.order]
      : OrdersStore.findInStore({
          'status.name': {
            $in: ['created', 'checking-out'],
          },
          storefrontPlaceIds: props.order?.storefrontPlaceIds,
          $sort: {
            createdAt: 1,
          },
        }).map((item) => item.value)
  } else {
    return OrdersStore.findInStore({
      _id: injectedOrderId?.value,
      $sort: {
        createdAt: 1,
      },
    }).map((item) => item.value)
  }
})

const getStorefrontPlace = () => {
  const ids = [
    ...(props.order?.storefrontPlaceIds || []),
    ...(props.injectedObjectId ? [props.injectedObjectId] : []),
  ]

  return StorefrontPlacesStore.find({ query: { _id: { $in: ids } } })
}

const getStaff = () => {
  const staffIds = props.order?.staff.map((staff) => staff.staffId)

  if (!staffIds?.length) return Promise.resolve()

  return UsersStore.find({ query: { _id: { $in: staffIds } } })
}

const fetchData = () => {
  loading.value = true

  return Promise.all([getStaff(), getStorefrontPlace()]).finally(
    () => (loading.value = false),
  )
}

const onActionsShow = () => {
  if (actionsAreShown.value) return onActionsDismiss()

  const height = actionsRef.value?.clientHeight

  actionsAreShown.value = true
  actionsStyles.value = { transform: `translateY(${-height}px)` }
}

const onActionsDismiss = () => {
  actionsAreShown.value = false
  actionsStyles.value = {}
}

const onOrderCancelled = (orderId) => {
  if (!ordersAtThisTableWithFallback.value.length) {
    AppBus.emit('order-modal-dismiss')
  } else {
    setTimeout(() => {
      onActionsDismiss()
    }, 200)
  }
}

const onClearTap = () => {
  Object.keys(batchMenuItems).forEach((key) => delete batchMenuItems[key])
}

function onBatchEvent(
  eventName: 'batch-add',
  payload: { menuItem: Stores['orders']['Result']['menuItems'][number] },
) {
  if (eventName === 'batch-add') {
    batchMenuItems[payload.menuItem._id] = { ...payload.menuItem }
  }
}

const onUpdateBatchItem = ({ id, item }: { id: string; item: any }) => {
  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onIncreaseQuantity = (index: number) => {
  const id = Object.values(batchMenuItems)[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  item.quantity++

  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onDecreaseQuantity = (index: number) => {
  const id = Object.values(batchMenuItems)[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  if (item.quantity === 1) {
    delete batchMenuItems[id]

    return
  }

  item.quantity--

  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onDelete = (id: string) => {
  delete batchMenuItems[id]
}

const onLoadElementCallback: IntersectionObserverCallback = (entries) => {
  requestAnimationFrame(() => {
    entries.map((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
        const id = entry.target.getAttribute('data-id')

        if (id) {
          emit('update:currently-previewed-order-id', id)
        }
      }
    })
  })
}

const unobserve = () => {
  if (observer.value) {
    observer.value?.disconnect()
    observer.value = null
  }
}

const observe = () => {
  const scrollviewEl = scrollview.value?.$el || null

  if (!scrollviewEl) return

  if (!observer.value) {
    const options = {
      root: scrollviewEl,
      rootMargin: '0px',
      threshold: 0.8,
    }

    observer.value = new IntersectionObserver(onLoadElementCallback, options)
  }

  ;[...scrollviewEl.children].forEach(observePageEl)
}

const observePageEl = (element: Element) => {
  if (element) observer.value?.observe(element)
}

onMounted(() => {
  observe()

  if (scrollview.value && props.order?._id) {
    const slide = [...slides.value].find((slide) => {
      const slideId = slide?.$el.getAttribute('data-id')

      return slideId === props.order?._id
    })

    if (slide?.$el) {
      const offset = slide?.$el?.offsetLeft

      if (
        scrollview.value?.$el.scrollLeft !== undefined &&
        offset !== undefined
      ) {
        scrollview.value.$el.scrollLeft = offset
      }
    }
  }
})

watch(
  computed(() => ordersAtThisTableWithFallback.value),
  () => {
    unobserve()
    observe()
  },
  {
    flush: 'post',
  },
)

watch(
  computed(() => props.injectedIsOpen),
  (newVal) => newVal && fetchData(),
)

onBeforeUnmount(() => {
  unobserve()
})
</script>
