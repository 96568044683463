<template>
  <AppStack
    direction="flex-col"
    class="overflow-y-auto h-full bg-bg-elevation-2"
  >
    <ion-toolbar :style="sidebarToolbarStyles">
      <AppTypography
        :text="`Create ${goodsToCreate?.name || 'unknown'}`"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-primary"
      />
    </ion-toolbar>
    <AppStack
      v-if="loading"
      align-items="items-center"
      justify-content="justify-center"
      flex="flex-1"
      class="h-full"
    >
      <AppSpinner />
    </AppStack>
    <template v-else>
      <AppStack direction="flex-col" justify-content="center" class="flex-1">
        <AppStack
          align-items="items-center"
          direction="flex-col"
          justify-content="justify-center"
          class="px-7 py-5 gap-6 relative overflow-hidden flex-1"
        >
          <AppTypography
            :text="goodsToCreate?.name"
            name="heading-4xl-9-bold"
            color="text-fg-elevation-3-primary"
          />
          <AppStack class="gap-4" align-items="items-center">
            <button
              class="shadow-elevation-1-0-xs rounded-md active:bg-bg-elevation-3-alt px-8 py-2
                border-solid border border-border-elevation-3-secondary"
              @click="onQuantityChange('-')"
            >
              <AppIcon
                icon="minus"
                stroke-width="1.8"
                color="text-fg-elevation-3-primary"
                class="w-[32px] h-[32px]"
              />
            </button>
            <AppTypography
              name="heading-205xl-9-semibold"
              tag="div"
              color="text-fg-elevation-2-primary"
              class="flex-1 w-[85px] whitespace-nowrap text-center flex justify-center
                !tracking-tighter"
            >
              <div>{{ amountFormatted }}</div>
            </AppTypography>
            <button
              class="shadow-elevation-1-0-xs rounded-md active:bg-bg-elevation-3-alt px-8 py-2
                border-solid border border-border-elevation-3-secondary"
              @click="onQuantityChange('+')"
            >
              <AppIcon
                icon="plus"
                stroke-width="1.8"
                color="text-fg-elevation-3-primary"
                class="w-[32px] h-[32px]"
              />
            </button>
          </AppStack>
          <div
            class="border-border-elevation-3-secondary h-[1px] w-full
              bg-border-elevation-3-secondary absolute bottom-0 left-7 opacity-50"
          />
        </AppStack>
      </AppStack>
      <div class="safe-bottom-max bg-bg-elevation-2 py-7 space-y-7">
        <AppStack direction="flex-col" class="px-7">
          <AppStack
            v-for="ingredient in ingredients"
            :key="ingredient.id"
            direction="flex-row"
            class="gap-1"
          >
            <AppTypography
              :text="ingredient.label"
              name="text-lg-7-medium"
              color="text-fg-elevation-2-secondary"
              class="flex-1"
            />
            <AppTypography
              :text="ingredient.text"
              name="text-lg-7-medium"
              color="text-fg-elevation-2-secondary"
            />
          </AppStack>
        </AppStack>
        <AppStack direction="flex-col" class="px-7">
          <AppStack class="gap-3">
            <button
              variant="custom"
              class="py-[18px] relative rounded-lg overflow-hidden flex-1"
              @click="onStockOrderSubmit"
            >
              <div
                class="bg-bg-blue absolute top-0 left-0 w-full h-full active:brightness-90"
              />
              <AppTypography
                color="text-universal-fg-opposite"
                text="Create"
                name="text-lg-7-semibold"
                class="relative"
              />
            </button>
          </AppStack>
        </AppStack>
      </div>
    </template>
  </AppStack>
</template>

<script setup lang="ts">
import { PropType, computed, onMounted, ref, watch } from 'vue'
import { IonToolbar } from '@ionic/vue'
import { useEventBus } from '@vueuse/core'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppSpinner from '@restify/packages/design-system/low-level/AppSpinner.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import useStores from '~/composables/useStores'

const emit = defineEmits(['update:model-value', 'dismiss'])
const props = defineProps({
  goodsId: {
    type: [String, null] as PropType<null | string>,
    required: true,
  },
})

const StockBus = useEventBus<string>('stock')
const {
  goods: GoodsStore,
  products: ProductsStore,
  stockTransactions: StockTransactionsStore,
} = useStores()

const amountToCreate = ref(100)
const loading = ref(false)

const sidebarToolbarStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
  'border-bottom': '1px solid var(--rf-border-elevation-2-secondary)',
  '--padding-top': '24px',
  '--padding-bottom': '24px',
  'padding-left': '24px',
  'padding-right': '24px',
}))

const goodsToCreate = computed(() => {
  return GoodsStore.getFromStore(props.goodsId).value
})

const onQuantityChange = (type: '+' | '-') => {
  const newAmount =
    type === '+' ? amountToCreate.value + 100 : amountToCreate.value - 100

  if (newAmount < 0) {
    return
  }

  amountToCreate.value = newAmount
}

const amountFormatted = computed(() => {
  const smallUnitsToLarge = {
    gr: 'kg',
    ml: 'l',
    item: 'item',
  }

  if (amountToCreate.value >= 1000) {
    return `${amountToCreate.value / 1000} ${smallUnitsToLarge[goodsToCreate.value.unitOfMeasure]}.`
  }

  return `${amountToCreate.value} ${goodsToCreate.value.unitOfMeasure}.`
})

const ingredients = computed(() => {
  const proportion = Number(
    goodsToCreate.value?.exitAmount / amountToCreate.value,
  ).toFixed(1)

  return Object.values(goodsToCreate.value.products).map((item) => {
    const product = ProductsStore.getFromStore(item.productId).value
    const bruttoAmount = Number(item.bruttoAmount / proportion).toFixed(0)

    return {
      label: product.name,
      id: product._id,
      text: `${bruttoAmount} ${product?.unitOfMeasure}.`,
      product,
      proportion,
      bruttoAmount,
    }
  })
})

const onStockOrderSubmit = () => {
  return StockTransactionsStore.create({
    type: 'expence',
    products: ingredients.value.reduce((acc, ingredient) => {
      acc[ingredient.product._id] = {
        amount: ingredient.bruttoAmount,
        price: 0,
        type: 'product',
        productId: ingredient.product._id,
      }

      return acc
    }, {}),
  })
    .then(() => {
      return StockTransactionsStore.create({
        type: 'income',
        products: {
          [goodsToCreate.value._id]: {
            amount: amountToCreate.value,
            price: 0,
            type: 'goods',
            goodsId: goodsToCreate.value._id,
          },
        },
      })
    })
    .then(() => {
      StockBus.emit('recalculate-levels')

      return emit('dismiss')
    })
}

onMounted(() => {
  if (!goodsToCreate.value) return

  loading.value = true

  return ProductsStore.find({
    query: {
      _id: {
        $in: goodsToCreate.value.products.map((item) => item.productId),
      },
    },
  }).finally(() => {
    loading.value = false
  })
})
</script>

<style scoped>
ion-menu::part(backdrop) {
  background-color: rgba(18, 23, 33, 1);
}
</style>
