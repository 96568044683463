<template>
  <AppStack
    align-items="items-center"
    justify-content="justify-center"
    wrap="flex-wrap"
    class="gap-3 w-[284px]"
  >
    <AppStack
      v-for="key in 9"
      :key="key"
      :class="props.loading ? ['opacity-50 pointer-events-none'] : []"
      tag="button"
      align-items="items-center"
      justify-content="justify-center"
      class="w-[84px] h-[84px] relative bg-bg-elevation-2 rounded-2xl hover:opacity-70
        transition-opacity active:top-[2px] shadow-elevation-2-0-xs"
      @click="onKeyClick(key)"
    >
      <AppTypography
        :text="String(key)"
        color="text-fg-elevation-2-primary"
        type="heading-3xl-9-medium"
      />
    </AppStack>
    <AppStack
      align-items="items-center"
      justify-content="justify-center"
      tabindex="-1"
      class="w-[84px] h-[84px] relative"
    />
    <AppStack
      :class="props.loading ? ['opacity-50 pointer-events-none'] : []"
      tag="button"
      align-items="items-center"
      justify-content="justify-center"
      class="w-[84px] h-[84px] relative bg-bg-elevation-2 rounded-2xl hover:opacity-70
        transition-opacity active:top-[2px]"
      @click="onKeyClick(0)"
    >
      <AppTypography
        text="0"
        color="text-fg-elevation-2-primary"
        type="heading-3xl-9-medium"
      />
    </AppStack>
    <AppStack
      :class="props.loading ? ['opacity-50 pointer-events-none'] : []"
      tag="button"
      align-items="items-center"
      justify-content="justify-center"
      class="w-[84px] h-[84px] relative border-border-elevation-2-secondary border-[3px]
        rounded-2xl hover:opacity-70 transition-opacity active:top-[2px]"
      @click="onKeyClick('delete')"
    >
      <BackspaceIcon
        class="text-fg-elevation-2-tertiary relative -left-[2px] w-[36px] h-[36px]"
      />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { BackspaceIcon } from '@heroicons/vue/24/outline'

import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const emit = defineEmits(['key', 'delete'])

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
})

const onKeyClick = (key: 'delete' | number) => {
  if (Capacitor.isNativePlatform()) {
    Haptics.impact({ style: ImpactStyle.Medium })
  }

  if (key === 'delete') {
    emit('delete')
  } else {
    emit('key', key)
  }
}
</script>
