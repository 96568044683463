<template>
  <AppStack direction="flex-col" class="py-3 relative">
    <AppStack
      align-items="items-baseline"
      wrap="flex-wrap"
      class="relative gap-x-2 gap-y-1 pr-7"
    >
      <AppTypography
        :text="props.title"
        :class="props.status === 'cancelled' ? ['line-through'] : []"
        :color="
          ['served', 'cancelled'].includes(props.status)
            ? 'text-fg-elevation-2-secondary'
            : 'text-fg-elevation-2-primary'
        "
        name="text-lg-7-medium"
        class="line-clamp-2"
      />
      <AppTypography
        :text="props.subtitle"
        color="text-fg-elevation-2-secondary"
        name="text-base-6-medium"
        class="whitespace-nowrap"
      />
      <AppTypography
        v-if="props.label"
        :text="props.label.text"
        color="text-fg-elevation-2-secondary"
        name="text-xs-3-bold"
        class="bg-bg-elevation-2-alt py-1 px-[6px] rounded-lg uppercase"
      />
      <AppStack
        v-if="props.status"
        align-items="items-center"
        justify-content="justify-center"
        class="w-6 h-6 absolute right-0 top-1/2 -translate-y-1/2"
      >
        <div
          v-if="props.status === 'idle'"
          class="w-3 h-3 rounded-full bg-bg-blue"
        />
        <div
          v-else-if="props.status === 'inProgress'"
          class="w-3 h-3 rounded-full bg-bg-green"
        />
        <AppIcon
          v-else-if="props.status === 'ready'"
          icon="check"
          color="text-fg-blue"
          class="w-6 h-6"
          stroke-width="1.5"
        />
        <AppIcon
          v-else-if="props.status === 'served'"
          stroke-width="2"
          icon="double-check"
          color="text-fg-blue"
          class="w-6 h-6"
        />
        <AppIcon
          v-else-if="props.status === 'cancelled'"
          icon="cancel"
          color="text-fg-red"
          stroke-width="2px"
          class="w-[22px] h-[22px]"
        />
      </AppStack>
    </AppStack>
    <AppStack direction="flex-col">
      <AppStack v-if="props.variations.length" class="pt-[6px]">
        <Cog8ToothIcon
          class="h-4 w-4 text-fg-elevation-2-secondary mt-[2px] mr-2"
          aria-hidden="true"
        />
        <AppStack direction="flex-col">
          <AppTypography
            v-for="(configuration, index) in props.variations"
            :key="index"
            :text="configuration"
            color="text-fg-elevation-2-secondary"
            name="text-base-5-normal"
          />
        </AppStack>
      </AppStack>
      <AppStack v-if="props.extras.length" class="pt-[6px]">
        <PlusIcon
          class="h-4 w-4 text-fg-elevation-2-secondary mt-[2px] mr-2"
          aria-hidden="true"
        />
        <AppStack direction="flex-col">
          <AppTypography
            v-for="(extra, index) in props.extras"
            :key="index"
            :text="extra"
            color="text-fg-elevation-2-secondary"
            name="text-base-5-normal"
          />
        </AppStack>
      </AppStack>
      <AppStack v-if="props.removables.length" class="pt-[6px]">
        <MinusIcon
          class="h-4 w-4 text-fg-elevation-2-secondary mt-[2px] mr-2"
          aria-hidden="true"
        />
        <AppStack direction="flex-col">
          <AppTypography
            v-for="(removable, index) in props.removables"
            :key="index"
            :text="removable"
            color="text-fg-elevation-2-secondary"
            name="text-base-5-normal"
          />
        </AppStack>
      </AppStack>
      <AppStack v-if="props.comment" class="pt-[6px]">
        <AppIcon
          icon="message-text"
          color="text-fg-blue"
          class="h-4 w-4 text-fg-elevation-2-secondary mt-[2px] mr-2"
          stroke-width="1.5"
        />
        <AppStack direction="flex-col">
          <AppTypography
            :text="props.comment"
            color="text-fg-elevation-2-secondary"
            name="text-base-5-normal"
          />
        </AppStack>
      </AppStack>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { Cog8ToothIcon } from '@heroicons/vue/24/outline'
import { MinusIcon, PlusIcon } from '@heroicons/vue/24/solid'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    default: '',
  },
  label: {
    type: Object,
    default: null,
  },
  variations: {
    type: Array,
    default: () => [],
  },
  extras: {
    type: Array,
    default: () => [],
  },
  removables: {
    type: Array,
    default: () => [],
  },
  comment: {
    type: String,
    default: '',
  },
  status: {
    type: String as PropType<
      'idle' | 'inProgress' | 'ready' | 'cancelled' | 'served'
    >,
    default: null,
  },
})
</script>
