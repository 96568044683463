<template>
  <AppStack direction="flex-col">
    <template v-if="displayBatchedUi">
      <OrderModalMenuitemsBatchedList
        v-for="(batch, index) in Object.values(batches)"
        :key="batch.batch.id + batch.batch.createdAt + index"
        :name="`${$t('order.batch')} #${index + 1}`"
        :batch="batch"
      />
      <OrderModalMenuitemsListButton
        :class="[
          ...(Object.values(batches).length
            ? [
                `border-t-[0.5px] border-solid border-b-0 border-x-0
                  border-border-elevation-2-secondary`,
              ]
            : []),
          ...(props.order.status.name !== 'created' ? ['opacity-50'] : []),
        ]"
        :text="$t('order.startBatch')"
        @click="
          props.order.status.name === 'created' && emit('start-batch-tap')
        "
      />
    </template>
    <AppStack v-else direction="flex-col">
      <OrderModalMenuitemsList :items="menuItems" class="flex-1" />
      <OrderModalMenuitemsBatchedList
        v-if="props.batchItems.length"
        :batch="{ menuItems: props.batchItems }"
        :name="$t('order.draftBatch')"
        quantity-changable
        deletable
        @increase-quantity="emit('increase-quantity', $event)"
        @decrease-quantity="emit('decrease-quantity', $event)"
        @delete="emit('delete', $event)"
        @add-comment="emit('add-comment', $event)"
      />
      <OrderModalSidebarEmpty
        v-if="!menuItems.length && !props.batchItems.length"
        :text="$t('order.sidebarPlaceholder')"
        class="absolute top-1/2 left-0 -translate-y-1/2"
      />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import { type Stores } from '~/composables/useStores'
import { useAppStore } from '~/stores/app'
import OrderModalMenuitemsBatchedList from './OrderModalSidebarMenuitemsBatchedList.vue'
import OrderModalMenuitemsList from './OrderModalSidebarMenuitemsList.vue'
import OrderModalMenuitemsListButton from './OrderModalSidebarMenuitemsListButton.vue'
import OrderModalSidebarEmpty from './OrderModalSidebarEmpty.vue'
import useAppHelpers from '~/composables/useAppHelpers'

const emit = defineEmits([
  'start-batch-tap',
  'increase-quantity',
  'decrease-quantity',
  'delete',
  'add-comment',
])

const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result']>,
    required: true,
  },
  batchItems: {
    type: Array,
    default: () => [],
  },
  isMenuItems: {
    type: Boolean,
    default: false,
  },
})

const { mapMenuItem } = useAppHelpers()
const AppStore = useAppStore()

const displayBatchedUi = computed(() => {
  if (props.order.menuItems.length && !props.order.batches.length) {
    return false
  }

  return AppStore?.selectedStorefront.inHouseService.setup.batches
})

const batches = computed(() => {
  const batchesAsMap = props.order.batches.reduce(
    (acc: Record<string, Stores['orders']['Result']['batches'][0]>, batch) => {
      if (!acc[batch.id]) acc[batch.id] = batch

      return acc
    },
    {},
  )

  const menuItemsByBatch = props.order.menuItems.reduce(
    (
      acc: Record<
        string,
        {
          batch: any
          menuItems: Partial<Stores['orders']['Result']['menuItems']>
        }
      >,
      menuItem,
    ) => {
      if (!menuItem || !menuItem?.batchId) return acc

      if (!acc[menuItem?.batchId])
        acc[menuItem?.batchId] = {
          batch: null,
          menuItems: [],
        }

      acc[menuItem?.batchId].batch = batchesAsMap[menuItem.batchId]
      acc[menuItem?.batchId].menuItems.push(mapMenuItem(menuItem))

      return acc
    },
    {},
  )

  return menuItemsByBatch
})

const menuItems = computed(() => {
  return props.order.menuItems.map((menuItem) => mapMenuItem(menuItem))
})
</script>
