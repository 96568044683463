import { createAnimation } from '@ionic/vue'
import type { ModalAnimationOptions } from '@ionic/core/dist/types/components/modal/modal-interface.d.ts'

interface StyleOptions {
  style: Style
}

export enum Style {
  Dark = 'DARK',
  Light = 'LIGHT',
  Default = 'DEFAULT',
}

export const StatusBar = {
  getEngine() {
    return (
      (win as any)?.Capacitor?.isPluginAvailable('StatusBar') &&
      (win as any)?.Capacitor.Plugins.StatusBar
    )
  },
  supportsDefaultStatusBarStyle() {
    /**
     * The 'DEFAULT' status bar style was added
     * to the @capacitor/status-bar plugin in Capacitor 3.
     * PluginHeaders is only supported in Capacitor 3+,
     * so we can use this to detect Capacitor 3.
     */
    return !!(win as any)?.Capacitor?.PluginHeaders
  },
  setStyle(options: StyleOptions) {
    const engine = this.getEngine()
    if (!engine) {
      return
    }

    engine.setStyle(options)
  },
  getStyle: async function (): Promise<Style> {
    const engine = this.getEngine()
    if (!engine) {
      return Style.Default
    }
    const { style } = await engine.getInfo()
    return style
  },
}

/**
 * When accessing the document or window, it is important
 * to account for SSR applications where the
 * window is not available. Code that accesses
 * window when it is not available will crash.
 * Even checking if `window === undefined` will cause
 * apps to crash in SSR.
 *
 * Use win below to access an SSR-safe version
 * of the window.
 *
 * Example 1:
 * Before:
 * if (window.innerWidth > 768) { ... }
 *
 * After:
 * import { win } from 'path/to/this/file';
 * if (win?.innerWidth > 768) { ... }
 *
 * Note: Code inside of this if-block will
 * not run in an SSR environment.
 */
export const win: Window | undefined =
  typeof window !== 'undefined' ? window : undefined

export const doc: Document | undefined =
  typeof document !== 'undefined' ? document : undefined

/**
 * Use y = mx + b to
 * figure out the backdrop value
 * at a particular x coordinate. This
 * is useful when the backdrop does
 * not begin to fade in until after
 * the 0 breakpoint.
 */
export const getBackdropValueForSheet = (
  x: number,
  backdropBreakpoint: number,
) => {
  /**
   * We will use these points:
   * (backdropBreakpoint, 0)
   * (maxBreakpoint, 1)
   * We know that at the beginning breakpoint,
   * the backdrop will be hidden. We also
   * know that at the maxBreakpoint, the backdrop
   * must be fully visible. maxBreakpoint should
   * always be 1 even if the maximum value
   * of the breakpoints array is not 1 since
   * the animation runs from a progress of 0
   * to a progress of 1.
   * m = (y2 - y1) / (x2 - x1)
   *
   * This is simplified from:
   * m = (1 - 0) / (maxBreakpoint - backdropBreakpoint)
   *
   * If the backdropBreakpoint is 1, we return 0 as the
   * backdrop is completely hidden.
   *
   */
  if (backdropBreakpoint === 1) {
    return 0
  }

  const slope = 1 / (1 - backdropBreakpoint)

  /**
   * From here, compute b which is
   * the backdrop opacity if the offset
   * is 0. If the backdrop does not
   * begin to fade in until after the
   * 0 breakpoint, this b value will be
   * negative. This is fine as we never pass
   * b directly into the animation keyframes.
   * b = y - mx
   * Use a known point: (backdropBreakpoint, 0)
   * This is simplified from:
   * b = 0 - (backdropBreakpoint * slope)
   */
  const b = -(backdropBreakpoint * slope)

  /**
   * Finally, we can now determine the
   * backdrop offset given an arbitrary
   * gesture offset.
   */

  return x * slope + b
}

/**
 * The tablet/desktop card modal activates
 * when the window width is >= 768.
 * At that point, the presenting element
 * is not transformed, so we do not need to
 * adjust the status bar color.
 *
 * Note: We check supportsDefaultStatusBarStyle so that
 * Capacitor <= 2 users do not get their status bar
 * stuck in an inconsistent state due to a lack of
 * support for Style.Default.
 */
export const setCardStatusBarDark = () => {
  if (
    !win ||
    win.innerWidth >= 768 ||
    !StatusBar.supportsDefaultStatusBarStyle()
  ) {
    return
  }

  StatusBar.setStyle({ style: Style.Dark })
}

export const setCardStatusBarDefault = (defaultStyle = Style.Default) => {
  if (
    !win ||
    win.innerWidth >= 768 ||
    !StatusBar.supportsDefaultStatusBarStyle()
  ) {
    return
  }

  StatusBar.setStyle({ style: defaultStyle })
}

export const createSheetEnterAnimation = (opts: ModalAnimationOptions) => {
  const { currentBreakpoint, backdropBreakpoint } = opts

  /**
   * If the backdropBreakpoint is undefined, then the backdrop
   * should always fade in. If the backdropBreakpoint came before the
   * current breakpoint, then the backdrop should be fading in.
   */
  const shouldShowBackdrop =
    backdropBreakpoint === undefined || backdropBreakpoint < currentBreakpoint!
  const initialBackdrop = shouldShowBackdrop
    ? `calc(var(--backdrop-opacity) * ${currentBreakpoint!})`
    : '0'

  const backdropAnimation = createAnimation('backdropAnimation').fromTo(
    'opacity',
    0,
    initialBackdrop,
  )

  if (shouldShowBackdrop) {
    backdropAnimation
      .beforeStyles({
        'pointer-events': 'none',
      })
      .afterClearStyles(['pointer-events'])
  }

  const wrapperAnimation = createAnimation('wrapperAnimation').keyframes([
    { offset: 0, opacity: 1, transform: 'translateY(100%)' },
    {
      offset: 1,
      opacity: 1,
      transform: `translateY(${100 - currentBreakpoint! * 100}%)`,
    },
  ])

  return { wrapperAnimation, backdropAnimation }
}

export const createSheetLeaveAnimation = (opts: ModalAnimationOptions) => {
  const { currentBreakpoint, backdropBreakpoint } = opts

  /**
   * Backdrop does not always fade in from 0 to 1 if backdropBreakpoint
   * is defined, so we need to account for that offset by figuring out
   * what the current backdrop value should be.
   */
  const backdropValue = `calc(var(--backdrop-opacity) * ${getBackdropValueForSheet(
    currentBreakpoint!,
    backdropBreakpoint!,
  )})`
  const defaultBackdrop = [
    { offset: 0, opacity: backdropValue },
    { offset: 1, opacity: 0 },
  ]

  const customBackdrop = [
    { offset: 0, opacity: backdropValue },
    { offset: backdropBreakpoint!, opacity: 0 },
    { offset: 1, opacity: 0 },
  ]

  const backdropAnimation = createAnimation('backdropAnimation').keyframes(
    backdropBreakpoint !== 0 ? customBackdrop : defaultBackdrop,
  )

  const wrapperAnimation = createAnimation('wrapperAnimation').keyframes([
    {
      offset: 0,
      opacity: 1,
      transform: `translateY(${100 - currentBreakpoint! * 100}%)`,
    },
    { offset: 1, opacity: 1, transform: 'translateY(100%)' },
  ])

  return { wrapperAnimation, backdropAnimation }
}
