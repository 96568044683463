<template>
  <ion-page>
    <ion-content :fullscreen="true" :style="contentStyles">
      <AppLogin
        class="bg-bg-elevation-0 fixed top-0 left-0 w-full h-full z-10"
        @login="onLogin"
      />
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonPage, IonContent } from '@ionic/vue'
import { computed } from 'vue'
import AppLogin from '~/components/AppLogin/index.vue'
import { useAuthStore } from '~/stores/auth'

const emit = defineEmits(['login'])

const AuthStore = useAuthStore()

const contentStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-0)',
  '--border-color': 'var(--rf-border-elevation-0-primary)',
}))

const onLogin = () => {
  AuthStore.lastSessionLogin = Date.now()

  return emit('login')
}
</script>
