<template>
  <AppStack class="w-[212px] h-2 relative" :class="props.width">
    <AppStack
      class="rounded-lg bg-bg-blue absolute top-0 bottom-0 left-0 right-0 opacity-10"
    />
    <AppStack
      class="bg-bg-blue rounded-lg"
      :style="{ width: `${props.progress}%` }"
    />
    <!-- :class="props.progressWidth" -->
  </AppStack>
</template>

<script setup lang="ts">
import { PropType, defineProps, computed } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'

const props = defineProps({
  width: {
    type: String,
    default: '',
  },
  progress: {
    type: Number,
    default: 25,
  },
})
</script>
