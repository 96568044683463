import { AuthenticationRequest } from '@feathersjs/authentication'
import { addMinutes as DateFnsAddMinutes } from 'date-fns'
import useStores, { type Stores } from '~/composables/useStores'
import useFeathers from '~/composables/useFeathers'
import { useAppStore } from '~/stores/app'
import { useNotification } from '@restify/packages/design-system/low-level/AppNotification/useNotification'
import { parse as HelpersJwtParse } from '@restify/packages/helpers/jwt'
import { defineStore } from 'pinia'

const { notifyWarning } = useNotification()

const setPreferredEmails = (user: Stores['users']['Result']) => {
  let preferredEmails: Stores['users']['Result'][] = []

  try {
    preferredEmails = JSON.parse(
      window.localStorage.getItem('preferredEmails') || [],
    )
  } catch (error) {
    console.log(error)
  }

  if (preferredEmails.findIndex((item) => item.email === user.email) < 0) {
    preferredEmails.push(user)
  }

  window.localStorage.setItem(
    'preferredEmails',
    JSON.stringify(preferredEmails),
  )
}

export const useAuthStore = defineStore('auth', {
  state: (): {
    accessToken: null | string
    user: null | Stores['users']['Result']
    authentication: unknown
    preferredEmail: string | null
    preferredAppId: string | null
    preferredStorefrontId: string | null
    lastSessionLogin: null | number
    lastActivity: number
  } => ({
    accessToken: null,
    user: null,
    authentication: {},
    preferredEmail: null,
    preferredAppId: null,
    preferredStorefrontId:
      window.localStorage.getItem('preferredStorefrontId') || null,
    lastSessionLogin: null,
    lastActivity: Date.now(),
  }),
  getters: {
    isUserAuthenticated(): boolean {
      return !!this.accessToken
    },
    parsedAccessToken(): ReturnType<typeof HelpersJwtParse> | null {
      return (this.accessToken && HelpersJwtParse(this.accessToken)) || null
    },
    reactiveUser(): Stores['users']['Result'] | null {
      const { users: UsersStore } = useStores()

      return this.user ? UsersStore.itemsById[this.user._id].value : null
    },
    userApp(): Stores['apps']['Result'] | null {
      const { apps: AppsStore } = useStores()
      const appId = this.preferredAppId || this.reactiveUser?.appId

      if (!appId) return null

      return AppsStore.itemsById[appId].value || null
    },
    userPermittedStorefrontId(): string | undefined {
      if (!this.reactiveUser) return undefined

      if (this.reactiveUser?.permissions?.storefrontIds?.[0]) {
        return this.reactiveUser.permissions.storefrontIds[0]
      }

      const { storefronts: StorefrontsStore } = useStores()

      const storefronts = StorefrontsStore.findInStore({
        query: {
          appId: this.reactiveUser.appId,
          $sort: { createdAt: -1 },
        },
      })

      return storefronts.data?.[0]?._id
    },
  },
  actions: {
    async authenticate(authData: AuthenticationRequest) {
      const { apps: AppsStore } = useStores()

      return this.authenticateBase({
        ...authData,
        ...(this.preferredAppId ? { preferredAppId: this.preferredAppId } : {}),
        ...(this.preferredStorefrontId
          ? { preferredStorefrontId: this.preferredStorefrontId }
          : {}),
        ...(authData.preferredAppId
          ? { preferredAppId: authData.preferredAppId }
          : {}),
        ...(authData.preferredStorefrontId
          ? { preferredStorefrontId: authData.preferredStorefrontId }
          : {}),
      }).then(() => {
        if (!this.parsedAccessToken) return

        const parsedToken = this.parsedAccessToken

        // Get his permitted app and redirect to appropriate alias
        return (
          parsedToken.appId &&
          AppsStore.get(parsedToken.appId)
            .then(() => {
              this.setPreferredStorefrontId(parsedToken.storefrontId)

              if (parsedToken.isSuperadmin) {
                this.setPreferredAppId(parsedToken.appId)

                return
              }

              this.removePreferredAppId()
            })
            .catch((error) => {
              notifyWarning('Error happened', error.message)

              this.logout()

              throw error
            })
        )
      })
    },
    async authenticateBase(authData: AuthenticationRequest) {
      const {
        users: UsersStore,
        storefronts: StorefrontsStore,
        shifts: ShiftsStore,
      } = useStores()
      const feathersClient = useFeathers()

      try {
        this.$state.isLoading = true

        const response = await feathersClient
          .authenticate(authData)
          .catch((error) => {
            throw error
          })

        const time = new Date().getTime()
        const after = DateFnsAddMinutes(time, 30).getTime()

        await Promise.all([
          UsersStore.get(response.user._id),
          ShiftsStore.find({
            query: {
              $or: [
                {
                  startsAt: { $lte: time },
                  endsAt: { $gte: time },
                },
                {
                  startsAt: { $lte: time },
                  endsAt: {
                    $lte: after,
                    $gte: time,
                  },
                },
              ],
              $sort: { startsAt: 1 },
            },
          }),
          StorefrontsStore.find({
            query: {
              appId: response.user.appId,
              $sort: { createdAt: -1 },
            },
          }),
        ])

        this.$state.accessToken = response.accessToken
        this.$state.authentication = response.authentication
        this.$state.user = { ...response.user }
        this.$state.isLoading = false
        this.$state.lastSessionLogin = Date.now()

        setPreferredEmails(response.user)

        return response
      } catch (error) {
        this.logout()

        throw error
      }
    },
    async logout() {
      const feathersClient = useFeathers()

      this.$state.isLoading = true

      window.localStorage.removeItem('preferredAppId')
      this.removePreferredAppId()

      return (
        feathersClient.authentication.removeAccessToken() &&
        feathersClient.authentication
          .reset()
          .then(() => {
            this.$state.accessToken = null
            this.$state.authentication = {}
            this.$state.user = null
            this.$state.lastSessionLogin = null
            this.$state.lastActivity = Date.now()
            this.$state.isLoading = false
          })
          .catch((error) => notifyWarning('Error happened', error.message))
      )
    },
    setPreferredAppId(id: string | null) {
      if (!this.reactiveUser?.isSuperadmin || !id) return

      this.preferredAppId = id
      window.localStorage.setItem('preferredAppId', id)
    },
    setPreferredStorefrontId(id: string | null) {
      this.preferredStorefrontId = id

      if (id) window.localStorage.setItem('preferredStorefrontId', id)

      useAppStore().setSelectedStorefrontId(id)
    },
    removePreferredAppId() {
      this.preferredAppId = null
      window.localStorage.removeItem('preferredAppId')
    },
  },
})
