<template>
  <ion-page>
    <ion-tabs
      :style="{
        'flex-direction': 'row',
      }"
    >
      <ion-router-outlet />
      <ion-tab-bar
        slot="top"
        :class="tabBarClasses"
        :style="{
          ...tabBarStyles,
          width: '92px',
        }"
        class="h-full"
      >
        <AppStack direction="flex-col" class="h-full pb-4">
          <div
            class="relative flex flex-shrink-0 items-center h-[96px] justify-center"
            @click="onLogoClick"
          >
            <AppLogoShort class="w-[42px]" />
          </div>
          <AppStack
            direction="flex-col"
            flex="flex-1"
            class="space-y-2 pt-1"
            align-items="items-center"
          >
            <div v-for="(tab, index) in nagivationConfig" :key="index">
              <div
                :class="tab.active ? ['bg-bg-elevation-1-alt'] : []"
                class="flex justify-center items-center transition-colors p-[14px] rounded-md"
                @click="router.replace(tab.to)"
              >
                <AppIcon
                  :icon="tab.icon"
                  :class="[
                    ...tab.iconClasses,
                    tab.active ? ['text-fg-elevation-2-primary'] : [],
                  ]"
                  stroke-width="1.7px"
                  size="custom"
                  class="w-[36px] h-[36px] transition-[stroke-width] duration-200"
                />
              </div>
            </div>
          </AppStack>
          <AppStack direction="flex-col" class="space-y-3">
            <!-- <div
                v-for="item in secondaryNavigation"
                :key="item.name"
                class="px-[14px]"
              >
                <AppStack
                  justify-content="justify-center"
                  align-items="items-center"
                  :to="item.name"
                  class="p-[14px] rounded-md
                    active:bg-bg-elevation-1-alt"
                  @click="item.onClick"
                >
                  <AppIcon
                    :icon="item.icon"
                    size="36"
                    class="text-fg-elevation-2-tertiary"
                  />
                </AppStack>
              </div> -->
            <AppStack
              justify-content="justify-center"
              align-items="items-center"
              class="px-[14px]"
              @click="onSignOutClick"
            >
              <AppStack
                justify-content="justify-center"
                align-items="items-center"
                class="w-[60px] h-[60px] rounded-full relative overflow-hidden"
              >
                <div
                  class="absolute top-0 left-0 w-full h-full rounded-full bg-bg-elevation-2-alt
                    opacity-50"
                />
                <div
                  class="absolute top-0 left-0 w-full h-full rounded-full transition-colors duration-300"
                >
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    :class="
                      AppStore.isOffline
                        ? ['text-border-yellow']
                        : ['text-border-blue', 'opacity-70']
                    "
                  >
                    <rect
                      x="1"
                      y="1"
                      width="58"
                      height="58"
                      rx="29"
                      :stroke-dasharray="strokeDashArrayValue"
                      stroke-width="4"
                      stroke="currentColor"
                      class="tranition-[stroke-dasharray] duration-200 ease-in-out"
                    />
                  </svg>
                </div>
                <span class="relative text-[30px]">
                  {{ AuthStore.reactiveUser?.profile.emoji }}
                </span>
              </AppStack>
            </AppStack>
          </AppStack>
        </AppStack>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useIonRouter, alertController } from '@ionic/vue'
import { useRoute, useRouter } from 'vue-router'
import { differenceInSeconds } from 'date-fns'
import { IonTabBar, IonTabs, IonRouterOutlet, IonPage } from '@ionic/vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppLogoShort from '@restify/packages/design-system/mid-level/AppLogoShort.vue'
import useTime from '@restify/packages/composables/useTime'
import useStores from '~/composables/useStores'
import { useAuthStore } from '~/stores/auth'
import { useAppStore } from '~/stores/app'
import packageJson from '../../package.json'

const routerVue = useRouter()
const router = useIonRouter()
const route = useRoute()
const { storefronts: StorefrontsStore } = useStores()
const AuthStore = useAuthStore()
const AppStore = useAppStore()
const { time } = useTime()

const storefront = computed(() => {
  if (!AppStore.selectedStorefrontId) return null

  return StorefrontsStore.getFromStore(AppStore.selectedStorefrontId).value
})

const nagivationConfig = computed(() => {
  if (!AuthStore.reactiveUser?.profile?.role) return []

  return [
    ...((storefront.value?.inHouseService.enabled ||
      storefront.value?.inHouseDelivery.enabled ||
      storefront.value?.pickup.enabled ||
      storefront.value?.delivery.enabled) &&
    ['cook', 'barmen', 'manager'].includes(
      AuthStore.reactiveUser?.profile?.role,
    )
      ? [
          {
            to: {
              name: 'kitchen',
            },
            icon: 'fire-flame',
            tab: 'kitchen',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
          {
            to: {
              name: 'stock',
            },
            icon: 'box-iso',
            tab: 'stock',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
    ...(storefront.value?.inHouseService.enabled &&
    storefront.value?.inHouseService.setup.cashier &&
    ['waiter', 'manager'].includes(AuthStore.reactiveUser?.profile?.role)
      ? [
          {
            to: {
              name: 'quick-order',
            },
            icon: 'computer',
            tab: 'quick-order',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
    ...(storefront.value?.inHouseService.enabled &&
    ['waiter', 'manager'].includes(AuthStore.reactiveUser?.profile?.role)
      ? [
          {
            to: {
              name: 'in-house',
            },
            icon: 'shop-alt',
            tab: 'in-house',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
    ...(storefront.value?.pickup.enabled &&
    ['waiter', 'manager'].includes(AuthStore.reactiveUser?.profile?.role)
      ? [
          {
            to: {
              name: 'pickup',
            },
            icon: 'shopping-bag',
            tab: 'pickup',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
    ...(storefront.value?.delivery.enabled &&
    ['waiter', 'manager'].includes(AuthStore.reactiveUser?.profile?.role)
      ? [
          {
            to: {
              name: 'delivery',
            },
            icon: 'delivery-truck',
            tab: 'delivery',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
    ...(['manager'].includes(AuthStore.reactiveUser?.profile?.role)
      ? [
          {
            to: {
              name: 'settings',
            },
            icon: 'settings',
            tab: 'settings',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
  ].map((tab) => {
    const href = routerVue.resolve(tab.to).href

    return {
      ...tab,
      href,
      active: href === route.fullPath,
    }
  })
})

const secondaryNavigation = computed(() => {
  return [
    {
      name: 'Logout',
      onClick: onSignOutClick,
      icon: 'log-out',
    },
  ]
})

const tabBarClasses = computed(() => {
  return {
    kitchen: ['shadow-elevation-1-0-lg'],
  }[String(route.name)]
})

const tabBarStyles = computed(() => {
  return {
    '--background': 'var(--rf-bg-elevation-1)',
    '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
    '--color': 'var(--rf-fg-elevation-1-tertiary)',
  }
})

const homeLink = computed(() => {
  if (
    storefront.value?.inHouseService.enabled ||
    storefront.value?.inHouseDelivery.enabled
  ) {
    return {
      name: 'in-house',
    }
  }

  if (storefront.value?.delivery.enabled) {
    return {
      name: 'delivery',
    }
  }

  if (storefront.value?.pickup.enabled) {
    return {
      name: 'pickup',
    }
  }

  return { name: 'kitchen' }
})

const strokeDashArrayValue = computed(() => {
  const length = 188.496

  if (AuthStore.reactiveUser?.profile.role !== 'waiter') {
    return `${length} ${length}`
  }

  return `${length - length / (50 / differenceSinceLastActivity.value)}, ${length}`
})

const differenceSinceLastActivity = computed(() => {
  return differenceInSeconds(time.value, AuthStore.lastActivity)
})

const onSignOutClick = () => {
  return AppStore.setLoginSheetVisibility(true)
}

const onLogoClick = async () => {
  const alert = await alertController.create({
    header: 'Version',
    message: `${packageJson.version}`,
    buttons: ['Close'],
  })

  await alert.present()
}

watch(
  computed(() => route.fullPath),
  () => {
    navigator.serviceWorker.ready.then((registration) => {
      console.log('Trying to update SW')
      registration.update()
    })
  },
)
</script>

<style>
.circular-chart {
  display: block;
}

.circle {
  stroke: #4cc790;
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
</style>
