// Pinia Store
import { type Ref } from 'vue'
import { defineStore } from 'pinia'
import useStores, { type Stores } from '~/composables/useStores'
import { useI18n } from 'vue-i18n'

type activeBreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
// type viewportHeightType = number | null

interface State {
  activeBreakpoint: activeBreakpointType
  selectedStorefrontId: null | string
  loginSheetIsOpen: boolean
  locale: 'en-gb' | 'bg' | 'ru'
  i18n: any
  networkConnectionStatus: 'disconnected' | 'reconnecting' | 'connected'
  // viewportHeight: viewportHeightType
}

export const useAppStore = defineStore('app', {
  state: (): State => ({
    activeBreakpoint: 'xs',
    selectedStorefrontId: null,
    loginSheetIsOpen: true,
    locale: 'en-gb',
    i18n: useI18n(),
    networkConnectionStatus: 'connected',
    // viewportHeight: null
  }),
  getters: {
    selectedStorefront(): Stores['storefronts']['Result'] | undefined {
      const { storefronts: StorefrontsStore } = useStores()

      return StorefrontsStore.getFromStore(this.selectedStorefrontId).value
    },
    loginSheetVisibility(): boolean {
      return this.loginSheetIsOpen
    },
    isOffline(): boolean {
      return ['disconnected', 'reconnecting'].includes(
        this.networkConnectionStatus,
      )
    },
  },
  actions: {
    setActiveBreakpoint(value: activeBreakpointType) {
      this.activeBreakpoint = value
    },
    setLoginSheetVisibility(value: boolean) {
      this.loginSheetIsOpen = value
    },
    setSelectedStorefrontId(storefrontId: string) {
      this.$state.selectedStorefrontId = storefrontId
    },
    setLocale(locale: 'en-gb' | 'bg' | 'ru') {
      this.$state.locale = locale
      this.i18n.locale = locale
    },
    setNetworkConnectionStatus(
      value: 'disconnected' | 'reconnecting' | 'connected',
    ) {
      this.$state.networkConnectionStatus = value
    },
    // setViewportHeight (value: viewportHeightType) {
    //   this.viewportHeight = value
    // },
  },
})
