<template>
  <AppStack
    justify-content="justify-between"
    align-items="items-start"
    class="w-full py-4"
  >
    <AppStack direction="flex-col">
      <AppTypography
        name="text-lg-7-medium"
        color="text-fg-elevation-2-primary"
        :text="props.title"
      />
      <AppTypography
        name="text-lg-7-medium"
        color="text-fg-elevation-2-secondary"
        :text="props.description"
      />
    </AppStack>
    <AppTypography
      :text="$t('promotions.remove')"
      color="text-fg-red"
      name="text-lg-7-medium"
      @click="emit('remove')"
    />
  </AppStack>
</template>

<script setup lang="ts">
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const emit = defineEmits(['remove'])

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
})
</script>
