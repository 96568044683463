<template>
  <AppStack
    :class="
      {
        2: 'bg-bg-elevation-2',
        3: 'bg-bg-elevation-3',
      }[props.elevation]
    "
    direction="flex-col"
    class="relative gap-[8px] p-3 pt-[10px] rounded-md shadow-elevation-1-0-xs group
      overflow-hidden"
    @click="emit('menu-item-tap', onClick)"
  >
    <div
      :class="
        {
          2: 'bg-bg-elevation-2-alt',
          3: 'bg-bg-elevation-3-alt',
        }[props.elevation]
      "
      class="absolute top-0 left-0 w-full h-full opacity-0 group-active:opacity-50"
    />
    <AppTypography
      :text="props.title"
      :color="
        {
          2: 'text-fg-elevation-2-primary',
          3: 'text-fg-elevation-3-primary',
        }[props.elevation]
      "
      name="text-lg-6-medium"
      class="relative line-clamp-3 h-[72px]"
    />
    <AppTypography
      :text="props.subtitle"
      :color="
        {
          2: 'text-fg-elevation-2-secondary',
          3: 'text-fg-elevation-3-secondary',
        }[props.elevation]
      "
      name="text-lg-6-medium"
      class="relative"
    />
    <transition
      enter-active-class="duration-500 ease-in-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0"
    >
      <AppStack
        v-if="adding"
        direction="flex-col"
        align-items="items-center"
        justify-items="justify-center"
        class="absolute top-0 left-0 w-full h-full pointer-events-none"
      >
        <div
          :class="
            {
              2: 'bg-bg-elevation-2',
              3: 'bg-bg-elevation-3',
            }[props.elevation]
          "
          class="absolute top-0 left-0 w-full h-full bg-bg-elevation-2"
        />
        <AppIcon
          size="custom"
          icon="check"
          stroke-width="2px"
          color="text-fg-blue"
          class="w-[28px] h-[28px] relative"
        />
        <AppTypography
          text="Added"
          :color="
            {
              2: 'text-fg-elevation-2-primary',
              3: 'text-fg-elevation-2-primary',
            }[props.elevation]
          "
          name="text-lg-6-medium"
          class="relative"
        />
      </AppStack>
    </transition>
  </AppStack>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const emit = defineEmits(['menu-item-tap'])
const props = defineProps({
  added: {
    type: Boolean,
    default: false,
  },
  elevation: {
    type: Number,
    default: 2,
    validator: (val: number) => [2, 3].includes(val),
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
})

const adding = ref(false)

const onClick = () => {
  adding.value = true

  setTimeout(() => (adding.value = false), 1000)
}
</script>
