<template>
  <ion-menu
    content-id="main-content"
    side="end"
    :menu-id="props.modalId"
    :style="menuStyles"
  >
    <PickupModalContent
      :order="order"
      :injected-order-id="props.orderId || undefined"
    />
  </ion-menu>
  <ion-page id="main-content" class="pointer-events-none" />
</template>

<script setup lang="ts">
import { IonMenu, IonPage } from '@ionic/vue'
import { PropType, computed, watch, ref } from 'vue'
import useStores from '~/composables/useStores'
import PickupModalContent from './PickupModalContent.vue'
import { useEventBus } from '@vueuse/core'

const props = defineProps({
  orderId: {
    type: String as PropType<string | null>,
    default: null,
  },
  modalId: {
    type: String,
    required: true,
  },
})

const {
  users: UsersStore,
  storefrontPlaces: StorefrontPlacesStore,
  orders: OrdersStore,
} = useStores()

const loading = ref(false)

const order = computed(() => {
  if (!props.orderId) return null

  return OrdersStore.getFromStore(props.orderId).value || null
})

const getOrder = () => {
  if (props.orderId === 'new') return Promise.resolve()

  return OrdersStore.find({ query: { _id: props.orderId } })
}

const getStorefrontPlace = () => {
  const ids = [
    ...(order.value?.storefrontPlaceIds || []),
    // ...injectedObjectId?.value ? [injectedObjectId?.value] : []
  ]

  return StorefrontPlacesStore.find({ query: { _id: { $in: ids } } })
}

const getStaff = () => {
  const staffIds = order.value?.staff.map((staff) => staff.staffId)

  if (!staffIds?.length) return Promise.resolve()

  return UsersStore.find({ query: { _id: { $in: staffIds } } })
}

const fetchData = () => {
  loading.value = false

  return getOrder()
    .then(() => Promise.all([getStaff(), getStorefrontPlace()]))
    .finally(() => (loading.value = false))
}

watch(
  computed(() => props.orderId),
  (newVal) => newVal && fetchData(),
)

const bus = useEventBus<
  | 'mark-as-in-progress'
  | 'mark-as-ready'
  | 'mark-as-delivery'
  | 'mark-as-picked-up'
>('pickup')
bus.on(onPickupEvent)

function onPickupEvent(
  eventName:
    | 'mark-as-in-progress'
    | 'mark-as-ready'
    | 'mark-as-delivery'
    | 'mark-as-picked-up',
) {
  if (eventName === 'mark-as-in-progress') {
    OrdersStore.patch(props.orderId, {
      'status.name': 'confirmed',
      'status.confirmedAt': new Date().getTime(),
    })
  } else if (eventName === 'mark-as-ready') {
    OrdersStore.patch(props.orderId, {
      'status.name': 'ready',
      'status.readyAt': new Date().getTime(),
    })
  } else if (eventName === 'mark-as-delivery') {
    OrdersStore.patch(props.orderId, {
      'status.name': 'delivery',
      'status.deliveryAt': new Date().getTime(),
    })
  } else if (eventName === 'mark-as-picked-up') {
    OrdersStore.patch(props.orderId, {
      'status.name': 'picked-up',
      'status.pickedUpAt': new Date().getTime(),
    })
  }
}

const menuStyles = computed(() => ({
  '--width': '450px',
  '--backdrop-opacity': 0.9,
}))
</script>

<style scoped>
ion-menu::part(backdrop) {
  background-color: rgba(18, 23, 33, 1);
}
</style>
