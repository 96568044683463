import { createI18n } from 'vue-i18n'

const messages = {
  'en-gb': {
    test: 'Test',
    kitchen: {
      title: 'Kitchen',
      table: 'Table',
      noTable: 'No table',
      collapsedOrders:
        'None ready | {n} more items ready | {n} more items ready | {n} more items ready',
      tabs: {
        active: 'Active',
        done: 'History',
      },
    },
    quickOrder: {
      title: 'Quick Order',
      order: 'Order',
      clear: 'Clear',
      sidebarPlaceholder: 'Add items on the left',
    },
    order: {
      order: 'Order',
      orderUnknown: 'Unknown order',
      checkout: 'Checkout',
      sendToWork: 'Send to work',
      batch: 'BATCH',
      draftBatch: 'DRAFT BATCH',
      createdOrderForThisTable: 'Add order to table',
      cancelOrder: 'Cancel Order',
      changeWaiter: 'Assign to me',
      actionsClose: 'Close',
      items: '0 items | 1 item | {count} items',
      table: 'Table',
      tableUnknown: 'Uknown table',
      delivery: 'Delivery',
      pickup: 'Pickup',
      transactionsTitle: 'Payment',
      paymentTitle: 'Payment',
      promotions: 'Promotions',
      back: 'Back',
      payCash: 'Pay in Cash',
      payCard: 'Pay with Card',
      startBatch: 'Start Batch',
      contentPlaceholder: 'Tap “Start Batch” in the sidebar to add menu-items.',
      receiptPrinted: 'Receipt is printed...',
      finilise: 'Finalise',
      noOrderPlaceholder: 'No order yet',
      createOrder: 'Create Order',
      addMenuItem: 'Add Menu item',
      sidebarPlaceholder: 'Add items on the right',
      newOrder: 'New Order',
    },
    promotions: {
      sidebarTitle: 'Applied Promotions',
      contentTitle: 'Add Promotion',
      sidebarPlaceholder: 'Add promotions on the right',
      remove: 'Remove',
      done: 'Done',
      searchInputPlaceholder: 'Start typing',
      searchCancel: 'Cancel',
      searchPlaceholder: 'Start typing and search results will appear here.',
      matchingPromotions: 'Matching Promotions',
      apply: 'Apply',
      applied: 'Applied',
    },
    inHouse: {
      title: 'In House',
      filterMap: 'Map',
      filterStatus: 'Status',
      filterWaiter: 'Waiter',
      mapMove: 'Move',
      mapMoveCancel: 'Cancel',
      mapMoveSelectTable: 'Select a table to move to',
      mapMoveTitle: 'Move order from table {from} to {to}',
      tables: '0 tables | 1 table | {count} tables',
      statusWaitingTitle: 'Waiting',
      statusInProgressTitle: 'In Progress',
      statusIdleTitle: 'Idle',
      statusCheckedOutTitle: 'Checked Out',
    },
    stock: {
      title: 'Stock',
      filterStock: 'Stock',
      filterGoods: 'Goods',
      filterOrders: 'Orders',
      newOrder: 'New Order',
    },
  },
  bg: {
    test: 'Тест',
    kitchen: {
      title: 'Кухня',
      table: 'Маса',
      noTable: 'Няма маса',
      collapsedOrders:
        'Нет готовых | Eщe {n} позиций готово | Eще {n} позиции готово | Eще {n} позиций готово',
      tabs: {
        active: 'Active',
        done: 'History',
      },
    },
    quickOrder: {
      title: 'Быстрый заказ',
      order: 'Заказ',
      clear: 'Очистить',
      sidebarPlaceholder: 'Добавьте позиции слева',
    },
    order: {
      order: 'Заказ',
      orderUnknown: 'Неизвестный заказ',
      checkout: 'Оплата',
      sendToWork: 'Отправить в работу',
      batch: 'ОЧЕРЕДЬ',
      draftBatch: 'Черновик заказа',
      createdOrderForThisTable: 'Создать заказ на стол',
      cancelOrder: 'Отменить заказ',
      changeWaiter: 'Закрепить за мной',
      actionsClose: 'Закрыть',
      table: 'Маса',
      tableUnknown: 'Неизвестный маса',
      delivery: 'Доставка',
      pickup: 'На вынос',
      transactionsTitle: 'Оплата',
      paymentTitle: 'Оплата',
      promotions: 'Промоакции',
      back: 'Назад',
      payCash: 'Оплата наличными',
      payCard: 'Оплата картой',
      startBatch: 'Добавить в заказ',
      contentPlaceholder:
        'Нажмите “Добавить в заказ” в левом меню, чтобы добавить позиции.',
      receiptPrinted: 'Чек печатается...',
      finilise: 'Завершить',
      noOrderPlaceholder: 'Пока нет заказа',
      createOrder: 'Создать Заказ',
      addMenuItem: 'Добавить позицию',
      sidebarPlaceholder: 'Добавьте позиции справа',
      newOrder: 'Новый Заказ',
    },
    promotions: {
      sidebarTitle: 'Примененные промоакции',
      contentTitle: 'Добавить промоакцию',
      sidebarPlaceholder: 'Добавьте промоакцию справа',
      remove: 'Удалить',
      done: 'Готово',
      searchInputPlaceholder: 'Начните печатать',
      searchCancel: 'Отменить',
      searchPlaceholder: 'Начните печатать и результат поиска появится здесь.',
      matchingPromotions: 'Доступные промоакции',
      apply: 'Применить',
      applied: 'Применена',
    },
    inHouse: {
      title: 'Внутри',
      filterMap: 'Карта',
      filterStatus: 'Статус',
      filterWaiter: 'Официант',
      mapMove: 'Переместить',
      mapMoveCancel: 'Отменить',
      mapMoveSelectTable: 'Выберите на какой столик перенести',
      mapMoveTitle: 'Переместить заказ с столика {from} на {to}',
      tables: 'Нет столиков | {n} столик | {n} столика | {n} столиков',
      statusWaitingTitle: 'Ждут',
      statusInProgressTitle: 'В процессе',
      statusIdleTitle: 'Кушают',
      statusCheckedOutTitle: 'Расчитаны',
    },
    stock: {
      title: 'Склад',
      filterStock: 'Склад',
      filterGoods: 'Полуфабрикат',
      filterOrders: 'Поръчки',
      newOrder: 'Нова поръчка',
    },
  },
  ru: {
    test: 'Тест',
    kitchen: {
      title: 'Кухня',
      table: 'Стола',
      collapsedOrders:
        'Нет готовых | Еще {n} позиций готово | Еще {n} позиции готово | Еще {n} позиций готово',
      noTable: 'Без стола',
      tabs: {
        active: 'В работе',
        done: 'История',
      },
    },
    quickOrder: {
      title: 'Быстрый заказ',
      order: 'Заказ',
      clear: 'Очистить',
      sidebarPlaceholder: 'Добавьте позиции слева',
    },
    order: {
      order: 'Заказ',
      orderUnknown: 'Неизвестный заказ',
      checkout: 'Оплата',
      sendToWork: 'Отправить в работу',
      batch: 'ОЧЕРЕДЬ',
      draftBatch: 'Черновик заказа',
      createdOrderForThisTable: 'Создать заказ на стол',
      cancelOrder: 'Отменить заказ',
      changeWaiter: 'Закрепить за мной',
      actionsClose: 'Закрыть',
      items: '0 позиций | {n} позиция | {n} позиций | {n} позиций',
      table: 'Столик',
      tableUnknown: 'Неизвестный столик',
      delivery: 'Доставка',
      pickup: 'На вынос',
      transactionsTitle: 'Оплата',
      paymentTitle: 'Оплата',
      promotions: 'Промоакции',
      back: 'Назад',
      payCash: 'Оплата наличными',
      payCard: 'Оплата картой',
      startBatch: 'Добавить в заказ',
      contentPlaceholder:
        'Нажмите “Добавить в заказ” в левом меню, чтобы добавить позиции.',
      receiptPrinted: 'Чек печатается...',
      finilise: 'Завершить',
      noOrderPlaceholder: 'Пока нет заказа',
      createOrder: 'Создать Заказ',
      addMenuItem: 'Добавить позицию',
      sidebarPlaceholder: 'Добавьте позиции справа',
      newOrder: 'Новый Заказ',
    },
    promotions: {
      sidebarTitle: 'Примененные промоакции',
      contentTitle: 'Добавить промоакцию',
      sidebarPlaceholder: 'Добавьте промоакцию справа',
      remove: 'Удалить',
      done: 'Готово',
      searchInputPlaceholder: 'Начните печатать',
      searchCancel: 'Отменить',
      searchPlaceholder: 'Начните печатать и результат поиска появится здесь.',
      matchingPromotions: 'Доступные промоакции',
      apply: 'Применить',
      applied: 'Применена',
    },
    inHouse: {
      title: 'Внутри',
      filterMap: 'Карта',
      filterStatus: 'Статус',
      filterWaiter: 'Официант',
      mapMove: 'Переместить',
      mapMoveCancel: 'Отменить',
      mapMoveSelectTable: 'Выберите на какой столик перенести',
      mapMoveTitle: 'Переместить заказ с столика {from} на {to}',
      tables: 'Нет столиков | {n} столик | {n} столика | {n} столиков',
      statusWaitingTitle: 'Ждут',
      statusInProgressTitle: 'В процессе',
      statusIdleTitle: 'Кушают',
      statusCheckedOutTitle: 'Расчитаны',
    },
    stock: {
      title: 'Склад',
      filterStock: 'Склад',
      filterGoods: 'Полуфабрикаты',
      filterOrders: 'Заявки',
      newOrder: 'Новая заявка',
    },
  },
}

function customRule(choice: any, choicesLength: any, orgRule: any) {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}

export const i18n = createI18n({
  locale: 'en-gb',
  fallbackLocale: 'en-gb',
  legacy: false,
  pluralizationRules: {
    ru: customRule,
    bg: customRule,
  },
  messages,
})

export default () => {
  return { provide: { i18n } }
}
