<template>
  <AppStack direction="flex-col" class="px-7">
    <AppStack direction="flex-col">
      <AppStack align-items="items-baseline" class="mb-6">
        <AppTypography
          :text="formatPrice(orderTotal)"
          name="heading-205xl-8-bold"
          color="text-fg-elevation-2-primary"
        />
        <AppTypography
          :text="quantityText"
          name="heading-2xl-7-semibold"
          color="text-fg-elevation-2-secondary"
          class="ml-3"
        />
      </AppStack>
    </AppStack>
    <AppStack class="gap-3">
      <button
        :class="
          checkoutIsDisabled || sendToWorkInProgress
            ? ['pointer-events-none']
            : []
        "
        variant="custom"
        class="py-[18px] relative rounded-lg overflow-hidden flex-1"
        @click="$emit('batch-send-to-work')"
      >
        <div
          :class="
            checkoutIsDisabled || sendToWorkInProgress
              ? ['bg-bg-elevation-2-alt']
              : ['bg-bg-blue']
          "
          class="absolute top-0 left-0 w-full h-full active:brightness-90"
        />
        <AppFadeTransition>
          <AppSpinner v-if="sendToWorkInProgress" variant="fill-white" />
          <AppTypography
            v-else
            :color="
              checkoutIsDisabled
                ? 'text-fg-elevation-2-secondary'
                : 'text-universal-fg-opposite'
            "
            :text="$t('order.sendToWork')"
            name="text-lg-7-semibold"
            class="relative"
          />
        </AppFadeTransition>
      </button>
      <button
        variant="custom"
        class="px-4 relative rounded-lg overflow-hidden border-[1px]
          border-border-elevation-3-secondary border-solid"
        @click="emit('clear-tap')"
      >
        <div
          class="absolute top-0 left-0 w-full h-full bg-bg-elevation-3
            active:bg-bg-elevation-3-alt"
        />
        <AppIcon
          color="text-fg-elevation-3-secondary"
          icon="bin-minus"
          size="custom"
          class="w-[32px] h-[32px] relative"
        />
      </button>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'
import AppSpinner from '@restify/packages/design-system/low-level/AppSpinner.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import { type Stores } from '~/composables/useStores'
import { useI18n } from 'vue-i18n'
import useAppHelpers from '~/composables/useAppHelpers'

const { formatPrice } = useAppHelpers()
const emit = defineEmits(['batch-send-to-work', 'clear-tap'])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
  batchItems: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  sendToWorkInProgress: {
    type: Boolean,
    default: false,
  },
})

const checkoutIsDisabled = computed(() => {
  return !props.batchItems.length
})

const orderTotal = computed(() => {
  return props.batchItems.reduce((acc: number, menuItem) => {
    const variationsTotal = (
      menuItem.menuItem as Stores['orders']['Result']['menuItems'][number]
    ).variations.reduce((total, variation) => {
      total = total + variation.price

      return total
    }, 0)

    const extrasTotal = (
      menuItem.menuItem as Stores['orders']['Result']['menuItems'][number]
    ).extras.reduce((total, extra) => {
      total = total + extra.price

      return total
    }, 0)

    acc =
      acc + (menuItem.price + variationsTotal + extrasTotal) * menuItem.quantity

    return acc
  }, 0)
})

const { t } = useI18n()

const quantityText = computed(() => {
  const quantity = Object.values(props.batchItems || {}).reduce(
    (acc: number, item: Stores['orders']['Result']['menuItems'][number]) => {
      if (!props.order) return 0

      acc = acc + item.quantity

      return acc
    },
    0,
  )

  return t('order.items', quantity)
})
</script>
