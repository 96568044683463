<template>
  <AppStack
    direction="flex-col"
    class="w-full"
    justify-content="justify-center"
    align-items="items-center"
  >
    <AppStack
      direction="flex-col"
      class="w-full"
      justify-content="justify-center"
      align-items="items-center"
    >
      <AppIcon
        icon="plus"
        stroke-width="1.5"
        color="text-fg-elevation-2-tertiary"
        class="w-[64px] h-[64px] opacity-30"
      />
      <AppTypography
        :text="props.text"
        color="text-fg-elevation-2-tertiary"
        name="text-lg-7-medium"
        class="text-center"
      />
    </AppStack>
  </AppStack>
</template>

<script lang="ts" setup>
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
})
</script>
