<template>
  <AppStack direction="flex-col" class="px-7">
    <AppStack direction="flex-col">
      <AppStack
        direction="flex-col"
        align-items="items-baseline"
        class="mb-6 gap-1"
      >
        <AppStack
          v-for="(caption, index) in captionsList"
          :key="index"
          justify-content="justify-between"
          align-items="items-baseline"
          class="w-full"
        >
          <AppTypography
            :text="caption.key"
            name="text-lg-7-medium"
            color="text-fg-elevation-2-secondary"
          />
          <AppTypography
            :text="caption.value"
            name="text-lg-7-medium"
            color="text-fg-elevation-2-secondary"
            class="ml-3"
          />
        </AppStack>
      </AppStack>
      <AppStack align-items="items-baseline">
        <AppTypography
          :text="formatPrice(orderTotal)"
          name="heading-205xl-8-bold"
          color="text-fg-elevation-2-primary"
        />
        <AppTypography
          :text="quantityText"
          name="heading-2xl-7-semibold"
          color="text-fg-elevation-2-secondary"
          class="ml-3"
        />
      </AppStack>
    </AppStack>
    <AppStack class="gap-2 mt-6">
      <button
        variant="custom"
        class="py-[18px] relative rounded-lg overflow-hidden flex-1 flex justify-center
          items-center"
        :class="orderNotPaidAtAllYet ? [] : ['opacity-50']"
        @click="orderNotPaidAtAllYet && bus.emit('promotions-tap', props.order)"
      >
        <div
          class="bg-bg-blue opacity-20 absolute top-0 left-0 w-full h-full"
        />
        <AppIcon
          icon="percentage"
          stroke-width="1.5"
          color="text-fg-blue"
          class="w-7 h-7 mr-3"
        />
        <AppTypography
          :text="$t('order.promotions')"
          color="text-fg-blue"
          name="text-lg-7-semibold"
          class="relative"
        />
      </button>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { computed, PropType } from 'vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { useI18n } from 'vue-i18n'
import useAppHelpers from '~/composables/useAppHelpers'

const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const { formatPrice } = useAppHelpers()
const bus = useEventBus<string>('app')
const { promotions: PromotionsStore, transactions: TransactionsStore } =
  useStores()

const promotionsList = computed(() => {
  const ids = [...Object.keys(props.order?.appliedPromotions?.promotions || {})]

  if (!ids.length) return []

  return ids.map((id) => {
    return PromotionsStore.getFromStore(id)
  })
})

const orderNotPaidAtAllYet = computed(() => {
  if (!props.order?._id) return []

  return TransactionsStore.findInStore({
    orderId: props.order?._id,
    $sort: {
      createdAt: 1,
    },
  }).every((item) => item.value?.reversed)
})

const orderTotal = computed(() => {
  return typeof props?.order?.appliedPromotions?.total === 'number'
    ? props.order?.appliedPromotions?.total || 0
    : props.order?.total || 0
})

const { t } = useI18n()

const quantityText = computed(() => {
  const quantity = props.order?.menuItems.reduce(
    (acc: number, item: Stores['orders']['Result']['menuItems'][number]) => {
      if (!props.order) return 0

      acc = acc + item.quantity

      return acc
    },
    0,
  )

  return t('order.items', quantity)
})

const captionsList = computed(() => {
  return [
    ...(promotionsList.value.length
      ? [
          {
            key: 'Total',
            value: formatPrice(props.order?.total),
          },
        ]
      : []),
    ...promotionsList.value.map((promotion) => ({
      key: promotion.value?.name,
      value: formatPrice(
        props.order?.appliedPromotions?.promotions?.[promotion.value?._id]
          ?.discount
          ? -props.order?.appliedPromotions?.promotions?.[promotion.value?._id]
              ?.discount
          : 0,
      ),
    })),
  ]
})
</script>
