import { intervalToDuration } from 'date-fns'

export const formatDuration = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  const zeroPad = (num: number) => String(num).padStart(2, '0')

  const formatted = [
    duration.hours || 0,
    duration.minutes || 0,
    duration.seconds || 0,
  ]
    .filter((value, index) => (index === 0 ? Boolean(value) : true))
    .map(zeroPad)
    .join(':')

  return formatted
}
