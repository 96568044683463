<template>
  <AppStack
    v-if="props.loading"
    align-items="items-center"
    justify-content="justify-center"
    flex="flex-1"
    class="h-full"
  >
    <ion-spinner :style="{ '--color': 'var(--rf-fg-elevation-2-secondary)' }" />
  </AppStack>
  <OrderModalContentTransactions
    v-else-if="props.activeScreen === 'transactions'"
    :order="props.order"
    @back-tap="emit('update:active-screen', 'default')"
    @finalise-tap="emit('update:active-screen', 'finalise')"
  />
  <OrderModalContentFinalise
    v-else-if="props.activeScreen === 'finalise'"
    :order="props.order"
  />
  <OrderModalContentCatalog
    v-else-if="
      props.activeScreen === 'edit' ||
      (props.activeScreen === 'default' && !displayBatchedUi)
    "
    :order="props.order"
    :loading="props.loading"
    :title="$t('order.addMenuItem')"
  />
  <OrderModalContentEmpty v-else />
</template>

<script lang="ts">
export default { name: 'OrderModalContent' }
</script>

<script setup lang="ts">
import { IonSpinner } from '@ionic/vue'
import { type PropType, computed, ref } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import OrderModalContentCatalog from './OrderModalContentCatalog.vue'
import OrderModalContentEmpty from './OrderModalContentEmpty.vue'
import OrderModalContentTransactions from './OrderModalContentTransactions.vue'
import OrderModalContentFinalise from './OrderModalContentFinalise.vue'
import { type Stores } from '~/composables/useStores'
import { useAppStore } from '~/stores/app'

const emit = defineEmits(['update:active-screen'])
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  activeScreen: {
    type: String as PropType<'default' | 'edit' | 'transactions' | 'finalise'>,
    required: true,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const AppStore = useAppStore()

const displayBatchedUi = computed(() => {
  return AppStore?.selectedStorefront?.inHouseService.setup.batches
})
</script>
