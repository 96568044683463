<template>
  <AppStack
    class="relative rounded pl-[10px] shadow-elevation-2-0-xs select-none
      focus-within:ring-1 focus-within:ring-inset focus-within:ring-blue-300"
    :class="statusColor || 'bg-bg-elevation-2'"
    @click="onClickHandler"
  >
    <AppStack
      align-items="items-center"
      class="relative space-x-3 pl-[18px] pr-5 py-3 flex flex-1 gap-4 min-w-0 w-full
        focus:outline-none cursor-pointer bg-bg-elevation-2 rounded-r"
    >
      <AppStack
        direction="flex-col"
        wrap="flex-wrap"
        flex="flex-1"
        class="overflow-hidden gap-2"
      >
        <AppTypography
          :text="`${props.menuItem.quantity} x ${useInternalName(props.menuItem.name, props.menuItem.internalName)}`"
          size="text-lg-7-medium"
          color="text-fg-elevation-2-primary"
          class="whitespace-nowrap overflow-hidden text-ellipsis w-full"
          :class="
            ['served'].includes(props.menuItem.status?.name)
              ? 'text-fg-elevation-2-secondary line-through'
              : undefined
          "
        />
        <StationFadeGradient
          v-if="shouldShowGradient"
          class="absolute top-[6px] right-[46px] h-10 w-[140px]"
        />
        <div
          class="absolute top-[6px] right-0 h-10 w-[46px] bg-bg-elevation-2"
        />
        <AppStack
          v-if="shouldShowTimer"
          class="absolute top-3 right-4 bg-bg-elevation-2"
        >
          <AppTypography
            :text="`+${HelpersFormatDuration(overCookingTime)}`"
            size="text-lg-7-medium"
            :color="overCookingTimeColor"
          />
          <StationFadeGradient class="absolute right-[100%] h-10 w-[164px]" />
        </AppStack>
        <AppStack
          v-if="scheduledAction"
          tag="button"
          align-items="items-center"
          justify-content="justify-center"
          class="w-10 h-10 rounded-sm cursor-pointer bg-bg-elevation-0 absolute top-[6px]
            right-[6px]"
          @click="scheduledAction = null"
        >
          <ArrowUturnLeftIcon
            class="text-fg-elevation-2-tertiary relative h-7 w-7"
          />
        </AppStack>
        <AppStack v-if="props.menuItem.variations?.length">
          <Cog8ToothIcon
            class="h-5 w-5 text-fg-elevation-2-secondary mt-[3px] mr-[10px]"
            aria-hidden="true"
          />
          <AppStack direction="flex-col">
            <AppTypography
              v-for="variation in props.menuItem.variations"
              :key="variation.id"
              :text="useInternalName(variation.optionName)"
              color="text-fg-elevation-2-secondary"
              size="text-lg-6-normal"
            />
          </AppStack>
        </AppStack>
        <AppStack v-if="props.menuItem.extras?.length">
          <PlusIcon
            class="h-5 w-5 text-fg-elevation-2-secondary mt-[3px] mr-[10px]"
            aria-hidden="true"
          />
          <AppStack direction="flex-col">
            <AppTypography
              v-for="extra in props.menuItem.extras"
              :key="extra.id"
              :text="useInternalName(extra.optionName)"
              color="text-fg-elevation-2-secondary"
              size="text-lg-6-normal"
            />
          </AppStack>
        </AppStack>
        <AppStack v-if="props.menuItem.removables?.length">
          <MinusIcon
            class="h-5 w-5 text-fg-elevation-2-secondary mt-[3px] mr-[10px]"
            aria-hidden="true"
          />
          <AppStack direction="flex-col">
            <AppTypography
              v-for="removed in props.menuItem.removables"
              :key="removed.id"
              :text="removed.name"
              color="text-fg-elevation-2-secondary"
              size="text-lg-6-normal"
            />
          </AppStack>
        </AppStack>
        <AppStack v-if="props.menuItem.comment">
          <AppIcon
            icon="message-text"
            color="text-fg-blue"
            class="h-5 w-5 text-fg-elevation-2-secondary mt-[3px] mr-[10px]"
            stroke-width="1.5"
          />
          <AppStack direction="flex-col">
            <AppTypography
              :text="props.menuItem.comment"
              color="text-fg-elevation-2-secondary"
              size="text-lg-6-normal"
            />
          </AppStack>
        </AppStack>
      </AppStack>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { useInternalName } from '~/composables/useFallbackLanguage'
import { PropType, computed, watch, ref } from 'vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import StationFadeGradient from '~/components/StationFadeGradient.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import { Cog8ToothIcon } from '@heroicons/vue/24/outline'
import {
  ArrowUturnLeftIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/vue/24/solid'
import { useCallbackOnClick } from '@restify/packages/composables/clicks'
import useTime from '@restify/packages/composables/useTime'
import {
  differenceInSeconds as DateFnsDifferenceInSeconds,
  add as DateFnsAdd,
} from 'date-fns'
import useStores, { type Stores } from '~/composables/useStores'
import { formatDuration as HelpersFormatDuration } from '~/helpers/format-duration'

const props = defineProps({
  orderId: {
    type: String,
    required: true,
  },
  menuItem: {
    type: Object as PropType<Stores['orders']['Result']['menuItems'][number]>,
    required: true,
  },
  staff: {
    type: Object as PropType<{
      shiftId?: string
      staffId: string
      role: string
      processed: boolean
    }>,
    default: null,
  },
})

const statusColorMap: Record<
  Stores['orders']['Result']['menuItems'][number]['status']['name'],
  string
> = {
  ready: '',
  cancelled: '',
  idle: 'bg-bg-blue',
  inProgress: 'bg-bg-green',
}

const nextStatusMap: Record<
  Stores['orders']['Result']['menuItems'][number]['status']['name'],
  Stores['orders']['Result']['menuItems'][number]['status']['name']
> = {
  idle: 'inProgress',
  inProgress: 'ready',
  ready: 'served',
  served: 'served',
  cancelled: 'cancelled',
}

const { time } = useTime()
const { menuItems: MenuItemsStore, orders: OrdersStore } = useStores()
const { handler: onClickHandler } = useCallbackOnClick(500, 2, () =>
  updateMenuItemStatus(),
)
const scheduledAction = ref<{
  status: Stores['orders']['Result']['menuItems'][number]['status']['name']
  time: ReturnType<typeof Date.now>
} | null>(null)
const scheduledActionTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

const order = computed(() => {
  return OrdersStore.getFromStore(props.orderId).value
})

const currentStatus = computed(() => {
  return scheduledAction.value?.status || props.menuItem.status?.name
})

const statusColor = computed(
  () =>
    statusColorMap[
      scheduledAction.value?.status || props.menuItem.status?.name
    ],
)

const menuItem = computed(
  () => MenuItemsStore.getFromStore(props.menuItem.menuItemId).value,
)

const shouldShowGradient = computed(() => {
  return scheduledAction.value || overCookingTime.value < 0
})

const shouldShowTimer = computed(() => {
  return (
    estimatedCookingTime.value > 0 &&
    ['idle', 'inProgress'].includes(currentStatus.value) &&
    overCookingTime.value < 0 &&
    !scheduledAction.value
  )
})

const overCookingTime = computed(() => {
  const startTime = props.menuItem.status.inProgressAt

  if (!startTime) return 0

  const shouldBeReadyByTime = DateFnsAdd(new Date(startTime), {
    seconds: estimatedCookingTime.value,
  })
  const secondsLeftToCook = DateFnsDifferenceInSeconds(
    shouldBeReadyByTime,
    new Date(time.value),
  )

  return secondsLeftToCook
})

const overCookingTimeColor = computed(() => {
  const significantlyOvercookedTime = estimatedCookingTime.value * 60 * 0.5

  return overCookingTime.value > significantlyOvercookedTime
    ? 'text-fg-yellow'
    : 'text-fg-red'
})

const estimatedCookingTime = computed(() => {
  if (!menuItem.value) return 0

  return menuItem.value.cookingTime * 60
})

const updateMenuItemStatus = () => {
  const currentStatus =
    scheduledAction.value?.status || props.menuItem.status.name
  const nextStatus = nextStatusMap[currentStatus]

  if (currentStatus === nextStatus) return

  scheduledAction.value = {
    status: nextStatus,
    time: Date.now(),
  }
}

watch(
  computed(() => scheduledAction.value),
  (newValue, oldValue) => {
    // Set timer to execute if not undone
    if (oldValue === null && newValue) {
      scheduledActionTimeout.value = setTimeout(() => {
        if (!scheduledAction.value || !order.value) return

        OrdersStore.patch(
          props.orderId,
          {
            $set: {
              'menuItems.$.status.name': scheduledAction.value.status,
              [`menuItems.$.status.${scheduledAction.value.status}At`]:
                scheduledAction.value.time,
            },
          },
          {
            query: {
              'menuItems._id': props.menuItem._id,
            },
          },
        )

        scheduledAction.value = null
      }, 2000)

      return
    }

    // newValue === null means action is undone
    // let's clear the timer
    if (newValue === null && oldValue && scheduledActionTimeout.value) {
      clearTimeout(scheduledActionTimeout.value)

      return
    }
  },
)
</script>
