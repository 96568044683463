import { computed } from 'vue'
import { useAuthStore } from '~/stores/auth'

export function translateField(field, languageId) {
  const fallbackItem = field.find((item) => item.menuLanguageId === languageId)

  return fallbackItem ? fallbackItem.text : 'No text'
}

export default function useFallbackLanguage(field, fallback?) {
  const AuthStore = useAuthStore()
  const menulanguages = AuthStore.userApp?.menuLanguages || []
  const userPreferredAppLanguage =
    AuthStore.reactiveUser?.profile.preferredAppLanguage
  // const contentLanguage = computed(() => {
  //   return AuthStore.userApp?.menuLanguages.find((language) => language.code === userPreferredAppLanguage)
  // })
  const fallbackLanguage = computed(
    () => fallback || menulanguages.find((language) => language.fallback),
  )

  if (fallback) {
    const translatedItemWithPassedFallback = field.find(
      (item) => item.menuLanguageId === fallback.value._id,
    )

    if (translatedItemWithPassedFallback)
      return translatedItemWithPassedFallback.text
  }

  // if (!contentLanguage.value && !fallbackLanguage.value) return field[0]?.text || '[i18n-content]'

  // const translatedItem = field.find(item => item.menuLanguageId === contentLanguage.value?._id)

  // if (translatedItem) return translatedItem.text

  const translatedItemWithFallback = field.find(
    (item) => item.menuLanguageId === fallbackLanguage.value._id,
  )

  return translatedItemWithFallback
    ? translatedItemWithFallback.text
    : field?.[0]?.text || 'No text'
}

export function useInternalName(
  field: { menuLanguageId: string; text: string }[],
  internalName?: string,
) {
  if (internalName) return internalName

  const AuthStore = useAuthStore()
  const menulanguages = AuthStore.userApp?.menuLanguages || []
  const fallbackLanguage = computed(() =>
    menulanguages.find((language) => language.fallback),
  )

  return useFallbackLanguage(field, fallbackLanguage)
}
