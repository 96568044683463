<template>
  <AppStack
    direction="flex-col"
    class="overflow-y-auto h-full bg-bg-elevation-2"
  >
    <ion-toolbar :style="sidebarToolbarStyles">
      <AppTypography
        text="Stock Order"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-primary"
      />
      <AppTypography
        v-if="props.order?.publicId"
        :text="` ${props.order.publicId}`"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-secondary"
      />
    </ion-toolbar>
    <AppStack direction="flex-col">
      <ion-list
        :style="{
          '--ion-item-background': 'var(--rf-bg-elevation-2)',
        }"
      >
        <IonItemSliding
          v-for="(product, index) in products"
          :key="product.product._id"
        >
          <IonItem
            :style="{
              '--background': 'var(--rf-bg-elevation-2)',
              '--border-color': 'var(--rf-border-elevation-2-secondary)',
              '--padding-start': '28px',
              '--padding-end': '28px',
            }"
            lines="inset"
            class="item"
          >
            <AppStack
              align-items="items-center"
              class="py-5 gap-3 relative overflow-hidden w-full"
            >
              <AppTypography
                :text="product.product.name"
                name="text-lg-7-medium"
                color="text-fg-elevation-3-primary"
                class="flex-1"
              />
              <AppStack class="gap-4" align-items="items-center">
                <button
                  v-if="!props.stockOrderId"
                  class="shadow-elevation-1-0-xs w-[60px] py-1 rounded-md active:bg-bg-elevation-3-alt
                    border-solid border border-border-elevation-3-secondary"
                  @click="onQuantityChange('-', product)"
                  @mousedown="onMouseHold(() => onQuantityChange('-', product))"
                  @touchstart="
                    onMouseHold(() => onQuantityChange('-', product), $event)
                  "
                  @contextmenu="($event) => $event.preventDefault()"
                >
                  <AppIcon
                    icon="minus"
                    stroke-width="1.8"
                    color="text-fg-elevation-3-primary"
                    class="w-[24px] h-[24px] pointer-events-none"
                  />
                </button>
                <AppTypography
                  name="text-lg-7-medium"
                  tag="div"
                  color="text-fg-elevation-2-secondary"
                  class="flex-1 w-[40px] whitespace-nowrap text-center flex justify-center
                    !tracking-tighter"
                >
                  <div>{{ renderAmount(product) }}</div>
                </AppTypography>
                <button
                  v-if="!props.stockOrderId"
                  class="shadow-elevation-1-0-xs w-[60px] py-1 rounded-md active:bg-bg-elevation-3-alt
                    border-solid border border-border-elevation-3-secondary"
                  @click="onQuantityChange('+', product)"
                  @mousedown="onMouseHold(() => onQuantityChange('+', product))"
                  @touchstart="
                    onMouseHold(() => onQuantityChange('+', product), $event)
                  "
                  @contextmenu="($event) => $event.preventDefault()"
                >
                  <AppIcon
                    icon="plus"
                    stroke-width="1.8"
                    color="text-fg-elevation-3-primary"
                    class="w-[24px] h-[24px] pointer-events-none"
                  />
                </button>
              </AppStack>
            </AppStack>
          </IonItem>
          <IonItemOptions
            v-if="!props.stockOrderId"
            side="end"
            :style="{
              'border-bottom-color':
                products.length === index + 1
                  ? 'transparent'
                  : 'var(--rf-border-elevation-2-secondary)',
            }"
          >
            <IonItemOption class="bg-bg-red" @click="onDelete(product, $event)">
              <AppIcon
                icon="bin-minus"
                stroke-width="1.8"
                color="text-universal-fg-opposite"
                class="w-[24px] h-[24px] pointer-events-none"
              />
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
        <div class="h-[220px]" />
      </ion-list>
    </AppStack>
    <div
      v-if="!props.stockOrderId"
      class="transition-transform duration-300 ease-in-out z-[10] absolute bottom-0 left-0
        w-full safe-bottom-max bg-bg-elevation-2"
    >
      <div
        class="absolute top-[-100px] left-0 w-full h-[100px] pointer-events-none
          bg-gradient-to-b to-bg-elevation-2 from-transparent"
      />
      <AppStack direction="flex-col" class="px-7">
        <AppStack class="gap-3">
          <button
            :class="
              !products.length ? ['pointer-events-none', 'opacity-50'] : []
            "
            variant="custom"
            class="py-[18px] relative rounded-lg overflow-hidden flex-1"
            @click="onStockOrderSubmit"
          >
            <div
              class="bg-bg-blue absolute top-0 left-0 w-full h-full active:brightness-90"
            />
            <AppTypography
              color="text-universal-fg-opposite"
              text="Submit"
              name="text-lg-7-semibold"
              class="relative"
            />
          </button>
        </AppStack>
      </AppStack>
    </div>
  </AppStack>
</template>

<script setup lang="ts">
import {
  IonToolbar,
  IonList,
  IonItemSliding,
  IonItem,
  IonItemOption,
  IonItemOptions,
} from '@ionic/vue'
import { PropType, computed } from 'vue'
import { cleanCopy } from '@restify/packages/helpers/object'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import useMouseHold from '@restify/packages/composables/useMouseHold'
import useStores from '~/composables/useStores'

const emit = defineEmits(['update:model-value', 'dismiss'])
const props = defineProps({
  modelValue: {
    type: Array as PropType<unknown[] | null>,
    default: null,
  },
  stockOrderId: {
    type: [String, null] as PropType<null | string>,
    required: true,
  },
})

const { products: ProductsStore, procurementOrders: ProcurementOrdersStore } =
  useStores()
const { onMouseHold } = useMouseHold()

const sidebarToolbarStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
  'border-bottom': '1px solid var(--rf-border-elevation-2-secondary)',
  '--padding-top': '24px',
  '--padding-bottom': '24px',
  'padding-left': '24px',
  'padding-right': '24px',
}))

const StockOrder = computed(() => {
  return (
    props.stockOrderId &&
    ProcurementOrdersStore.getFromStore(props.stockOrderId).value
  )
})

const products = computed(() => {
  return StockOrder.value
    ? Object.values(StockOrder.value.order).map((item) => {
        return {
          product: ProductsStore.getFromStore(item.productId).value,
          amount: item.amount,
        }
      })
    : props.modelValue
})

const onQuantityChange = (type: '+' | '-', product) => {
  const newModelValue = cleanCopy(props.modelValue)
  const newProductIndex = newModelValue?.findIndex(
    (item) => item.product._id === product.product._id,
  )
  const amount = newModelValue[newProductIndex].amount
  const ammountStep = product.product.unitOfMeasure === 'item' ? 1 : 50
  const newAmount = type === '+' ? amount + ammountStep : amount - ammountStep

  if (newAmount < 0) {
    newModelValue?.splice(newProductIndex, 1)

    return emit('update:model-value', newModelValue)
  }

  newModelValue[newProductIndex].amount = newAmount

  emit('update:model-value', newModelValue)
}

const renderAmount = (product) => {
  const smallUnitsToLarge = {
    gr: 'kg',
    ml: 'l',
    item: 'item',
  }

  if (product.amount >= 1000) {
    return `${product.amount / 1000} ${smallUnitsToLarge[product.product.unitOfMeasure]}.`
  }

  return `${product.amount} ${product.product.unitOfMeasure}.`
}

const onStockOrderSubmit = () => {
  return ProcurementOrdersStore.create({
    order: props.modelValue?.reduce((acc, product) => {
      acc[product.product._id] = {
        productId: product.product._id,
        amount: product.amount,
        price: 0,
      }

      return acc
    }, {}),
    entry: {},
    status: 'backlog',
  }).then(() => {
    return emit('dismiss')
  })
}

const onDelete = (product: unknown, $event) => {
  if (props.stockOrderId) return

  const ionItem =
    $event.target.parentElement.parentElement.querySelector('.item')

  if (!ionItem) return

  ionItem.style.height = `${ionItem.clientHeight}px`
  ionItem.style.transition = 'height 200ms cubic-bezier(.68,.56,0,1)'
  getComputedStyle(ionItem).height

  requestAnimationFrame(() => {
    ionItem.style.height = '0px'

    setTimeout(() => {
      const newModelValue = cleanCopy(props.modelValue)
      const newProductIndex = newModelValue?.findIndex(
        (item) => item.product._id === product.product._id,
      )

      if (newProductIndex > -1) newModelValue?.splice(newProductIndex, 1)

      emit('update:model-value', newModelValue)
    }, 200)
  })
}
</script>

<style scoped>
ion-menu::part(backdrop) {
  background-color: rgba(18, 23, 33, 1);
}
</style>
