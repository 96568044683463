<template>
  <AppStack direction="flex-col">
    <OrderModalSidebarHeader
      :order="props.order"
      :injected-object-id="props.injectedObjectId"
      is-new-batch
      class="sticky top-0"
      @end-batch-tap="emit('end-batch-tap')"
    />
    <AppStack direction="flex-col">
      <AppStack direction="flex-col">
        <OrderModalMenuitemsList
          v-if="props.order && menuItems.length"
          :items="menuItems"
          is-edit
          quantity-changable
          deletable
          class="flex-1"
          @increase-quantity="onIncreaseQuantity"
          @decrease-quantity="onDecreaseQuantity"
          @delete="onDelete"
          @add-comment="onComment"
        />
      </AppStack>
      <OrderModalSidebarEmpty
        v-if="props.order && !menuItems.length"
        :text="$t('order.sidebarPlaceholder')"
        class="absolute top-1/2 left-0 -translate-y-1/2"
      />
      <div
        class="fixed bottom-0 left-0 w-[370px] safe-bottom-max bg-bg-elevation-2"
      >
        <div
          class="absolute top-[-100px] left-0 w-full h-[100px] pointer-events-none
            bg-gradient-to-b to-bg-elevation-2 from-transparent"
        />
        <OrderModalFooterEdit
          :order="props.order"
          :batch-items="menuItems"
          :send-to-work-in-progress="sendToWorkInProgress"
          @batch-send-to-work="onSendToWorkTap"
          @clear-tap="onClearBatchTap"
        />
      </div>
    </AppStack>
    <div class="h-[280px] shrink-0" />
  </AppStack>
</template>

<script setup lang="ts">
import { computed, ref, reactive, type PropType } from 'vue'
import { useEventBus } from '@vueuse/core'
import { alertController } from '@ionic/vue'
import { nanoid } from 'nanoid'
import { cleanCopy as HelpersObjectCleanCopy } from '@restify/packages/helpers/object'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import OrderModalSidebarHeader from './OrderModalSidebarHeader.vue'
import OrderModalMenuitemsList from './OrderModalSidebarMenuitemsList.vue'
import OrderModalSidebarEmpty from './OrderModalSidebarEmpty.vue'
import OrderModalFooterEdit from './OrderModalSidebarFooterEdit.vue'
import useStores, { type Stores } from '~/composables/useStores'
import useAppHelpers from '~/composables/useAppHelpers'

const emit = defineEmits(['end-batch-tap'])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: undefined,
  },
  injectedObjectId: {
    type: String,
    default: undefined,
  },
})

const { mapMenuItem } = useAppHelpers()
const { orders: OrdersStore } = useStores()
const bus = useEventBus<'batch-add'>('batch')
bus.on(onBatchEvent)
const batchMenuItems = reactive<
  Record<string, Stores['orders']['Result']['menuItems'][number]>
>({})
const batchId = ref(nanoid())
const sendToWorkInProgress = ref(false)

const menuItems = computed(() =>
  Object.values(batchMenuItems).map((item) => ({
    ...mapMenuItem(item),
    status: null,
    price: item.price,
    quantity: item.quantity,
    _id: item._id,
  })),
)

function onBatchEvent(
  eventName: 'batch-add',
  payload: { menuItem: Stores['orders']['Result']['menuItems'][number] },
) {
  if (eventName === 'batch-add') {
    batchMenuItems[payload.menuItem._id] = {
      ...payload.menuItem,
      batchId: batchId.value,
    }
  }
}

const onIncreaseQuantity = (index: number) => {
  const id = menuItems.value[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  item.quantity++

  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onDecreaseQuantity = (index: number) => {
  const id = menuItems.value[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  if (item.quantity === 1) {
    delete batchMenuItems[id]

    return
  }

  item.quantity--

  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onDelete = (id: string) => {
  delete batchMenuItems[id]
}

const onClearBatchTap = () => {
  Object.keys(batchMenuItems).forEach((id) => {
    delete batchMenuItems[id]
  })
}

const onComment = async (index: number, callback) => {
  const id = Object.values(batchMenuItems)[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  const alert = await alertController.create({
    header: 'Enter your comment',
    backdropDismiss: false,
    buttons: [
      {
        text: 'Cancel',
      },
      {
        text: item.comment ? 'Update' : 'Add',
        handler: (value) => {
          if (value[0] === '') {
            delete item.comment
          } else {
            item.comment = value[0]
          }

          batchMenuItems[id] = HelpersObjectCleanCopy(item)

          if (callback) callback()
        },
      },
    ],
    inputs: [
      {
        type: 'textarea',
        placeholder: 'Enter text here',
        value: item.comment,
      },
    ],
  })

  await alert.present().then(() => {
    document.querySelector('ion-alert textarea')?.focus()
  })
}

const onSendToWorkTap = () => {
  if (!Object.keys(batchMenuItems).length || !props.order?._id) return

  sendToWorkInProgress.value = true

  const update: any = {
    $push: {
      batches: {
        id: batchId.value,
        createdAt: new Date().getTime(),
      },
      menuItems: {
        $each: [],
      },
    },
  }

  Object.keys(batchMenuItems).forEach((id) => {
    // TODO: refactor to native mongo array mutation syntax
    update.$push.menuItems.$each.push({
      ...batchMenuItems[id],
      status: {
        ...batchMenuItems[id].status,
        name: 'idle',
        idleAt: new Date().getTime(),
      },
    })
  })

  return OrdersStore.patch(props.order?._id, update)
    .then(() => {
      emit('end-batch-tap')
    })
    .finally(() => {
      sendToWorkInProgress.value = false
    })
}
</script>

<style>
.comment-alert .alert-wrapper {
  top: calc(-20%);
  position: relative;
}
</style>
