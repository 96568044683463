<template>
  <ion-content>
    <AppStack
      direction="flex-col"
      justify-content="justify-between"
      class="w-full h-full min-h-full"
    >
      <AppStack
        class="w-full pt-[44px] px-7 pb-6 border-solid border-b border-t-0 border-x-0
          border-border-elevation-2-secondary justify-center"
      >
        <AppTypography
          :text="$t('order.paymentTitle')"
          name="heading-2xl-7-bold"
          color="text-fg-elevation-2-primary"
        />
      </AppStack>
      <AppStack
        class="w-full h-full"
        justify-content="justify-center"
        align-items="items-center"
      >
        <AppStack
          class="w-full h-full px-[80px] max-h-[460px] gap-10"
          flex="1"
          justify-content="justify-center"
        >
          <AppStack direction="flex-col" class="gap-6">
            <AppStack
              class="px-5 py-3 bg-bg-elevation-2-breaker gap-2 rounded-lg border-solid
                border-border-elevation-1-secondary"
              justify-content="justify-between"
              align-items="items-baseline"
            >
              <AppTypography
                :text="formatPrice(changeTotal, false)"
                name="text-lg-7-semibold"
                color="text-fg-elevation-2-secondary"
              />
              <AppTypography
                :text="formatPrice(customTotal)"
                name="heading-4xl-10-semibold"
                color="text-fg-elevation-2-primary"
              />
            </AppStack>
            <AppCheckoutNumpad @key="onNumpadKey" @delete="onNumpadDelete" />
          </AppStack>
          <AppStack direction="flex-col" class="w-full gap-5" flex="1">
            <AppStack
              class="relative overflow-hidden w-full h-full rounded-lg group border-solid
                border-[1px] border-border-elevation-3-primary gap-2"
              direction="flex-col"
              flex="1"
              justify-content="justify-center"
              align-items="items-center"
              @click="onFinalise"
            >
              <div
                class="absolute top-0 left-0 w-full h-full bg-bg-elevation-3 group-active:opacity-50
                  group-active:bg-bg-elevation-3-alt"
              />
              <AppIcon
                icon="check"
                stroke-width="1.5"
                color="text-fg-elevation-3-primary"
                class="w-10 h-10 relative"
              />
              <AppTypography
                name="text-lg-6-semibold"
                color="text-fg-elevation-3-primary"
                class="relative"
                :text="$t('order.finilise')"
              />
            </AppStack>
          </AppStack>
        </AppStack>
      </AppStack>
    </AppStack>
  </ion-content>
</template>

<script setup lang="ts">
import { computed, PropType, watch, ref, inject, ComputedRef } from 'vue'
import { IonContent } from '@ionic/vue'
import useFeathers from '~/composables/useFeathers'
import { useEventBus } from '@vueuse/core'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppCheckoutNumpad from '~/components/AppCheckoutNumpad.vue'
// import AppCheckoutReceipt from '~/components/AppCheckoutReceipt/index.vue'
import { type Stores } from '~/composables/useStores'
import useAppHelpers from '~/composables/useAppHelpers'

const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const bus = useEventBus<
  'order-modal-dismiss' | 'order-modal-update' | 'quick-order-checkout-dismiss',
  unknown
>('app')
const newTotal = ref<Array<null | number>>([null, null, null])
const isQuickOrder = inject<ComputedRef<string>>('isQuickOrder')

const { formatPrice } = useAppHelpers()
const ApiClient = useFeathers()

const customTotal = computed(() => {
  const value = newTotal.value

  return value.reduce((acc, number, index) => {
    if (number === null) {
      number = 0
    }

    if (value.length > 2 && value.length - index === 2) {
      acc += '.'
    }

    acc += number

    return acc
  }, '')
})

const changeTotal = computed(() => {
  return Number(customTotal.value) - orderTotal.value
})

const orderTotal = computed(() => {
  return typeof props?.order?.appliedPromotions?.total === 'number'
    ? props.order?.appliedPromotions?.total || 0
    : props.order?.total || 0
})

const onNumpadKey = (key: number) => {
  if (Number(customTotal.value).toFixed(2) === orderTotal.value.toFixed(2)) {
    onNumpadDelete()
  }

  const nullIndex = newTotal.value.lastIndexOf(null)

  if (nullIndex > -1) {
    newTotal.value.splice(nullIndex, 1)
    newTotal.value.push(key)

    return
  }

  newTotal.value.push(key)
}

const onNumpadDelete = () => {
  newTotal.value = [null, null, null]
}

const onFinalise = () => {
  if (!props.order) return

  return ApiClient.service('checkout')
    .create({
      action: 'checkout',
      orderId: props.order._id,
    })
    .then(() => {
      if (isQuickOrder?.value) {
        bus.emit('quick-order-checkout-dismiss')
      } else {
        bus.emit('order-modal-dismiss')
      }
    })
    .catch((error: any) => console.log(error))
}

watch(
  orderTotal,
  (newVal) => {
    newTotal.value = newVal
      .toFixed(2)
      .replace('.', '')
      .split('')
      .map((str) => parseInt(str))
  },
  { immediate: true },
)
</script>
