<template>
  <AppStack
    align-items="items-center"
    justify-content="justify-center"
    class="text-center"
  >
    <slot />
    <AppStack direction="row" align-items="items-center" class="gap-10">
      <AppStack
        v-for="user in users"
        :key="user.email"
        v-on-long-press="[
          () => onObjectLongTap(user.email),
          { delay: 500, modifiers: { stop: true } },
        ]"
        :class="[
          objectTouchStartEmail !== user.email &&
            objectTouchStartEmail !== null && ['opacity-50'],
          objectTouchStartEmail === user.email && ['user-card--selected'],
        ]"
        direction="flex-col"
        align-items="items-center"
        justify-content="justify-center"
        class="relative select-none user-card transition-opacity duration-150 ease-in-out"
        @touchstart="onObjectTouchStartTime()"
        @touchend="onObjectTouchEnd($event, user.email)"
        @contextmenu="($event: any) => $event.preventDefault()"
      >
        <AppFadeTransition>
          <AppStack
            v-if="objectTouchStartEmail === user.email"
            v-on-click-outside="onClickOutsideHandler"
            class="absolute top-[-24px] right-[-24px] w-16 h-16"
            justify-content="justify-center"
            align-items="items-center"
            @click="() => onRemoveUser(user.email)"
          >
            <AppStack
              class="bg-bg-elevation-2 p-1 rounded-full border-solid border-[2px]
                border-border-elevation-0-primary shadow-elevation-2-0-xs"
            >
              <AppIcon
                icon="cancel"
                stroke-width="1.8"
                color="text-fg-elevation-0-tertiary"
                class="w-[28px] h-[28px]"
              />
            </AppStack>
          </AppStack>
        </AppFadeTransition>
        <AppStack
          direction="col"
          align-items="items-center"
          justify-content="justify-center"
          class="flex-grow w-[190px] h-[190px] bg-bg-elevation-2 text-center rounded-[38px]
            shadow-elevation-2-0-xs"
        >
          <span class="text-[110px]">{{ user.profile.emoji }}</span>
        </AppStack>
        <AppTypography
          :text="user.profile.firstName"
          name="heading-205xl-9-semibold"
          class="mt-4"
        />
      </AppStack>
      <AppStack
        direction="flex-col"
        align-items="items-center"
        justify-content="justify-center"
        @click="emit('add-user')"
      >
        <AppStack
          direction="col"
          align-items="items-center"
          justify-content="justify-center"
          class="flex-grow w-[190px] h-[190px] border-solid border-[4px]
            border-border-elevation-0-secondary text-center rounded-[38px]"
        >
          <AppIcon
            icon="plus"
            stroke-width="1.8"
            color="text-fg-elevation-0-tertiary"
            class="w-20 h-20 opacity-50"
          />
        </AppStack>
        <AppTypography
          text="Add User"
          name="heading-205xl-9-semibold"
          color="text-fg-elevation-0-tertiary"
          class="mt-4"
        />
      </AppStack>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { PropType, onMounted, computed, ref } from 'vue'
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { vOnLongPress } from '@vueuse/components'
import { vOnClickOutside } from '@vueuse/components'

const emit = defineEmits(['add-user', 'login-user', 'remove-user'])
const props = defineProps({
  preferredUsers: {
    type: Array as PropType<Stores['users']['Result'][]>,
    default: () => [],
  },
})

const { users: UsersStore } = useStores()

const users = computed(() => {
  return props.preferredUsers.map((preferredUser) => {
    return UsersStore.getFromStore(preferredUser._id).value || preferredUser
  })
})

onMounted(() => {
  UsersStore.find({
    query: {
      _id: {
        $in: props.preferredUsers.map((user) => user._id),
      },
    },
  })
})

const onObjectLongTap = (email: string) => {
  objectTouchStartEmail.value = email
}

const onClickOutsideHandler = () => {
  objectTouchStartEmail.value = null
}

const onRemoveUser = (email: string) => {
  objectTouchStartEmail.value = null
  emit('remove-user', email)
}

const objectTouchStartTime = ref<ReturnType<typeof Date.now> | null>(null)
const objectTouchStartEmail = ref<string | null>(null)

const onObjectTouchStartTime = () => {
  objectTouchStartTime.value = Date.now()
}

const onObjectTouchEnd = ($event: PointerEvent, email: string) => {
  if (!objectTouchStartTime.value) return
  const timeDifference = Date.now() - objectTouchStartTime.value
  objectTouchStartTime.value = null

  if (timeDifference < 200 && !objectTouchStartEmail.value) {
    return emit('login-user', email)
  }
}
</script>

<style scoped>
.user-card {
  position: relative;
}

.user-card--selected {
  animation: jiggle 0.2s infinite;
}

@keyframes jiggle {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
}
</style>
