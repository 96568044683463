<template>
  <AppStack
    direction="flex-col"
    justify-content="justify-between"
    class="flex-1 overflow-hidden"
  >
    <AppStack
      class="w-full pt-[44px] px-7 pb-6 border-solid border-b border-t-0 border-x-0
        border-border-elevation-0-secondary justify-center"
    >
      <AppTypography
        :text="$t('order.transactionsTitle')"
        name="heading-2xl-7-bold"
        color="text-fg-elevation-2-primary"
      />
    </AppStack>
    <AppStack class="overflow-hidden flex-1">
      <AppFadeTransition>
        <AppStack
          v-if="loading"
          align-items="items-center"
          justify-content="justify-center"
          class="flex-1"
        >
          <AppSpinner text="Loading transactions.." size="lg" />
        </AppStack>
        <AppStack
          v-else
          class="scrollview overflow-x-auto overflow-y-hidden snap-x snap-mandatory py-20 gap-20
            flex-nowrap"
          :style="{
            paddingLeft: `calc((100% - 40vw) / 2)`,
            paddingRight: `calc((100% - 40vw) / 2)`,
          }"
        >
          <AppStack
            v-for="(transaction, index) in transactions"
            :key="index"
            direction="flex-col"
            class="rounded-[32px] bg-bg-elevation-1 shadow-2xl p-10 snap-center snap-always
              min-w-[40vw] max-w-[40vw]"
          >
            <AppStack>
              <AppStack direction="flex-col">
                <AppTypography
                  v-if="transaction?.paid && !transaction?.reversed"
                  text="Paid"
                  name="heading-lg-6-semibold"
                  color="text-[blue]"
                  class="uppercase mb-1"
                />
                <AppTypography
                  v-else-if="transaction?.paid && transaction?.reversed"
                  text="Reversed"
                  name="heading-lg-6-semibold"
                  color="text-[red]"
                  class="uppercase mb-1"
                />
                <AppTypography
                  :text="`Transaction #${index + 1}`"
                  name="heading-2xl-7-semibold"
                />
              </AppStack>
              <div class="ml-auto" :id="`click-trigger-${index}`">
                <AppIcon
                  v-if="!transaction?.reversed"
                  icon="more-horiz-circle"
                  stroke-width="1.8px"
                  class="w-8 h-8"
                  color="text-fg-elevation-2-secondary"
                  @click="
                    onTransactionActionsClick(transaction?._id || '', $event)
                  "
                />
              </div>
              <IonPopover
                v-if="!transaction?.reversed"
                :event="event"
                :is-open="transactionActionsPopoverIsOpen === transaction?._id"
                @didDismiss="transactionActionsPopoverIsOpen = ''"
              >
                <IonContent>
                  <IonList>
                    <IonItem
                      v-if="
                        ['superadmin', 'manager', 'app-owner'].includes(
                          AuthStore.reactiveUser?.permissions.type || '',
                        ) &&
                        transaction?.paid &&
                        !transaction?.reversed
                      "
                      :button="true"
                      :detail="false"
                      @click="onTransactionReverse(transaction?._id, $event)"
                    >
                      Reverse
                    </IonItem>
                    <IonItem
                      v-if="!transaction?.paid"
                      :button="true"
                      :detail="false"
                      @click="onTransactionDelete(transaction?._id, $event)"
                    >
                      Delete
                    </IonItem>
                  </IonList>
                </IonContent>
              </IonPopover>
            </AppStack>
            <AppStack class="mt-8" justify-items="justify-between">
              <AppStack direction="flex-col" class="gap-2 flex-1">
                <AppTypography
                  text="Total"
                  name="heading-lg-6-semibold"
                  class="uppercase"
                />
                <AppTypography
                  :text="String(transaction?.amount)"
                  name="heading-2xl-8-semibold"
                />
              </AppStack>
              <AppStack direction="flex-col" class="gap-2 flex-1">
                <AppTypography
                  text="Items"
                  name="heading-lg-6-semibold"
                  class="uppercase"
                />
                <AppTypography
                  :text="String(transaction.items.length)"
                  name="heading-2xl-8-semibold"
                />
              </AppStack>
            </AppStack>
            <AppStack
              v-if="
                !transaction?.paid &&
                !allTransactionsArePaid &&
                !transaction?.reversed
              "
              direction="flex-col"
              class="gap-4 mt-auto pt-8"
            >
              <AppButton
                :disabled="!transaction?.items.length"
                size="custom"
                :loading="transactionPrintingInProgress === transaction?._id"
                class="py-6 px-8 rounded-[16px] border-solid"
                @click="onTransactionPrint(transaction?._id)"
              >
                <AppIcon
                  size="custom"
                  :icon="
                    transaction?.type === 'cash' ? 'wallet' : 'credit-card'
                  "
                  class="w-9 h-9 mr-3"
                  stroke-width="1.5px"
                />
                <AppTypography
                  text="Print Check"
                  name="heading-xl-6-semibold"
                />
              </AppButton>
            </AppStack>
          </AppStack>
          <AppStack
            direction="flex-col"
            align-items="items-center"
            justify-content="justify-center"
            class="rounded-[32px] gap-8 p-10 snap-center snap-always min-w-[40vw] max-w-[40vw]
              border-border-elevation-0-primary border-solid border-2"
          >
            <AppTypography
              text="New Transaction"
              name="heading-2xl-7-semibold"
            />
            <AppFadeTransition>
              <AppStack
                v-if="loadingCreateTransaction"
                align-items="items-center"
                justify-content="justify-center"
                class="flex-1"
              >
                <AppSpinner text="Creating transaction.." size="lg" />
              </AppStack>
              <AppStack v-else direction="flex-col" class="gap-4 flex-1 w-full">
                <AppButton
                  :disabled="!possibleToCreateTransaction"
                  size="custom"
                  class="py-6 px-8 rounded-[16px] border-solid flex-1"
                  variant="fill-white"
                  @click="createTransaction('cash')"
                >
                  <AppIcon
                    size="custom"
                    icon="wallet"
                    class="w-9 h-9 mr-3"
                    stroke-width="1.5px"
                  />
                  <AppTypography text="Add Cash" name="heading-xl-6-semibold" />
                </AppButton>
                <AppButton
                  :disabled="!possibleToCreateTransaction"
                  size="custom"
                  class="py-6 px-8 rounded-[16px] flex-1"
                  @click="createTransaction('card')"
                >
                  <AppIcon
                    size="custom"
                    icon="credit-card"
                    class="w-9 h-9 mr-3"
                    stroke-width="1.5px"
                  />
                  <AppTypography text="Add Card" name="heading-xl-6-semibold" />
                </AppButton>
              </AppStack>
            </AppFadeTransition>
          </AppStack>
        </AppStack>
      </AppFadeTransition>
    </AppStack>
    <AppStack
      v-if="
        allTransactionsArePaid && props.order?.status?.name !== 'checked-out'
      "
      class="w-full px-7 safe-bottom-max"
      flex="1"
      justify-content="justify-center"
      align-items="items-center"
    >
      <button
        class="py-[18px] relative rounded-lg overflow-hidden flex-1"
        @click="emit('finalise-tap')"
      >
        <div
          class="absolute top-0 left-0 w-full h-full bg-bg-elevation-0-alt active:brightness-90"
        />
        <AppTypography
          color="text-fg-elevation-3-primary"
          text="Finalise"
          name="text-lg-7-semibold"
          class="relative"
        />
      </button>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { type PropType, onMounted, ref, computed } from 'vue'
import {
  toastController,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
} from '@ionic/vue'
import Bugsnag from '@bugsnag/js'
import useFeathers from '~/composables/useFeathers'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppButton from '@restify/packages/design-system/low-level/AppButton.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'
import { roundValue } from '@restify/packages/helpers/number'
import useStores, { type Stores } from '~/composables/useStores'
import {
  useFiscalStore,
  getReceiptTotal,
  getReceiptPaymentsTotal,
} from '~/stores/fiscal'
import { useAuthStore } from '~/stores/auth'
import AppSpinner from '@restify/packages/design-system/low-level/AppSpinner.vue'

const ApiClient = useFeathers()
const AuthStore = useAuthStore()

const { transactions: TransactionsStore } = useStores()
const FiscalStore = useFiscalStore()

const emit = defineEmits(['back-tap', 'finalise-tap'])

const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const event = ref(null)
const loading = ref(true)
const loadingCreateTransaction = ref(false)
const transactionActionsPopoverIsOpen = ref<string>('')
const transactionPrintingInProgress = ref<string>('')

const onTransactionActionsClick = (id, $event) => {
  event.value = $event
  transactionActionsPopoverIsOpen.value = id
}

const transactions = computed(() => {
  if (!props.order?._id) return []

  return TransactionsStore.findInStore({
    orderId: props.order._id,
    $sort: {
      createdAt: 1,
    },
  })
    .filter((item) => item.value)
    .map((item) => item.value)
})

const saleItems = computed(() => {
  const paidTransactionItems = transactions.value
    .filter((transaction) => {
      return transaction && !transaction?.reversed && transaction.paid
    })
    .reduce((acc: any, menuItem) => {
      if (!menuItem?._id) return acc

      menuItem.items.forEach((item) => {
        acc[item.id] = item
      })

      return acc
    }, {})

  return props.order?.menuItems.reduce((acc: any, menuItem) => {
    if (paidTransactionItems[menuItem._id]) return acc

    if (props.order?.appliedPromotions?.menuItems?.[menuItem._id]) {
      acc[menuItem._id] = {
        ...props.order?.appliedPromotions.menuItems[menuItem._id],
      }

      return acc
    } else {
      acc[menuItem._id] = {
        id: menuItem._id,
        menuItemId: menuItem.menuItemId,
        price: menuItem.price,
        quantity: menuItem.quantity,
        amountBeforeDiscount: menuItem.price,
        amountAfterDiscount: menuItem.price,
        discount: 0,
      }

      return acc
    }
  }, {})
})

const paidTransactionsTotal = computed(() => {
  return transactions.value
    .filter((transaction) => {
      return transaction && !transaction?.reversed && transaction.paid
    })
    .reduce((acc, transaction) => {
      return roundValue(acc + (transaction?.amount || 0), 2)
    }, 0)
})

const inProgressTransactionsTotal = computed(() => {
  return transactions.value
    .filter((transaction) => {
      return transaction && !transaction?.reversed
    })
    .reduce((acc, transaction) => {
      return roundValue(acc + (transaction?.amount || 0), 2)
    }, 0)
})

const orderTotal = computed(() => {
  return (
    (typeof props.order?.appliedPromotions?.total === 'number'
      ? props.order.appliedPromotions?.total
      : props.order?.total) || 0
  )
})

const allTransactionsArePaid = computed(() => {
  return (
    paidTransactionsTotal.value >= orderTotal.value &&
    transactions.value.every((transaction) => transaction.paid)
  )
})

const possibleToCreateTransaction = computed(() => {
  return (
    inProgressTransactionsTotal.value < orderTotal.value ||
    orderTotal.value === 0
  )
})

const onTransactionDelete = (id: string) => {
  if (!props.order?._id || !id) return

  transactionActionsPopoverIsOpen.value = ''

  return ApiClient.service('checkout').create({
    action: 'remove-transaction',
    orderId: props.order?._id,
    transactionId: id,
  })
}

const onTransactionReverse = (id: string) => {
  if (!props.order?._id || !id) return

  if (
    !FiscalStore.isFiscalDevMode &&
    (!FiscalStore.printerId || !FiscalStore.printerStatus.ok)
  ) {
    return displayError('Printer is not configured or connected')
  }

  transactionPrintingInProgress.value = id

  return ApiClient.service('checkout')
    .create({
      action: 'reversal-receipt',
      orderId: props.order?._id,
      printerId: FiscalStore.printerId,
      transactionId: id,
    })
    .then((result) => {
      return printReversalReceipt(result.printObject).then(
        (printingResult) => ({
          printingResult,
          result,
        }),
      )
    })
    .then(({ printingResult, result }) => {
      if (!props.order?._id || !printingResult) return

      console.log({ printingResult, result })

      const { transaction } = result

      return ApiClient.service('checkout').patch(null, {
        orderId: props.order._id,
        action: 'reversal-receipt',
        transactionId: String(transaction._id),
        fiscalPayload: printingResult,
      })
    })
    .finally(() => {
      transactionPrintingInProgress.value = ''
    })
}

const onTransactionPrint = async (id?: string) => {
  if (!props.order?._id || !id) return

  if (
    !FiscalStore.isFiscalDevMode &&
    (!FiscalStore.printerId || !FiscalStore.printerStatus.ok)
  ) {
    return displayError('Printer is not configured or connected')
  }

  transactionPrintingInProgress.value = id

  return ApiClient.service('checkout')
    .create({
      action: 'receipt',
      orderId: props.order?._id,
      printerId: FiscalStore.printerId,
      transactionId: id,
    })
    .then((result) => {
      return printReceipt(result.printObject).then((printingResult) => ({
        printingResult,
        result,
      }))
    })
    .then(({ printingResult, result }) => {
      if (!props.order?._id || !printingResult) return

      console.log({ printingResult, result })

      const { transaction } = result

      return ApiClient.service('checkout').patch(null, {
        orderId: props.order._id,
        action: 'receipt',
        transactionId: String(transaction._id),
        fiscalPayload: printingResult,
      })
    })
    .finally(() => {
      transactionPrintingInProgress.value = ''
    })
}

const printReceipt = async (printObject: any) => {
  const receiptTotal = getReceiptTotal(printObject)
  const receiptPaymentsTotal = getReceiptPaymentsTotal(printObject)

  if (
    receiptTotal <= 0 ||
    receiptPaymentsTotal <= 0 ||
    FiscalStore.isFiscalDevMode
  ) {
    // TODO: skip printing, zero total
    return true
  }

  const result = await FiscalStore.printReceipt(printObject).catch((error) => {
    Bugsnag.notify(error)

    displayError(JSON.stringify(error))

    return {
      ok: false,
      messages: [
        {
          type: 'error',
          text: JSON.stringify(error),
        },
      ],
    }
  })

  if (result.ok) {
    return result
  } else {
    Bugsnag.notify(
      new Error('Error response from printer: ' + JSON.stringify(result)),
    )

    displayError('Error response from printer: ' + JSON.stringify(result))

    return
  }
}

const printReversalReceipt = async (printObject: any) => {
  const receiptTotal = getReceiptTotal(printObject)
  const receiptPaymentsTotal = getReceiptPaymentsTotal(printObject)

  if (
    receiptTotal <= 0 ||
    receiptPaymentsTotal <= 0 ||
    FiscalStore.isFiscalDevMode
  ) {
    // TODO: skip printing, zero total
    return true
  }

  const result = await FiscalStore.printReversalReceipt(printObject).catch(
    (error) => {
      Bugsnag.notify(error)

      displayError(JSON.stringify(error))

      return {
        ok: false,
        messages: [
          {
            type: 'error',
            text: JSON.stringify(error),
          },
        ],
      }
    },
  )

  if (result.ok) {
    return result
  } else {
    Bugsnag.notify(
      new Error('Error response from printer: ' + JSON.stringify(result)),
    )

    displayError('Error response from printer: ' + JSON.stringify(result))

    return
  }
}

const init = async () => {
  if (!props.order?._id) return

  loading.value = true

  await FiscalStore.updatePrinterStatus()

  return TransactionsStore.find({
    query: {
      orderId: props.order._id,
    },
  }).finally(() => {
    loading.value = false
  })
}

const createTransaction = async (type: 'cash' | 'card') => {
  if (!props.order?._id) return

  if (!FiscalStore.printerId || !FiscalStore.printerStatus.ok) {
    return displayError('Printer is not configured or connected')
  }

  loadingCreateTransaction.value = true

  return ApiClient.service('checkout')
    .create({
      orderId: props.order._id,
      action: 'create-transaction',
      printerId: FiscalStore.printerId,
      type,
      saleItems: Object.keys(saleItems.value),
    })
    .finally(() => {
      loadingCreateTransaction.value = false
    })
}

const displayError = async (message: string) => {
  const toast = await toastController.create({
    message,
    duration: 3000,
    cssClass: 'toast text-lg-7-semibold',
    position: 'bottom',
    mode: 'ios',
  })

  toast.present()
}

onMounted(() => {
  init()
})
</script>

<style scoped>
.scrollview {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollview::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
