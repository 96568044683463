<template>
  <ion-modal
    ref="modal"
    :is-open="props.isOpen"
    :presenting-element="props.presentingElement"
    :enter-animation="iosEnterAnimation"
    :leave-animation="iosLeaveAnimation"
    :style="{
      '--backdrop-opacity': 0.9,
      '--min-height': '100%',
      '--min-width': '100%',
      '--border-radius': '16px 16px 0px 0px',
      '--background': 'var(--rf-bg-elevation-2)',
      'margin-top': '44px',
    }"
    can-dismiss
    @didDismiss="emit('dismiss', false)"
    @willPresent="onModalWillPresent"
    @willDismiss="onModalWillDismiss"
  >
    <ion-nav id="promotions-modal-nav" ref="navRef" :root="PromotionsModal" />
  </ion-modal>
</template>

<script setup lang="ts">
import { IonModal, IonNav } from '@ionic/vue'
import { PropType, provide, computed, ref, watch } from 'vue'
import { useEventBus } from '@vueuse/core'
import PromotionsModal from './PromotionsModal/index.vue'
import {
  useEnterAnimation,
  useLeaveAnimation,
} from '~/composables/useModalAnimations'

const emit = defineEmits(['dismiss', 'update'])
const props = defineProps({
  presentingElement: {
    type: Object as PropType<HTMLElement | null>,
    default: null,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  orderId: {
    type: String as PropType<string | null>,
    default: null,
  },
  objectId: {
    type: String as PropType<string | null>,
    default: null,
  },
})

provide(
  'isOpen',
  computed(() => props.isOpen),
)
provide(
  'orderId',
  computed(() => props.orderId),
)
provide(
  'objectId',
  computed(() => props.objectId),
)
provide(
  'navRef',
  computed(() => navRef?.value?.$el || null),
)

const { iosEnterAnimation } = useEnterAnimation()
const { iosLeaveAnimation } = useLeaveAnimation()
const canDismiss = ref(false)

const navRef = ref<InstanceType<typeof IonNav> | null>(null)
const bus = useEventBus<'promotions-modal-dismiss', unknown>('app')
bus.on(onAppEvent)

function onAppEvent(event: 'promotions-modal-dismiss', payload: unknown) {
  if (event === 'promotions-modal-dismiss') {
    return emit('dismiss', false)
  }
}

const onModalWillPresent = ($event: unknown) => {
  $event.target.presentingElement.classList.add('pushed-back')
}

const onModalWillDismiss = ($event: unknown) => {
  $event.target.presentingElement.classList.remove('pushed-back')
}

watch(
  computed(() => props.isOpen),
  (newVal) => {
    const value = newVal
    canDismiss.value = !value

    setTimeout(() => {
      canDismiss.value = value
    }, 500)
  },
)
</script>
