<template>
  <PromotionsModalContentDefault
    v-if="navigationRoute === 'default'"
    :order="props.order"
    @search-tap="() => setNavigation('search')"
  />
  <PromotionsModalContentSearch
    v-else-if="navigationRoute === 'search'"
    :order="props.order"
    @cancel-tap="() => setNavigation('default')"
  />
</template>

<script lang="ts">
export default { name: 'PromotionsModalContent' }
</script>

<script setup lang="ts">
import { ref, PropType } from 'vue'
import PromotionsModalContentDefault from './PromotionsModalContentDefault.vue'
import PromotionsModalContentSearch from './PromotionsModalContentSearch.vue'
import { type Stores } from '~/composables/useStores'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

type PromotionModalNaviagtionRoute = 'default' | 'search'
const navigationRoute = ref('default' as PromotionModalNaviagtionRoute)
const setNavigation = (route: PromotionModalNaviagtionRoute) => {
  navigationRoute.value = route
}
</script>
