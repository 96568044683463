import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IndexPage.vue'),
  },
  {
    path: '/kitchen',
    name: 'kitchen',
    component: () => import('../views/KitchenPage.vue'),
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import('../views/Stock/StockPage.vue'),
  },
  {
    path: '/quick-order',
    name: 'quick-order',
    component: () => import('../views/QuickOrderPage/index.vue'),
  },
  {
    path: '/in-house',
    name: 'in-house',
    component: () => import('../views/InHousePage.vue'),
  },
  {
    path: '/pickup',
    name: 'pickup',
    component: () => import('../views/Pickup/PickupPage.vue'),
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('../views/DeliveryPage.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings/SettingsPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
})

export default router
