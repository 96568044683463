<template>
  <AppStack class="grid grid-cols-4 gap-2 px-7 py-5 w-full">
    <AppStack
      v-for="option in props.options"
      :key="option.value.toString()"
      direction="flex-col"
      class="relative rounded-md bg-bg-elevation-2 shadow-elevation-1-0-xs group
        overflow-hidden p-4 border-solid border-[3px]"
      :class="
        option.value === props.modelValue
          ? 'border-border-blue'
          : 'border-bg-elevation-2'
      "
      justify-content="justify-center"
      align-items="items-center"
      @click="onPickerUpdate(option)"
    >
      <AppTypography
        :text="option.label"
        color="text-fg-elevation-2-primary"
        name="text-lg-6-semibold"
        class="relative text-center line-clamp-2"
      />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { ref, watch, nextTick } from 'vue'
import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { PropType, computed } from 'vue'

import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

type Option = {
  label: string
  value: string | number | boolean
}

const emit = defineEmits(['update:model-value'])

const props = defineProps({
  options: {
    type: Array as PropType<Option[]>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Boolean],
    required: true,
  },
  bgColor: {
    type: String,
    required: true,
  },
  segmentColor: {
    type: String,
    required: true,
  },
  textColor: {
    type: String,
    required: true,
  },
  segmentShadow: {
    type: String,
    required: true,
  },
  radius: {
    type: String as PropType<'md' | 'full'>,
    required: true,
  },
  size: {
    type: String as PropType<'xs' | 'sm' | 'lg'>,
    required: true,
  },
})

const rendered = ref(false)
const largestTextWidth = ref(100)
const optionRefs = ref([])

const onPickerUpdate = (option: Option) => {
  if (Capacitor.isNativePlatform()) {
    Haptics.impact({ style: ImpactStyle.Light })
  }

  emit('update:model-value', option.value)
}

const measureLargestText = () => {
  return optionRefs.value.reduce((acc, ref) => {
    const width = ref.$el.clientWidth

    acc = width > acc ? width : acc

    return acc
  }, 0)
}

watch(
  computed(() => JSON.stringify(props.options)),
  () => {
    rendered.value = false

    nextTick(() => {
      largestTextWidth.value = measureLargestText()
      rendered.value = true
    })
  },
  { immediate: true },
)
</script>
