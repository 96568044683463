<template>
  <AppStack tag="nav" class="w-full">
    <AppTypography
      v-for="(tab, tabIdx) in props.tabs"
      :key="tab.name"
      :class="[
        props.activeTab === tab.value
          ? 'bg-bg-elevation-0-alt'
          : 'bg-transparent',
      ]"
      :size="
        {
          lg: 'text-lg-6-semibold',
          xl:
            props.activeTab === tab.value
              ? 'heading-xl-6-bold'
              : 'heading-xl-6-semibold',
        }[props.size]
      "
      :color="
        props.activeTab === tab.value
          ? 'text-fg-elevation-0-primary'
          : 'text-fg-elevation-0-secondary'
      "
      class="flex max-w-fit group relative flex-1 overflow-hidden py-2 text-center px-4
        cursor-pointer transition-colors focus:z-10 rounded-full"
      @click="emit('update:active-tab', tab.value || tabIdx)"
    >
      {{ tab.name }}
    </AppTypography>
  </AppStack>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'

const emit = defineEmits(['update:active-tab'])
const props = defineProps({
  activeTab: {
    type: String,
    default: null,
  },
  tabs: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  size: {
    type: String as PropType<'lg' | 'xl'>,
    required: true,
  },
})
</script>
