<template>
  <ion-page>
    <div class="z-10 pt-3 pb-3 absolute top-0 left-0 w-full">
      <div
        class="w-[60px] h-[6px] rounded-sm bg-border-elevation-3-secondary mx-auto"
      />
    </div>
    <AppStack flex="flex-1">
      <OrderModalSidebar
        :loading="loading"
        :order="order"
        v-model:active-screen="activeScreen"
        v-model:currently-previewed-order-id="currentlyPreviewedOrderId"
      />
      <OrderModalContent
        :loading="loading"
        :order="order"
        v-model:active-screen="activeScreen"
        :class="
          ['finalise'].includes(activeScreen)
            ? ['bg-bg-elevation-2']
            : ['bg-bg-elevation-2-breaker']
        "
      />
    </AppStack>
  </ion-page>
</template>

<script lang="ts">
export default { name: 'OrderModal' }
</script>

<script setup lang="ts">
import { IonPage } from '@ionic/vue'
import { ref, computed, watch, inject, ComputedRef } from 'vue'
import { useEventBus } from '@vueuse/core'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import { getUniqueValues as HelpersArrayGetUniqueValues } from '@restify/packages/helpers/array'
import useStores from '~/composables/useStores'
import OrderModalSidebar from '~/views/OrderModal/OrderModalSidebar/index.vue'
import OrderModalContent from '~/views/OrderModal/OrderModalContent/index.vue'

const bus = useEventBus<
  'order-modal-dismiss' | 'order-modal-update' | 'app-visible',
  unknown
>('app')

const {
  orders: OrdersStore,
  users: UsersStore,
  promotions: PromotionsStore,
  storefrontPlaces: StorefrontPlacesStore,
} = useStores()

const injectedIsOpen = inject<ComputedRef<boolean>>('isOpen')
const injectedOrderId = inject<ComputedRef<string>>('orderId')
const injectedObjectId = inject<ComputedRef<string> | null>('objectId', null)

const loading = ref(false)

const activeScreen = ref<'default' | 'edit' | 'transactions' | 'finalise'>(
  'default',
)

const ordersToView = computed(() => {
  return injectedObjectId?.value
    ? OrdersStore.findInStore({
        'status.name': {
          $in: ['created', 'checking-out'],
        },
        storefrontPlaceIds: injectedObjectId.value,
        $sort: {
          createdAt: 1,
        },
      }).map((item) => item.value)
    : OrdersStore.findInStore({
        _id: injectedOrderId?.value,
        $sort: {
          createdAt: 1,
        },
      }).map((item) => item.value)
})

const currentlyPreviewedOrderId = ref(ordersToView.value[0]?._id)

const order = computed(() => {
  if (loading.value) return null

  return OrdersStore.getFromStore(currentlyPreviewedOrderId.value).value
})

const fetchData = () => {
  const promises = []

  // Promotions ids and Applied promotions
  const nonLoadedPromotions =
    ordersToView.value
      .reduce((acc: string[], order) => {
        acc.push(...(order?.promotionIds || []))

        return acc
      }, [])
      .filter((id) => !PromotionsStore.getFromStore(id).value) || []
  const nonLoadedAppliedPromotions = ordersToView.value
    .reduce((acc: string[], order) => {
      acc.push(...Object.keys(order?.appliedPromotions || {}))

      return acc
    }, [])
    .filter((id) => !PromotionsStore.getFromStore(id).value)

  if (nonLoadedPromotions.length || nonLoadedAppliedPromotions.length) {
    const promotionIdsToFetch = [
      ...nonLoadedPromotions,
      ...nonLoadedAppliedPromotions,
    ]

    if (promotionIdsToFetch.length) {
      promises.push(
        PromotionsStore.find({
          query: {
            _id: { $in: HelpersArrayGetUniqueValues(promotionIdsToFetch) },
          },
        }),
      )
    }
  }

  // Staff users
  const nonLoadedStaff =
    ordersToView.value
      .reduce((acc: string[], order) => {
        acc.push(...(order?.staff.map((staff) => staff.staffId) || []))

        return acc
      }, [])
      .filter((id) => !UsersStore.getFromStore(id).value) || []

  if (nonLoadedStaff.length) {
    promises.push(
      UsersStore.find({
        query: { _id: { $in: nonLoadedStaff } },
      }),
    )
  }

  // Storefront places
  const nonLoadedStorefrontPlaces =
    ordersToView.value
      .reduce((acc: string[], order) => {
        acc.push(...(order?.storefrontPlaceIds || []))

        return acc
      }, [])
      .filter((id) => !StorefrontPlacesStore.getFromStore(id).value) || []

  if (nonLoadedStorefrontPlaces.length) {
    promises.push(
      StorefrontPlacesStore.find({
        query: { _id: { $in: nonLoadedStorefrontPlaces } },
      }),
    )
  }

  if (!promises.length) return

  loading.value = true

  return Promise.all(promises).finally(() => {
    loading.value = false
  })
}

watch(
  computed(() => injectedIsOpen?.value),
  (newVal) => newVal && fetchData(),
  { immediate: true },
)

watch(
  ordersToView,
  (newVal) => {
    const id = newVal[0]?._id

    if (id && !currentlyPreviewedOrderId.value) {
      currentlyPreviewedOrderId.value = id
    }
  },
  { immediate: true },
)

watch(
  currentlyPreviewedOrderId,
  (newVal) => {
    bus.emit('order-modal-update', {
      orderId: newVal,
    })
  },
  { immediate: true },
)
</script>
