<template>
  <ion-toolbar :style="sidebarToolbarStyles" class="pt-[30px]">
    <template v-if="props.isCheckout">
      <AppTypography
        :text="`${headerTitle} / `"
        name="heading-2xl-7-semibold"
        color="text-fg-blue"
        @click="emit('back-tap')"
      />
      <AppTypography
        :text="$t('order.checkout')"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-primary"
      />
      <ion-buttons slot="end" />
    </template>
    <template v-else-if="props.isFinalise">
      <AppTypography
        :text="`${$t('order.checkout')} / `"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-primary"
      />
      <AppTypography
        :text="$t('order.finilise')"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-primary"
      />
      <ion-buttons slot="end" />
    </template>
    <template v-else-if="props.isNewBatch">
      <AppTypography
        :text="`${headerTitle} / `"
        name="heading-2xl-7-semibold"
        color="text-fg-blue"
        @click="emit('end-batch-tap')"
      />
      <AppTypography
        :text="$t('order.newOrder')"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-primary"
      />
      <ion-buttons slot="end" />
    </template>
    <template v-else>
      <AppTypography
        :text="headerTitle"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-primary"
      />
      <AppTypography
        v-if="headerSubTitle"
        :text="headerSubTitle"
        name="heading-2xl-7-semibold"
        color="text-fg-elevation-2-secondary"
        class="ml-2"
      />
      <ion-buttons slot="end">
        <ion-button
          v-if="props.order"
          :disabled="props.order.status.name !== 'created'"
          @click="bus.emit('promotions-tap', props.order)"
        >
          <AppTypography
            :text="$t('order.promotions')"
            name="text-lg-7-medium"
            color="text-fg-blue"
          />
        </ion-button>
      </ion-buttons>
    </template>
  </ion-toolbar>
</template>

<script setup lang="ts">
import { IonButtons, IonButton, IonToolbar } from '@ionic/vue'
import { useEventBus } from '@vueuse/core'
import { computed, type PropType } from 'vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['end-batch-tap', 'back-tap'])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: undefined,
  },
  injectedObjectId: {
    type: String,
    default: undefined,
  },
  isNewBatch: {
    type: Boolean,
    default: false,
  },
  isCheckout: {
    type: Boolean,
    default: false,
  },
  isFinalise: {
    type: Boolean,
    default: false,
  },
})

const bus = useEventBus<string>('app')

const sidebarToolbarStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
  'border-bottom': '1px solid var(--rf-border-elevation-2-secondary)',
  '--padding-top': '10px',
  '--padding-bottom': '18px',
  'padding-left': '24px',
  'padding-right': '17px',
}))

const {
  orders: OrdersStore,
  storefrontPlaces: StorefrontPlacesStore,
  users: UsersStore,
} = useStores()

const storefrontPlace = computed(() => {
  if (!props.injectedObjectId) return null

  return StorefrontPlacesStore.getFromStore(props.injectedObjectId).value
})

const staff = computed(() => {
  if (!Array.isArray(props.order?.staff)) return null

  const waiter = props.order?.staff.find((staff) => staff.role === 'waiter')

  return waiter ? UsersStore.getFromStore(waiter.staffId).value : null
})

const { t } = useI18n()
const headerTitle = computed(() => {
  if (props.injectedObjectId) {
    return storefrontPlace.value
      ? `${t('order.table')} ${storefrontPlace.value?.config.name}`
      : t('order.tableUnknown')
  }

  if (props.order?.type === 'delivery') {
    return t('order.delivery')
  }

  if (props.order?.type === 'pickup') {
    return t('order.pickup')
  }

  return props.order
    ? `${t('order.order')} #${props.order.publicId}`
    : t('order.orderUnknown')
})

const headerSubTitle = computed(() => {
  if (!props.order?._id) false

  if (props.order?.type === 'delivery') {
    return `#${props.order.publicId}`
  }

  if (props.order?.type === 'pickup') {
    return `#${props.order.publicId}`
  }

  return staff.value?.profile.firstName || null
})
</script>
