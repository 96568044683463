// Pinia Store
import ky from 'ky'
import { defineStore } from 'pinia'
import { roundValue } from '@restify/packages/helpers/round'

export type PrintFiscalReceiptPayloadItem = {
  type?:
    | 'sale'
    | 'discount-amount'
    | 'surcharge-amount'
    | 'comment'
    | 'footer-comment' // 'sale' is default value
  text?: string // the name of the product
  quantity?: number // (optional) the quantity sold. When not specified, some fiscal printers might be able to omit the quantity completely, while others might print "1". For calculation purposes, omitting quantity means 1.
  unitPrice?: number // the unit price, not including any discounts/markups
  taxGroup?: number // the government regulated tax group. An integer from 1 to 8.
  department?: number // (optional) the department number. A positive integer.
  priceModifierValue?: number // (optional) modifies the total amount of the line according to the setting of "priceModifierType"
  priceModifierType?:
    | 'discount-percent'
    | 'discount-amount'
    | 'surcharge-percent'
    | 'surcharge-amount' // The item with type "discount-amount" and "surcharge-amount" can have the following fields set:
  amount?: number // the amount that will be substracted or added to the subtotal Warning: Check the value of "supportsSubTotalAmountModifiers" in your device info, to check whether your device supports subtotal modifiers by amount.
  // The item with type "comment" and "footer-comment" can have the following fields set:
  // "text" - the text of the comment
}
type PrintFiscalReceiptPayloadPayment = {
  amount: number
  paymentType:
    | 'cash'
    | 'check'
    | 'card'
    | 'coupons'
    | 'ext-coupons'
    | 'packaging'
    | 'internal-usage'
    | 'damage'
    | 'bank'
    | 'reserved1'
    | 'reserved2'
}
export type PrintFiscalReceiptPayload = {
  uniqueSaleNumber: string
  operator?: string
  operatorPassword?: string
  items: PrintFiscalReceiptPayloadItem[]
  payments: PrintFiscalReceiptPayloadPayment[]
}
export type PrintFiscalReversalReceiptPayload = {
  uniqueSaleNumber: string
  operator?: string
  operatorPassword?: string
  receiptNumber: number
  receiptDateTime: string
  fiscalMemorySerialNumber: string
  items: PrintFiscalReceiptPayloadItem[]
  payments: PrintFiscalReceiptPayloadPayment[]
  reason: 'operator-error'
}
type PrinterResultMessage = {
  type: string
  text: string
  code?: string // error code
}
export type PrintResult = {
  ok: boolean
  messages: PrinterResultMessage[]
  receiptNumber?: string
  receiptDateTime?: string
  receiptAmount?: number
  fiscalMemorySerialNumber?: string
  deviceDateTime?: string
}

const ErrorMessages = {
  printerId: {
    type: 'error',
    text: 'The fiscal printer is not setup in the settings',
  } as PrinterResultMessage,
  fiscalApiBase: {
    type: 'error',
    text: 'Fiscal API Server is not setup in the settings',
  } as PrinterResultMessage,
}

const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const createKyApi = (fiscalApiBase?: string | null) =>
  fiscalApiBase ? ky.create({ prefixUrl: fiscalApiBase, retry: 0 }) : null

export const getReceiptTotal = (printObject: PrintFiscalReceiptPayload) => {
  if (!printObject?.items?.length) return 0

  const total = printObject.items
    .filter((item) => item.type === 'sale')
    .reduce((acc, item) => {
      return roundValue(
        acc + roundValue((item.unitPrice || 0) * (item.quantity || 1)),
      )
    }, 0)

  return roundValue(total)
}

export const getReceiptPaymentsTotal = (
  printObject: PrintFiscalReceiptPayload,
) => {
  if (!printObject?.payments?.length) return 0

  const paymentsTotal = printObject.payments.reduce((acc, item) => {
    return roundValue(acc + (item.amount || 0))
  }, 0)

  return roundValue(paymentsTotal)
}

interface State {
  isFiscalDevMode: boolean
  isFiscalReceiptPreview: boolean
  fiscalApiBase: null | string
  printerId: null | string
  printerStatus: {
    ok: boolean
    status: 'idle' | 'ok' | 'not-set-up' | 'error' | 'loading'
    messages: PrinterResultMessage[]
  }
  api: typeof ky | null
}

const isFiscalDevMode = localStorage.getItem('isFiscalDevMode') === 'true'
const isFiscalReceiptPreview =
  localStorage.getItem('isFiscalReceiptPreview') === 'true'

export const useFiscalStore = defineStore('fiscal', {
  state: (): State => ({
    isFiscalDevMode: isFiscalDevMode,
    isFiscalReceiptPreview: isFiscalReceiptPreview,
    fiscalApiBase: window.localStorage.getItem('fiscalApiBase') || null,
    printerId: window.localStorage.getItem('printerId') || null,
    printerStatus: {
      ok: false,
      status: 'idle',
      messages: [],
    },
    api: createKyApi(window.localStorage.getItem('fiscalApiBase')),
  }),
  getters: {},
  actions: {
    setFiscalApiBase(fiscalApiBase = '') {
      window.localStorage.setItem('fiscalApiBase', fiscalApiBase)
      this.$state.fiscalApiBase = fiscalApiBase
      this.$state.api = createKyApi(fiscalApiBase)
    },

    setPrinterId(printerId: string | null) {
      if (printerId) {
        window.localStorage.setItem('printerId', printerId)
        this.$state.printerId = printerId
      }
    },

    async getPrintersList() {
      if (!this.$state.api) {
        console.error(ErrorMessages.fiscalApiBase.text)
        return []
      }
      try {
        const result = await this.$state.api.get('printers').json()
        return result
      } catch (error) {
        console.error('getPrintersList ~ error:', error)
        return []
      }
    },

    async updatePrinterStatus() {
      if (!this.$state.api) {
        console.error(ErrorMessages.fiscalApiBase.text)

        this.$state.printerStatus = {
          ok: false,
          status: 'not-set-up',
          messages: [ErrorMessages.fiscalApiBase],
        }

        return
      }

      if (!this.$state.printerId) {
        console.error(ErrorMessages.printerId.text)

        this.$state.printerStatus = {
          ok: false,
          status: 'not-set-up',
          messages: [ErrorMessages.printerId],
        }

        return
      }

      this.$state.printerStatus.status = 'loading'

      try {
        const printerStatus: any = await this.$state.api
          .get(`printers/${this.$state.printerId}/status`)
          .json()
        this.$state.printerStatus = {
          ...printerStatus,
          status: printerStatus?.ok ? 'ok' : 'error',
        }
      } catch (error: any) {
        console.error('updatePrinterStatus ~ error:', error)

        this.$state.printerStatus = {
          ok: false,
          messages: [
            {
              type: 'error',
              text: error?.name,
            },
          ],
          status: 'error',
        }
      }
    },

    async printDuplicate() {
      if (!this.$state.api) {
        console.error(ErrorMessages.fiscalApiBase.text)

        return
      }

      if (!this.$state.printerId) {
        console.error(ErrorMessages.printerId.text)

        return
      }

      if (this.$state.isFiscalDevMode) {
        console.log('DEV_MODE printing duplicate...')
        return
      } else {
        return this.$state.api
          .post(`printers/${this.$state.printerId}/duplicate`)
          .json()
      }
    },

    async printXReport() {
      if (!this.$state.api) {
        console.error(ErrorMessages.fiscalApiBase.text)

        return
      }
      if (!this.$state.printerId) {
        console.error(ErrorMessages.printerId.text)

        return
      }
      if (this.$state.isFiscalDevMode) {
        console.log('DEV_MODE printing X Report...')
        return
      } else {
        return this.$state.api
          .post(`printers/${this.$state.printerId}/xreport`)
          .json()
      }
    },

    async printZReport() {
      if (!this.$state.api) {
        console.error(ErrorMessages.fiscalApiBase.text)

        return
      }
      if (!this.$state.printerId) {
        console.error(ErrorMessages.printerId.text)

        return
      }
      if (this.$state.isFiscalDevMode) {
        console.log('DEV_MODE printing Z Report...')
      } else {
        return this.$state.api
          .post(`printers/${this.$state.printerId}/zreport`)
          .json()
      }
    },

    async printReceipt(
      printObject: PrintFiscalReceiptPayload,
    ): Promise<PrintResult> {
      if (!this.$state.api) {
        console.error(ErrorMessages.fiscalApiBase.text)

        return {
          ok: false,
          messages: [ErrorMessages.fiscalApiBase],
        }
      }

      if (!this.$state.printerId) {
        console.error(ErrorMessages.printerId.text)

        return {
          ok: false,
          messages: [ErrorMessages.printerId],
        }
      }

      if (this.$state.isFiscalDevMode) {
        console.log('DEV_MODE printing Receipt...')

        // eslint-disable-next-line no-debugger
        // debugger

        await delay(1000)

        return goodResult
      } else {
        return this.$state.api
          .post(`printers/${this.$state.printerId}/receipt`, {
            json: printObject,
          })
          .json()
      }
    },

    async printReversalReceipt(
      printObject: PrintFiscalReversalReceiptPayload,
    ): Promise<PrintResult> {
      if (!this.$state.api) {
        console.error(ErrorMessages.fiscalApiBase.text)

        return {
          ok: false,
          messages: [ErrorMessages.fiscalApiBase],
        }
      }

      if (!this.$state.printerId) {
        console.error(ErrorMessages.printerId.text)

        return {
          ok: false,
          messages: [ErrorMessages.printerId],
        }
      }

      if (this.$state.isFiscalDevMode) {
        console.log('DEV_MODE printing Receipt...')

        // eslint-disable-next-line no-debugger
        // debugger

        await delay(1000)

        return goodResult
      } else {
        return this.$state.api
          .post(`printers/${this.$state.printerId}/reversalreceipt`, {
            json: printObject,
          })
          .json()
      }
    },
  },
})

// Print Result Examples
const goodResult: PrintResult = {
  receiptNumber: '000507',
  receiptDateTime: '2023-07-09T12:57:46',
  receiptAmount: 0.3,
  fiscalMemorySerialNumber: '12022778',
  ok: true,
  messages: [
    {
      type: 'info',
      text: 'Pending daily report',
    },
    {
      type: 'info',
      text: 'Device is fiscalized',
    },
  ],
}

const warningResult: PrintResult = {
  ok: true,
  messages: [
    {
      type: 'warning',
      code: 'W201',
      text: 'The fiscal memory almost full',
    },
    {
      type: 'info',
      text: 'Serial number and number of FM are set',
    },
    {
      type: 'info',
      text: 'FM is formatted',
    },
  ],
  deviceDateTime: '2019-05-10T15:50:00',
}

const errorResult: PrintResult = {
  ok: false,
  messages: [
    {
      type: 'error',
      code: 'E201',
      text: 'The fiscal memory is full',
    },
    {
      type: 'info',
      text: 'Serial number and number of FM are set',
    },
    {
      type: 'info',
      text: 'FM is formatted',
    },
  ],
}
