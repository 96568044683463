<template>
  <ion-modal
    ref="modal"
    :is-open="props.isOpen"
    :presenting-element="props.presentingElement"
    :enter-animation="iosEnterAnimation"
    :leave-animation="iosLeaveAnimation"
    :style="{
      '--backdrop-opacity': 0.9,
      '--min-height': '100%',
      '--min-width': '100%',
      '--border-radius': '16px 16px 0px 0px',
      '--background': 'var(--rf-bg-elevation-2)',
      'margin-top': '44px',
    }"
    :can-dismiss="canDismiss"
    @didDismiss="emit('dismiss', false)"
  >
    <ion-nav id="order-modal-nav" ref="navRef" :root="OrderModal" />
  </ion-modal>
</template>

<script setup lang="ts">
import { IonModal, IonNav } from '@ionic/vue'
import { type PropType, provide, computed, ref, watch, onMounted } from 'vue'
import { useEventBus } from '@vueuse/core'
import OrderModal from './OrderModal/index.vue'
import {
  useEnterAnimation,
  useLeaveAnimation,
} from '~/composables/useModalAnimations'

const emit = defineEmits(['dismiss', 'update'])
const props = defineProps({
  presentingElement: {
    type: Object as PropType<HTMLElement | null>,
    default: null,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  orderId: {
    type: String as PropType<string | null>,
    default: null,
  },
  objectId: {
    type: String as PropType<string | null>,
    default: null,
  },
  orderType: {
    type: String as PropType<string | null>,
    default: null,
  },
})

provide(
  'isOpen',
  computed(() => props.isOpen),
)
provide(
  'orderId',
  computed(() => props.orderId),
)
provide(
  'objectId',
  computed(() => props.objectId),
)
provide(
  'orderType',
  computed(() => props.orderType),
)
provide(
  'navRef',
  computed(() => navRef?.value?.$el || null),
)
const { iosEnterAnimation } = useEnterAnimation()
const { iosLeaveAnimation } = useLeaveAnimation()
const canDismiss = ref(false)

const navRef = ref<InstanceType<typeof IonNav> | null>(null)
const bus = useEventBus<'order-modal-dismiss' | 'order-modal-update', unknown>(
  'app',
)
bus.on(onAppEvent)

function onAppEvent(
  event: 'order-modal-dismiss' | 'order-modal-update',
  payload: unknown,
) {
  if (event === 'order-modal-dismiss') {
    return emit('dismiss', false)
  } else if (event === 'order-modal-update') {
    return emit('update', payload)
  }
}

watch(
  computed(() => props.isOpen),
  (newVal) => {
    const value = newVal
    canDismiss.value = !value

    setTimeout(() => {
      canDismiss.value = value
    }, 500)
  },
)
</script>
