import { onDeactivated, ref } from 'vue'

export default function useTime(autoInit = true) {
  const time = ref(Date.now())
  const interval = ref<null | NodeJS.Timeout>(null)

  const set = () => {
    interval.value = setInterval(() => {
      time.value = Date.now()
    }, 1000)
  }

  const clear = () => {
    if (!interval.value) return

    clearInterval(interval.value)
  }

  if (autoInit) set()

  onDeactivated(clear)

  return {
    time,
    set,
    clear,
  }
}
