<template>
  <AppStack
    align-items="items-center"
    justify-content="justify-center"
    class="text-center"
  >
    <AppTypography
      tag="h1"
      color="text-fg-elevation-0-tertiary"
      type="heading-5xl-none-semibold"
      class="caret-fg-blue"
    >
      <span
        ref="loginRef"
        contenteditable
        class="inline-block outline-none whitespace-nowrap"
        autocorrect="false"
        @keydown="onLoginKeydown"
        @input="onLoginInput"
        @blur="onLoginBlur"
        @focusin="loginIsFocused = true"
        @focusout="loginIsFocused = false"
      />
    </AppTypography>
    <AppTypography
      ref="loginDefaultRef"
      :class="loginIsFocused ? ['w-[0px] overflow-hidden'] : []"
      tag="h1"
      color="text-fg-elevation-0-tertiary"
      text="login"
      type="heading-5xl-none-semibold"
      class="select-none whitespace-nowrap flex-shrink-0"
      @click="loginRef.focus()"
    />
    <AppTypography
      tag="h1"
      color="text-fg-elevation-0-primary"
      text="@restify.app"
      type="heading-5xl-none-semibold"
      class="cursor-default"
      @click="loginRef.focus()"
    />
  </AppStack>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const emit = defineEmits(['input', 'submit'])

const loginRef = ref<HTMLElement | null>(null)
const loginDefaultRef = ref<InstanceType<typeof AppTypography> | null>(null)
const loginIsFocused = ref(false)
// const prefilledEmail = ref(window.localStorage.getItem('preferredEmail') || '')
const prefilledEmail = ref('')

const userName = computed(() => prefilledEmail.value.split('@')[0])

onMounted(() => {
  if (loginDefaultRef.value && userName.value) {
    loginDefaultRef.value.$el.textContent = userName.value
    emit('input', userName.value)
  }
})

const onLoginKeydown = ($event: KeyboardEvent) => {
  if ($event.code === 'Enter') {
    emit('submit')

    return $event.preventDefault()
  }

  if (!loginDefaultRef?.value?.$el) return

  if (
    loginDefaultRef.value.$el.textContent === 'login' &&
    $event.key.length > 1
  ) {
    return $event.preventDefault()
  }

  loginDefaultRef.value.$el.textContent = ''
}

const onLoginInput = ($event: Event) => {
  emit('input', $event?.target?.textContent.replace(/\s/g, ''))
}

const onLoginBlur = ($event: FocusEvent) => {
  if (
    $event?.target?.textContent === '' &&
    loginDefaultRef.value &&
    loginDefaultRef.value.$el.textContent === ''
  ) {
    loginDefaultRef.value.$el.textContent = 'login'
  }
}
</script>
