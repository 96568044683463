<template>
  <AppStack
    tag="section"
    direction="flex-col"
    flex="flex-1"
    class="relative safe-top"
  >
    <AppFadeTransition>
      <AppStack
        v-if="screen !== 'users'"
        justify-content="justify-between"
        align-items="items-center"
        class="absolute top-0 left-0 w-full h-[120px]"
      >
        <AppStack
          v-if="screen === 'numpad'"
          tag="button"
          align-items="items-center"
          class="absolute left-5 top-1/2 -translate-y-1/2"
          @click="onLogoutClick"
        >
          <AppIcon
            icon="log-out"
            stroke-width="1.8"
            color="text-fg-elevation-0-tertiary scale-x-[-1]"
            class="w-[32px] h-[32px]"
          />
        </AppStack>
        <AppStack
          v-else-if="screen === 'email'"
          tag="button"
          align-items="items-center"
          class="absolute left-5 top-1/2 -translate-y-1/2"
          @click="onGoToUsersClick"
        >
          <AppIcon
            icon="group"
            stroke-width="1.8"
            color="text-fg-elevation-0-tertiary scale-x-[-1]"
            class="w-[32px] h-[32px]"
          />
        </AppStack>
        <span
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          @click="onLogoClick"
        >
          <AppLogoFull class="w-[144px]" />
        </span>
        <AppStack
          v-if="screen === 'email'"
          tag="button"
          align-items="items-center"
          class="absolute right-5 top-1/2 -translate-y-1/2"
          @click="onLogInClick"
        >
          <AppIcon
            icon="arrow-right"
            stroke-width="1.8"
            :color="
              form.login ? 'text-fg-blue' : 'text-fg-elevation-0-tertiary'
            "
            class="w-[32px] h-[32px]"
          />
        </AppStack>
      </AppStack>
    </AppFadeTransition>
    <AppStack
      direction="flex-col"
      justify-content="justify-center"
      flex="flex-1"
      class="overflow-hidden"
    >
      <AppFadeTransition>
        <AppStack
          v-if="screen === 'users'"
          direction="flex-row"
          class="flex-1 snap-mandatory snap-x overflow-x-auto scrollview"
        >
          <AppLoginOrderBoard
            v-if="
              AuthStore.reactiveUser &&
              orderBoardScreenIsEnabled &&
              orderBoardDisplayedUnits
            "
            :users="usersOnShift"
            class="flex-1 py-10 snap-center min-w-[100vw] overflow-y-auto"
            @input="onEmailInput"
            @submit="onEmailSubmit"
            @add-user="onAddUser"
            @login-user="onLoginUser"
            @remove-user="removePreferredEmail"
          />
          <AppStack
            direction="flex-col"
            class="snap-center min-w-[100vw] relative flex-1"
          >
            <AppStack
              justify-content="justify-between"
              align-items="items-center"
              class="absolute top-0 left-0 w-full h-[120px]"
            >
              <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                @click="onLogoClick"
              >
                <AppLogoFull class="w-[144px]" />
              </span>
            </AppStack>
            <AppLoginUsers
              :preferred-users="preferredUsers"
              class="flex-1"
              @input="onEmailInput"
              @submit="onEmailSubmit"
              @add-user="onAddUser"
              @login-user="onLoginUser"
              @remove-user="removePreferredEmail"
            />
          </AppStack>
        </AppStack>
        <AppStack
          v-else-if="screen === 'numpad'"
          direction="flex-col"
          align-items="items-center"
          justify-content="justify-center"
          class="text-center"
        >
          <AppStack class="gap-4 mb-14">
            <div
              v-for="(circle, index) in 6"
              :key="circle"
              :class="
                form.pincode[index] === undefined
                  ? ['bg-fg-elevation-0-tertiary', 'opacity-50']
                  : [AppStore.isOffline ? 'bg-fg-yellow' : 'bg-fg-blue']
              "
              class="w-[14px] h-[14px] rounded-full transition-all duration-300"
            />
          </AppStack>
          <AppLoginNumpad
            :loading="isLoading"
            @key="onNumpadKey"
            @delete="onNumpadDelete"
          />
        </AppStack>
        <AppLoginEmail
          v-else-if="screen === 'email'"
          @input="onEmailInput"
          @submit="onEmailSubmit"
        />
      </AppFadeTransition>
    </AppStack>
  </AppStack>
</template>

<script lang="ts">
export default { name: 'Login' }
</script>

<script setup lang="ts">
import { toastController, alertController } from '@ionic/vue'
import { ref, reactive, watch, computed } from 'vue'
import { addMinutes as DateFnsAddMinutes } from 'date-fns'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'
import AppLogoFull from '@restify/packages/design-system/mid-level/AppLogoFull.vue'
import AppLoginNumpad from '~/components/AppLogin/AppLoginNumpad.vue'
import AppLoginEmail from '~/components/AppLogin/AppLoginEmail.vue'
import AppLoginUsers from '~/components/AppLogin/AppLoginUsers.vue'
import AppLoginOrderBoard from '~/components/AppLogin/AppLoginOrderBoard.vue'
import useStores from '~/composables/useStores'
import { useAuthStore } from '~/stores/auth'
import { useAppStore } from '~/stores/app'
import startupDataSetup from '~/setup/startupDataSetup'
import packageJson from '../../../package.json'

const emit = defineEmits(['login'])

const AppStore = useAppStore()
const AuthStore = useAuthStore()
const { users: UsersStore, shifts: ShiftsStore } = useStores()
const time = new Date().getTime()
const after = DateFnsAddMinutes(time, 30).getTime()

let cachedUsers = []

try {
  const value = window.localStorage.getItem('preferredEmails')

  cachedUsers = value
    ? JSON.parse(window.localStorage.getItem('preferredEmails')) || []
    : []
} catch (error) {
  console.log(error)
}

const orderBoardScreenIsEnabled = !!localStorage.getItem('waitersOrderBoard')
const orderBoardDisplayedUnits =
  JSON.parse(localStorage.getItem('displayedUnits') || 'null') || []
const preferredUsers = ref(cachedUsers || [])

const removePreferredEmail = (email: string) => {
  if (AuthStore?.user?.email && AuthStore?.user?.email === email) {
    AuthStore.logout()
  }
  const preferredEmails = preferredUsers.value.filter(
    (user: any) => user.email !== email,
  )
  preferredUsers.value = [...preferredEmails]
  window.localStorage.setItem(
    'preferredEmails',
    JSON.stringify(preferredEmails),
  )
}

const usersOnShift = computed(() => {
  return ShiftsStore.findInStore({
    $or: [
      {
        startsAt: { $lte: time },
        endsAt: { $gte: time },
      },
      {
        startsAt: { $lte: time },
        endsAt: {
          $lte: after,
          $gte: time,
        },
      },
    ],
    $limit: 100,
  }).reduce((acc: Record<string, any>, shift) => {
    shift.value?.assignedStaffIds.forEach((id) => {
      const user = UsersStore.getFromStore(id).value

      if (user?.profile.role !== 'waiter') return

      acc[id] = user
    })

    return acc
  }, {})
})

const isLoading = ref(false)
const screen = ref<'email' | 'users' | 'numpad' | 'orderBoard'>(
  preferredUsers.value.length ? 'users' : 'email',
)
const form = reactive({
  strategy: 'local',
  login: '',
  pincode: '',
})

watch(
  computed(() => form.pincode),
  (newVal, oldVal) => {
    if (newVal.length !== 6) return

    doLogin()
  },
)

const doLogin = () => {
  isLoading.value = true

  return useAuthStore()
    .authenticate({
      email: form.login.includes('@')
        ? form.login
        : form.login + '@restify.app',
      password: form.pincode,
      strategy: 'local',
    })
    .then(() => startupDataSetup())
    .then(() => emit('login'))
    .finally(() => (isLoading.value = false))
    .catch(async (error) => {
      const toast = await toastController.create({
        message: `Error: ${error.name}. ${error.message}`,
        duration: 3000,
        cssClass: 'toast text-lg-7-semibold',
        position: 'bottom',
        mode: 'ios',
      })

      await toast.present()

      form.pincode = ''
    })
}

const onLogInClick = ($event?) => {
  screen.value = 'numpad'
}

const onLogoutClick = ($event) => {
  screen.value = 'users'
  form.login = ''
  form.pincode = ''
}

const onEmailInput = ($event) => {
  form.login = $event
}

const onEmailSubmit = ($event) => {
  return onSubmitClick()
}

const onSubmitClick = ($event?) => {
  if (form.login.length >= 3) {
    return onLogInClick()
  }
}

const onNumpadKey = ($event) => {
  form.pincode.length < 6 && (form.pincode += $event)
}

const onNumpadDelete = ($event) => {
  form.pincode = ''
}

const onAddUser = () => {
  form.login = ''
  screen.value = 'email'
}

const onGoToUsersClick = () => {
  screen.value = 'users'
}

const onLoginUser = (email: string) => {
  form.login = email
  screen.value = 'numpad'
}

const onLogoClick = async () => {
  const alert = await alertController.create({
    header: 'Version hash',
    message: `${packageJson.version}`,
    buttons: ['Close'],
  })

  await alert.present()
}
</script>

<style>
.toast {
  --border-radius: 10px;
  --background: var(--rf-bg-elevation-3);
  --box-shadow: var(--rf-shadow-elevation-2-0-lg);
}

.scrollview {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollview::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
