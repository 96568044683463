<template>
  <ion-content ref="scrollView" :style="contentStyles">
    <AppStack
      :class="props.headerClasses"
      justify-content="justify-between"
      align-items="items-center"
      class="w-full pt-[38px] px-7 pb-[18px] border-solid border-t-0 border-b-[1px]
        border-x-0 border-border-elevation-0-secondary sticky top-0 left-0 z-20
        bg-bg-elevation-2-breaker"
    >
      <slot name="title">
        <AppTypography
          :text="$t('order.addMenuItem')"
          name="heading-2xl-7-bold"
          color="text-fg-elevation-2-primary"
        />
      </slot>
      <AppHeader
        :tabs="menuOptions"
        :active-tab="selectedMenuId || undefined"
        class="p-0 pt-0 w-max"
        size="lg"
        @update:tab="onSelectMenu"
      />
    </AppStack>
    <AppStack
      v-if="props.loading || loading"
      align-items="items-center"
      justify-content="justify-center"
      flex="flex-1"
      class="h-full"
    >
      <ion-spinner :style="{ '--color': 'var(--rf-fg-elevation-0-primary)' }" />
    </AppStack>
    <template v-else>
      <AppStack v-if="selectedCategoryId" class="w-full">
        <AppCategoryPicker
          v-model="selectedCategoryId"
          :options="categoriesOptions"
          bg-color="bg-bg-elevation-2"
          segment-color="bg-bg-elevation-2-alt"
          text-color="text-fg-elevation-1-primary"
          segment-shadow="shadow-elevation-3-2-xs"
          size="lg"
          radius="full"
        />
      </AppStack>
      <OrderModalMenuItemsCategory
        v-if="selectedMenuId"
        :title="selectedCategory?.title"
        :menu-items="selectedCategory?.menuItems || []"
        :loading="loading"
        class="pt-7"
        @back-tap="onBackTap"
        @menu-item-tap="onMenuItemTap"
        @configurator-opened="onConfiguratorOpened"
        @configurator-closed="onConfiguratorClosed"
      />
      <AppStack
        v-else
        direction="flex-col"
        class="w-full h-full"
        justify-content="justify-center"
        align-items="items-center"
      >
        <AppStack
          direction="flex-col"
          class="w-full"
          justify-content="justify-center"
          align-items="items-center"
        >
          <AppIcon
            icon="search"
            stroke-width="1.5"
            color="text-fg-elevation-2-tertiary"
            class="w-[64px] h-[64px] opacity-30"
          />
          <AppTypography
            text="No menus found"
            color="text-fg-elevation-2-tertiary"
            name="text-lg-7-medium"
            class="text-center"
          />
        </AppStack>
      </AppStack>
    </template>
  </ion-content>
</template>

<script setup lang="ts">
import { IonContent, IonSpinner } from '@ionic/vue'
import { ref, computed, onMounted, PropType } from 'vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import { useEventBus } from '@vueuse/core'
import useFallbackLanguage from '~/composables/useFallbackLanguage'
import useReactivePagination from '@restify/packages/composables/useReactivePaginationFactory'
import useStores, { type Stores } from '~/composables/useStores'
import { useAppStore } from '~/stores/app'
import OrderModalMenuItemsCategory from './OrderModalMenuItemsCategory.vue'
import AppHeader from '~/components/AppHeader/index.vue'
import AppCategoryPicker from '~/components/AppCategoryPicker.vue'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
  headerClasses: {
    type: String,
    default: '',
  },
})

const {
  menus: MenusStore,
  storefronts: StorefrontsStore,
  menuItems: MenuItemsStore,
} = useStores()
const AppStore = useAppStore()
const bus = useEventBus<string>('batch')

const loading = ref(false)
const scrollView = ref(null)
const selectedCategoryId = ref<string | null>(null)
const selectedMenuId = ref<string | null>(null)

const { find: findMenus } = useReactivePagination(
  MenusStore,
  `${AppStore.selectedStorefront._id}-catalog`,
  {
    status: 'public',
    $skip: 0,
    $limit: 150,
  },
  {
    store: MenuItemsStore,
    query: (menus) => {
      return {
        _id: {
          $in: [
            ...new Set([
              ...menus.reduce((acc: string[], menu) => {
                acc.push(
                  ...menu.value?.menuItems.map(({ menuItemId }) => menuItemId),
                )

                return acc
              }, []),
            ]),
          ],
        },
        $limit: 150,
      }
    },
  },
)

const menuOptions = computed(() => {
  return menus.value.map((menu) => ({
    name: useFallbackLanguage(menu.value.name),
    value: menu.value._id,
  }))
})

const contentStyles = computed(() => ({
  // '--padding-top': '30px',
  '--background': 'var(--rf-bg-elevation-2-breaker)',
  '--border-color': 'var(--rf-border-elevation-0-primary)',
}))

const storefront = computed(() => {
  if (!AppStore.selectedStorefrontId) return null

  return StorefrontsStore.getFromStore(AppStore.selectedStorefrontId).value
})

const menuIds = computed(() => {
  if (!storefront.value) return []

  return [...(storefront.value?.inHouseService?.menuIds || [])]
})

const menuItemIds = computed(() => {
  if (!storefront.value || !menuIds.value.length || !menus.value.length) {
    return []
  }

  return [
    ...new Set([
      ...menus.value.reduce((acc: string[], menu) => {
        acc.push(...menu.value?.menuItems.map(({ menuItemId }) => menuItemId))

        return acc
      }, []),
    ]),
  ]
})

const selectedMenu = computed(() => {
  if (!selectedMenuId.value) return null

  return menus.value.find((menu) => menu.value._id === selectedMenuId.value)
    ?.value
})

const menus = computed(() => {
  if (!menuIds.value.length) return []

  return MenusStore.findInStore({
    _id: { $in: menuIds.value },
    status: 'public',
  })
})

const categories = computed(() => {
  const menu = selectedMenu.value

  if (!menu) return []

  const mappedCategories = menu.categories.reduce((acc, category) => {
    acc[category.id] = {
      id: category.id,
      title: useFallbackLanguage(category.name),
      menuItems: [],
    }

    return acc
  }, {})
  const mappedMenuItems = menu.menuItems.reduce((acc, item) => {
    const menuItem = menuItems.value[item.menuItemId]
    const category = mappedCategories[item.categoryId]

    if (menuItem) {
      category.menuItems.push(menuItem)
    }

    return mappedCategories
  }, {})
  return mappedMenuItems
})

const selectedCategory = computed(() => {
  return categories.value[selectedCategoryId.value]
})

const menuItems = computed(() => {
  if (!menuItemIds.value.length) return []

  return MenuItemsStore.findInStore({
    _id: { $in: menuItemIds.value },
    $limit: 500,
  }).reduce((acc: Record<string, Stores['menuItems']['Result']>, item) => {
    acc[item.value._id] = item.value

    return acc
  }, {})
})

const categoriesOptions = computed(() => {
  return Object.values(categories.value).map((category) => {
    return {
      label: category.title,
      value: category.id,
    }
  })
})

const onSelectMenu = (value) => {
  selectedMenuId.value = value
  selectedCategoryId.value = Object.keys(categories.value)[0]
}

const onBackTap = () => {
  selectedCategoryId.value = null
}

const onMenuItemTap = (payload: {
  menuItem: Stores['menuItems']['Result']
  onClick: () => void
}) => {
  bus.emit('batch-add', payload)

  payload.onClick()
}

const fetchData = () => {
  loading.value = true

  return findMenus()
    .then(() => {
      if (menus.value?.[0]?.value?._id)
        selectedMenuId.value = menus.value[0]?.value?._id
      selectedCategoryId.value = Object.keys(categories.value)[0]
    })
    .finally(() => (loading.value = false))
}

const onConfiguratorOpened = ($event) => {
  $event?.scrollIntoView({ behavior: 'smooth' })
}

const onConfiguratorClosed = () => {
  scrollView.value?.$el?.scrollToTop(0)
}

onMounted(fetchData)
</script>
