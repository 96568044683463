<template>
  <AppStack direction="flex-col">
    <OrderModalSidebarHeader
      is-checkout
      :order="props.order"
      :injected-object-id="props.injectedObjectId"
      class="sticky top-0"
      @back-tap="emit('back-tap')"
    />
    <template v-if="order">
      <AppStack
        :style="actionsStyles"
        direction="flex-col"
        class="transition-transform duration-300 ease-in-out safe-bottom-max"
      >
        <OrderModalMenuitemsCheckout :order="order" class="flex-1" />
        <div
          :class="
            actionsAreShown
              ? ['opacity-60']
              : ['opacity-0', 'pointer-events-none']
          "
          class="absolute top-0 left-0 w-full h-full z-[10] bg-bg-elevation-2 transition-opacity
            duration-200 ease-in-out"
          @click="actionsAreShown && onActionsDismiss()"
        />
        <div class="h-[280px]" />
      </AppStack>
      <div
        :style="actionsStyles"
        class="transition-transform duration-300 ease-in-out z-[10] fixed bottom-0 left-0
          w-[370px] safe-bottom-max bg-bg-elevation-2"
      >
        <div
          class="absolute top-[-100px] left-0 w-full h-[100px] pointer-events-none
            bg-gradient-to-b to-bg-elevation-2 from-transparent"
        />
        <OrderModalFooterCheckout :order="order" />
        <div
          :class="
            actionsAreShown
              ? ['opacity-60']
              : ['opacity-0', 'pointer-events-none']
          "
          class="absolute top-0 left-0 w-full h-full z-[10] bg-bg-elevation-2 transition-opacity
            duration-200 ease-in-out"
          @click="actionsAreShown && onActionsDismiss()"
        />
      </div>
      <div
        :style="actionsStyles"
        :class="actionsAreShown ? ['opacity-100'] : ['opacity-0']"
        class="z-[2] fixed bottom-0 left-0 w-[370px] pointer-events-none
          transition-[transform,opacity] duration-300 ease-in-out"
      >
        <div ref="actionsRef" class="relative translate-y-full safe-bottom-max">
          <OrderModalActions
            :order="order"
            class="pointer-events-auto safe-top-max border-t-[0.5px] border-solid border-b-0
              border-x-0 border-border-elevation-2-secondary"
            @dismiss="onActionsDismiss"
          />
        </div>
      </div>
    </template>
  </AppStack>
</template>

<script setup lang="ts">
import { ref, type PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import OrderModalSidebarHeader from './OrderModalSidebarHeader.vue'
import OrderModalMenuitemsCheckout from './OrderModalSidebarMenuitemsCheckout.vue'
import OrderModalFooterCheckout from './OrderModalSidebarFooterCheckout.vue'
import OrderModalActions from './OrderModalSidebarActions.vue'
import { type Stores } from '~/composables/useStores'

const emit = defineEmits([
  'back-tap',
])

const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: undefined,
  },
  injectedObjectId: {
    type: String,
    default: undefined,
  },
})

const actionsAreShown = ref(false)
const actionsRef = ref<HTMLElement | null>(null)
const actionsStyles = ref({})

const onActionsDismiss = () => {
  actionsAreShown.value = false
  actionsStyles.value = {}
}
</script>
