import { type Stores } from '~/composables/useStores'

export const getPickupOrderStatus = (order: Stores['orders']['Result']) => {
  if (order?.menuItems.length === 0 || order.status.name === 'created') {
    return 'backlog'
  }

  if (order.status.name === 'ready') {
    return 'ready'
  }

  if (order.status.name === 'confirmed') {
    return 'inProgress'
  }

  return 'backlog'
}

export const isOrderCooked = (order: Stores['orders']['Result']) => {
  let allReady = true

  if (order?.menuItems) {
    for (let index = 0; index < order?.menuItems.length; index++) {
      const menuItem = order?.menuItems[index]

      if (menuItem.status.name !== 'ready') {
        allReady = false
      }
    }
  }

  return allReady
}
