<template>
  <AppStack direction="flex-col">
    <OrderModalSidebarHeader
      :order="props.order"
      :injected-object-id="props.injectedObjectId"
    />
    <AppStack
      direction="flex-col"
      align-items="items-stretch"
      justify-content="justify-center"
      flex="flex-1"
      :style="{ height: 'calc(100% - 66px)' }"
    >
      <AppStack
        direction="flex-col"
        align-items="items-center"
        justify-content="justify-center"
        flex="flex-1"
        class="gap-0"
      >
        <AppTypography
          color="text-fg-elevation-2-secondary"
          :text="$t('order.noOrderPlaceholder')"
          name="text-lg-6-medium"
          class="relative"
        />
      </AppStack>
      <AppStack direction="flex-col" class="safe-bottom px-7 mt-auto">
        <AppStack class="gap-2 pb-5">
          <button
            :class="
              props.orderCreationInProgress ? ['pointer-events-none'] : []
            "
            variant="custom"
            class="py-[18px] relative rounded-xl overflow-hidden flex-1"
            @click="emit('create-tap')"
          >
            <div
              class="absolute top-0 left-0 w-full h-full bg-bg-blue opacity-100"
            />
            <AppFadeTransition>
              <AppSpinner
                v-if="props.orderCreationInProgress"
                variant="fill-white"
              />
              <AppTypography
                v-else
                color="text-universal-fg-opposite"
                :text="$t('order.createOrder')"
                name="text-lg-7-semibold"
                class="relative"
              />
            </AppFadeTransition>
          </button>
        </AppStack>
      </AppStack>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'
import AppSpinner from '@restify/packages/design-system/low-level/AppSpinner.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import OrderModalSidebarHeader from './OrderModalSidebarHeader.vue'
import type { PropType } from 'vue'
import { type Stores } from '~/composables/useStores'

const emit = defineEmits(['create-tap'])
const props = defineProps({
  orderCreationInProgress: {
    type: Boolean,
    default: false,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: undefined,
  },
  injectedObjectId: {
    type: String,
    default: undefined,
  },
})
</script>
