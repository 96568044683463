<template>
  <AppStack
    align-items="items-center"
    justify-content="justify-center"
    wrap="flex-wrap"
    class="gap-3 w-[284px]"
  >
    <AppStack
      v-for="key in 9"
      :key="key"
      align-items="items-center"
      justify-content="justify-center"
      class="w-[84px] h-[84px] relative rounded-2xl overflow-hidden group active:top-[2px]
        shadow-elevation-3-2-xs border-[1px] border-solid
        border-border-elevation-3-secondary"
      @click="onKeyClick(key)"
    >
      <div
        class="absolute top-0 left-0 w-full h-full bg-bg-elevation-3 group-active:opacity-50
          group-active:bg-bg-elevation-3-alt"
      />
      <AppTypography
        :text="String(key)"
        color="text-fg-elevation-2-primary"
        type="heading-3xl-9-medium"
        class="relative"
      />
    </AppStack>
    <AppStack
      align-items="items-center"
      justify-content="justify-center"
      tabindex="-1"
      class="w-[84px] h-[84px] relative"
    />
    <AppStack
      align-items="items-center"
      justify-content="justify-center"
      class="w-[84px] h-[84px] relative rounded-2xl overflow-hidden group active:top-[2px]
        shadow-elevation-3-2-xs border-[1px] border-solid
        border-border-elevation-3-secondary"
      @click="onKeyClick(0)"
    >
      <div
        class="absolute top-0 left-0 w-full h-full bg-bg-elevation-3 group-active:opacity-50
          group-active:bg-bg-elevation-3-alt"
      />
      <AppTypography
        text="0"
        color="text-fg-elevation-2-primary"
        type="heading-3xl-9-medium"
        class="relative"
      />
    </AppStack>
    <AppStack
      align-items="items-center"
      justify-content="justify-center"
      class="w-[84px] h-[84px] relative rounded-2xl overflow-hidden group active:top-[2px]
        shadow-elevation-3-2-xs border-[1px] border-solid
        border-border-elevation-3-secondary"
      @click="onKeyClick('delete')"
    >
      <div
        class="absolute top-0 left-0 w-full h-full bg-bg-elevation-3 group-active:opacity-50
          group-active:bg-bg-elevation-3-alt"
      />
      <BackspaceIcon
        class="text-fg-elevation-2-tertiary relative -left-[2px] w-[36px] h-[36px]"
      />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { BackspaceIcon } from '@heroicons/vue/24/outline'

import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const emit = defineEmits(['key', 'delete'])

const onKeyClick = (key: 'delete' | number) => {
  if (Capacitor.isNativePlatform()) {
    Haptics.impact({ style: ImpactStyle.Medium })
  }

  if (key === 'delete') {
    emit('delete')
  } else {
    emit('key', key)
  }
}
</script>
