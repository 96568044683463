<template>
  <ion-page>
    <div class="z-10 pt-3 pb-3 mb-[-30px]">
      <div
        class="w-[60px] h-[6px] rounded-sm bg-border-elevation-0-primary mx-auto"
      />
    </div>
    <AppStack flex="flex-1">
      <PromotionsModalSidebar :loading="loading" :order="order" />
      <PromotionsModalContent
        :loading="loading"
        :order="order"
        :is-order-edit="isOrderEdit"
        :is-checkout="isCheckout"
        @end-checkout="endCheckoutHandler"
      />
    </AppStack>
  </ion-page>
</template>

<script lang="ts">
export default { name: 'PromotionsModal' }
</script>

<script setup lang="ts">
import { IonPage } from '@ionic/vue'
import { ref, computed, watch, inject, ComputedRef } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import { getUniqueValues as HelpersArrayGetUniqueValues } from '@restify/packages/helpers/array'
import useStores from '~/composables/useStores'
import PromotionsModalSidebar from './PromotionsModalSidebar/index.vue'
import PromotionsModalContent from './PromotionsModalContent/index.vue'

const { orders: OrdersStore, promotions: PromotionsStore } = useStores()

const injectedIsOpen = inject<ComputedRef<boolean>>('isOpen')
const injectedOrderId = inject<ComputedRef<string>>('orderId')
const loading = ref(false)
const isOrderEdit = ref(false)
const isCheckout = ref(false)

const order = computed(() => {
  if (!injectedOrderId?.value) return null

  return OrdersStore.getFromStore(injectedOrderId.value).value
})

const endCheckoutHandler = () => {
  isCheckout.value = false
}

const getOrder = () => {
  if (injectedOrderId?.value === 'new' || !injectedOrderId?.value)
    return Promise.resolve()

  return OrdersStore.get(injectedOrderId.value)
}

const getPromotions = () => {
  const ids = HelpersArrayGetUniqueValues([
    ...(order?.value?.promotionIds || []),
    ...Object.keys(order?.value?.appliedPromotions?.promotions || {}),
  ])

  if (!order.value) return

  return Promise.all([
    PromotionsStore.find({
      query: {
        _id: { $in: ids },
        $limit: 100,
      },
    }),
    PromotionsStore.find({
      query: {
        orderType: order.value.type,
        status: 'public',
        startDate: { $lt: new Date().getTime() },
        endDate: { $gt: new Date().getTime() },
        $limit: 100,
      },
    }),
  ])
}

const fetchData = () => {
  loading.value = false

  return getOrder()
    .then(() => Promise.all([getPromotions()]))
    .finally(() => (loading.value = false))
}

watch(
  computed(() => injectedIsOpen?.value),
  (newVal, oldVal) => newVal && fetchData(),
  { immediate: true },
)
</script>
