<template>
  <div>
    <AppStack
      direction="flex-col"
      class="h-full bg-bg-elevation-2 rounded-md px-6 py-7"
    >
      <!-- <AppStack class="gap-1">
        <AppTypography
          name="heading-2xl-7-semibold"
          :text="props.title"
        />

        <AppTypography
          :text="`${props.currentStep}/${props.totalSteps}`"
          name="text-md-4-medium"
          color="text-fg-elevation-2-tertiary"
        />
      </AppStack> -->
      <AppStack
        align-items="items-center"
        justify-content="justify-between"
        class="mb-7"
      >
        <div>
          <AppTypography name="heading-2xl-7-semibold" :text="props.title" />
          <AppTypography
            class="ml-[6px]"
            name="text-lg-6-semibold"
            color="text-fg-elevation-2-tertiary"
            :text="`${props.currentStep}/${props.totalSteps}`"
          />
        </div>
        <AppProgressBar
          :progress="(props.currentStep / props.totalSteps) * 100"
        />
      </AppStack>
      <AppStack class="gap-[10px]">
        <OrderModalMenuItemsItemCell
          v-for="(option, optionKey) in props.options"
          :key="optionKey"
          :title="useFallbackLanguage(option.name)"
          :subtitle="formatPrice(option.price)"
          :elevation="3"
          class="flex-1 max-w-[170px] order-1 border-border-elevation-3-primary border-solid
            border"
          @menu-item-tap="
            emit('menu-item-option-tap', {
              option,
              onClick: $event,
            })
          "
        />
      </AppStack>
    </AppStack>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { type Stores } from '~/composables/useStores'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import OrderModalMenuItemsItemCell from './OrderModalMenuItemsItemCell.vue'
import useFallbackLanguage from '~/composables/useFallbackLanguage'
import AppProgressBar from '~/components/AppProgressBar.vue'
import useAppHelpers from '~/composables/useAppHelpers'

const { formatPrice } = useAppHelpers()

const emit = defineEmits(['menu-item-option-tap'])
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  currentStep: {
    type: Number,
    required: true,
  },
  totalSteps: {
    type: Number,
    required: true,
  },
  options: {
    type: Array as PropType<
      Array<
        | Stores['menuItems']['Result']['variations'][0]['options'][0]
        | Stores['menuItems']['Result']['extras'][0]['options'][0]
      >
    >,
    default: () => [],
  },
})
</script>
