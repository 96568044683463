<template>
  <AppStack
    justify-content="justify-between"
    align-items="items-center"
    class="w-full px-5 py-4 gap-6"
  >
    <AppStack direction="flex-col" class="overflow-hidden">
      <AppTypography
        name="text-lg-7-semibold"
        color="text-fg-elevation-3-primary"
        :text="props.title"
      />
      <AppTypography
        :text="props.description"
        name="text-lg-7-normal"
        color="text-fg-elevation-3-secondary"
        class="truncate"
      />
    </AppStack>
    <AppTypography
      :text="applied ? $t('promotions.applied') : $t('promotions.apply')"
      :color="applied ? 'text-fg-elevation-3-tertiary' : 'text-fg-blue'"
      name="text-lg-7-medium"
      class="active:opacity-50"
      @click="!applied && emit('apply')"
    />
  </AppStack>
</template>

<script setup lang="ts">
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const emit = defineEmits(['apply'])

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: '',
  },
  applied: {
    type: Boolean,
    required: false,
  },
})
</script>
