<template>
  <AppStack direction="flex-col" class="px-7">
    <AppStack v-if="!props.batchItems.length" direction="flex-col">
      <AppStack
        direction="flex-col"
        align-items="items-baseline"
        class="mb-6 gap-1"
      >
        <AppStack
          v-for="(caption, index) in captionsList"
          :key="index"
          justify-content="justify-between"
          align-items="items-baseline"
          class="w-full"
        >
          <AppTypography
            :text="caption.key"
            name="text-lg-7-medium"
            color="text-fg-elevation-2-secondary"
          />
          <AppTypography
            :text="caption.value"
            name="text-lg-7-medium"
            color="text-fg-elevation-2-secondary"
            class="ml-3"
          />
        </AppStack>
      </AppStack>
      <AppStack align-items="items-baseline">
        <AppTypography
          :text="formatPrice(orderTotal)"
          name="heading-205xl-8-bold"
          color="text-fg-elevation-2-primary"
        />
        <AppTypography
          :text="quantityText"
          name="heading-2xl-7-semibold"
          color="text-fg-elevation-2-secondary"
          class="ml-3"
        />
      </AppStack>
    </AppStack>
    <AppStack v-if="props.batchItems.length" class="mt-6 gap-3">
      <button
        :class="
          checkoutIsDisabled || props.sendToWorkInProgress
            ? ['pointer-events-none']
            : []
        "
        variant="custom"
        class="py-[18px] relative rounded-lg overflow-hidden flex-1"
        @click="emit('send-tap')"
      >
        <div
          :class="
            checkoutIsDisabled || sendToWorkInProgress
              ? ['bg-bg-elevation-2-alt']
              : ['bg-bg-blue']
          "
          class="absolute top-0 left-0 w-full h-full active:brightness-90"
        />
        <AppFadeTransition>
          <AppSpinner v-if="props.sendToWorkInProgress" variant="fill-white" />
          <AppTypography
            v-else
            :color="
              checkoutIsDisabled
                ? 'text-fg-elevation-2-secondary'
                : 'text-universal-fg-opposite'
            "
            :text="$t('order.sendToWork')"
            name="text-lg-7-semibold"
            class="relative"
          />
        </AppFadeTransition>
      </button>
      <button
        variant="custom"
        class="px-4 relative rounded-lg overflow-hidden border-[1px]
          border-border-elevation-3-secondary border-solid"
        @click="emit('clear-tap')"
      >
        <div
          class="absolute top-0 left-0 w-full h-full opacity-100 bg-bg-elevation-3"
        />
        <AppIcon
          color="text-fg-elevation-3-secondary"
          icon="bin-minus"
          size="custom"
          class="w-[32px] h-[32px] relative"
        />
      </button>
    </AppStack>
    <AppStack class="mt-6 gap-3">
      <button
        :class="
          checkoutIsDisabled || sendToWorkInProgress
            ? ['pointer-events-none']
            : []
        "
        variant="custom"
        class="py-[18px] relative rounded-lg overflow-hidden flex-1"
        @click="emit('checkout-tap')"
      >
        <div
          :class="
            checkoutIsDisabled || sendToWorkInProgress
              ? ['bg-bg-elevation-2-alt']
              : ['bg-bg-blue', 'opacity-20']
          "
          class="absolute top-0 left-0 w-full h-full"
        />
        <AppTypography
          :color="
            checkoutIsDisabled || sendToWorkInProgress
              ? 'text-fg-elevation-2-secondary'
              : 'text-fg-blue'
          "
          :text="$t('order.checkout')"
          name="text-lg-7-semibold"
          class="relative"
        />
      </button>
      <button
        variant="custom"
        class="px-4 relative rounded-lg overflow-hidden border-[1px]
          border-border-elevation-3-secondary border-solid"
        @click="emit('actions-tap')"
      >
        <div
          class="absolute top-0 left-0 w-full h-full opacity-100 bg-bg-elevation-3"
        />
        <AppIcon
          color="text-fg-elevation-3-secondary"
          icon="more-horiz"
          size="custom"
          class="w-[32px] h-[32px] relative"
        />
      </button>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'
import AppSpinner from '@restify/packages/design-system/low-level/AppSpinner.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { useI18n } from 'vue-i18n'
import useAppHelpers from '~/composables/useAppHelpers'

const emit = defineEmits([
  'actions-tap',
  'checkout-tap',
  'batch-send-to-work',
  'send-tap',
  'clear-tap',
])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
  batchItems: {
    type: Array,
    default: () => [],
  },
  displayBatchedUi: {
    type: Boolean,
    default: false,
  },
  sendToWorkInProgress: {
    type: Boolean,
    default: false,
  },
})

const { formatPrice } = useAppHelpers()
const { promotions: PromotionsStore } = useStores()

const checkoutIsDisabled = computed(() => {
  return (
    !Object.keys(props.batchItems).length &&
    props.order &&
    props.order.menuItems.length === 0
  )
})

const promotionsList = computed(() => {
  const ids = [...Object.keys(props.order?.appliedPromotions?.promotions || {})]

  if (!ids.length) return []

  return ids.reduce((acc: any[], id) => {
    const promotion = PromotionsStore.getFromStore(id).value

    if (promotion) acc.push(promotion)

    return acc
  }, [])
})

const orderTotal = computed(() => {
  return typeof props?.order?.appliedPromotions?.total === 'number'
    ? props.order?.appliedPromotions?.total || 0
    : props.order?.total || 0
})

const { t } = useI18n()
const quantityText = computed(() => {
  if (props.displayBatchedUi) {
    const quantity = Object.values(props.batchItems || {}).reduce(
      (acc: number, item: Stores['orders']['Result']['menuItems'][number]) => {
        if (!props.order) return 0

        acc = acc + item.quantity

        return acc
      },
      0,
    )

    return t('order.items', quantity)
  }

  const quantity = props.order?.menuItems.reduce(
    (acc: number, item: Stores['orders']['Result']['menuItems'][number]) => {
      if (!props.order) return 0

      acc = acc + item.quantity

      return acc
    },
    0,
  )

  return t('order.items', quantity)
})

const captionsList = computed(() => {
  return [
    ...(promotionsList.value.length
      ? [
          {
            key: 'Total',
            value: formatPrice(props.order?.total),
          },
        ]
      : []),
    ...promotionsList.value.map((promotion) => {
      return {
        key: promotion?.name,
        value: formatPrice(
          props.order?.appliedPromotions?.promotions?.[promotion?._id]?.discount
            ? -props.order?.appliedPromotions?.promotions?.[promotion?._id]
                ?.discount
            : 0,
        ),
      }
    }),
  ]
})
</script>
