<template>
  <ion-content
    :style="sidebarStyles"
    :scroll-y="['transactions'].includes(props.activeScreen)"
  >
    <AppStack
      direction="flex-col"
      class="flex-1 min-h-full overflow-hidden h-full"
    >
      <AppStack
        v-if="props.loading"
        align-items="items-center"
        justify-content="justify-center"
        flex="flex-1"
        class="h-full"
      >
        <ion-spinner
          :style="{ '--color': 'var(--rf-fg-elevation-2-secondary)' }"
        />
      </AppStack>
      <OrderModalSidebarCreate
        v-else-if="!props.order"
        class="h-full flex-1"
        :order-creation-in-progress="orderCreationInProgress"
        :order="props.order"
        :injected-object-id="injectedObjectId || undefined"
        @create-tap="onCreateTap"
      />
      <OrderModalSidebarOrderEdit
        v-else-if="props.order && props.activeScreen === 'edit'"
        :order="props.order"
        :injected-object-id="injectedObjectId || undefined"
        @end-batch-tap="emit('update:active-screen', 'default')"
      />
      <OrderModalSidebarCheckout
        v-else-if="props.order && ['transactions'].includes(props.activeScreen)"
        :order="props.order"
        :injected-object-id="injectedObjectId || undefined"
        @back-tap="emit('update:active-screen', 'default')"
      />
      <OrderModalSidebarFinalise
        v-else-if="props.order && props.activeScreen === 'finalise'"
        :order="props.order"
        :injected-object-id="injectedObjectId || undefined"
      />
      <OrderModalSidebarOrder
        v-else-if="props.order"
        :order="props.order"
        :is-menu-items="props.activeScreen === 'default' && !displayBatchedUi"
        :injected-object-id="injectedObjectId || undefined"
        @start-batch-tap="emit('update:active-screen', 'edit')"
        @checkout-tap="emit('update:active-screen', 'transactions')"
        @add-menu-item-tap="emit('update:active-screen', 'add-menu-item')"
        @update:currently-previewed-order-id="
          emit('update:currently-previewed-order-id', $event)
        "
      />
      <AppStack
        v-else
        align-items="items-center"
        justify-content="justify-center"
        flex="flex-1"
        class="h-full"
      >
        Could not load order
      </AppStack>
    </AppStack>
  </ion-content>
</template>

<script lang="ts">
export default { name: 'OrderModalSidebar' }
</script>

<script setup lang="ts">
import { IonSpinner, IonContent, toastController } from '@ionic/vue'
import { ref, computed, inject, ComputedRef, PropType } from 'vue'
import { useEventBus } from '@vueuse/core'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { useAuthStore } from '~/stores/auth'
import OrderModalSidebarOrderEdit from './OrderModalSidebarOrderEdit.vue'
import OrderModalSidebarCheckout from './OrderModalSidebarTransactions.vue'
import OrderModalSidebarFinalise from './OrderModalSidebarFinalise.vue'
import OrderModalSidebarCreate from './OrderModalSidebarCreateOrder.vue'
import OrderModalSidebarOrder from './OrderModalSidebarOrder.vue'
import { useShiftsLogic } from '@restify/packages/composables/useShiftsLogic'
import { useAppStore } from '~/stores/app'

const emit = defineEmits([
  'update:active-screen',
  'update:currently-previewed-order-id',
])
const bus = useEventBus<'order-modal-dismiss' | 'order-modal-update', unknown>(
  'app',
)
const props = defineProps({
  loading: {
    type: Boolean,
    required: false,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
  activeScreen: {
    type: String as PropType<
      'default' | 'add-menu-item' | 'edit' | 'transactions' | 'finalise'
    >,
    required: true,
  },
})

const { orders: OrdersStore, storefrontPlaces: StorefrontPlacesStore } =
  useStores()
const AppStore = useAppStore()
const AuthStore = useAuthStore()
const { shift, shiftIsHappening } = useShiftsLogic(AuthStore)

const orderCreationInProgress = ref(false)
const injectedObjectId = inject<ComputedRef<string> | null>('objectId', null)
const injectedObjectType = inject<ComputedRef<string>>('orderType')

const displayBatchedUi = computed(() => {
  return AppStore?.selectedStorefront?.inHouseService.setup.batches
})

const sidebarStyles = computed(() => ({
  'min-width': '370px',
  'max-width': '370px',
  '--background': 'var(--rf-bg-elevation-2)',
  'border-right': '1px solid var(--rf-border-elevation-2-secondary)',
}))

const storefrontPlace = computed(() => {
  if (!injectedObjectId?.value) return null

  return StorefrontPlacesStore.getFromStore(injectedObjectId.value).value
})

const onCreateTap = () => {
  if (
    !AuthStore.reactiveUser?._id ||
    (!injectedObjectId?.value && !injectedObjectType?.value)
  )
    return

  orderCreationInProgress.value = true

  return OrdersStore.create({
    status: {
      name: 'created',
      createdAt: new Date().getTime(),
    },
    menuItems: [],
    batches: [],
    tabName: '',
    fees: [],
    promotionIds: [],
    type: injectedObjectType?.value || 'inHouseService',
    storefrontPlaceIds: storefrontPlace?.value
      ? [storefrontPlace.value?._id]
      : [],
    staff: [
      {
        shiftId:
          shift.value && shiftIsHappening.value ? shift.value._id : undefined,
        staffId: AuthStore.reactiveUser?._id,
        role: AuthStore.reactiveUser.profile.role,
        processed: false,
      },
    ],
  })
    .then((newOrder) => {
      bus.emit('order-modal-update', {
        orderId: newOrder.value._id,
        ...(injectedObjectId?.value
          ? {
              objectId: injectedObjectId.value,
            }
          : {}),
      })
    })
    .catch(async (error) => {
      const toast = await toastController.create({
        message: `Error: ${error.name}. ${error.message}`,
        duration: 3000,
        cssClass: 'toast text-lg-7-semibold',
        position: 'bottom',
        mode: 'ios',
      })

      await toast.present()
    })
    .finally(() => {
      orderCreationInProgress.value = false
    })
}
</script>
