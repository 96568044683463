<template>
  <ion-page>
    <div class="z-10 pt-3 pb-3 absolute top-0 left-0 w-full">
      <div
        class="w-[60px] h-[6px] rounded-sm bg-border-elevation-3-secondary mx-auto"
      />
    </div>
    <AppStack flex="flex-1">
      <OrderModalSidebar
        :order="order"
        :is-checkout="isCheckout"
        :is-finalise="isFinalise"
        @start-checkout="
          () => {
            isCheckout = true
            isFinalise = false
          }
        "
        @start-finalise="
          () => {
            isCheckout = false
            isFinalise = true
          }
        "
        @end-checkout="onCheckoutDismiss"
      />
      <OrderModalContent
        :loading="loading"
        :is-checkout="isCheckout"
        :order="order"
        :is-finalise="isFinalise"
        :class="
          isCheckout || isFinalise
            ? ['bg-bg-elevation-2']
            : ['bg-bg-elevation-2-breaker']
        "
        @end-checkout="onCheckoutDismiss"
        @start-checkout="
          () => {
            isCheckout = true
            isFinalise = false
          }
        "
        @start-finalise="
          () => {
            isCheckout = false
            isFinalise = true
          }
        "
      />
    </AppStack>
  </ion-page>
</template>

<script setup lang="ts">
import { IonPage } from '@ionic/vue'
import { ref, computed, onMounted, inject, ComputedRef } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import OrderModalSidebar from '~/views/OrderModal/OrderModalSidebar/index.vue'
import OrderModalContent from '~/views/OrderModal/OrderModalContent/index.vue'
import { useEventBus } from '@vueuse/core'
import useStores from '~/composables/useStores'

const {
  orders: OrdersStore,
  users: UsersStore,
  storefrontPlaces: StorefrontPlacesStore,
} = useStores()

const injectedOrderId = inject<ComputedRef<string>>('orderId')
const injectedObjectId = inject<ComputedRef<string> | null>('objectId', null)
const loading = ref(false)
const isCheckout = ref(true)
const isFinalise = ref(false)

const bus = useEventBus<string>('app')

const onCheckoutDismiss = () => {
  bus.emit('quick-order-checkout-dismiss')
}
const order = computed(() => {
  if (!injectedOrderId?.value) return null

  return OrdersStore.getFromStore(injectedOrderId.value).value
})

const getOrder = () => {
  if (injectedOrderId?.value === 'new' || !injectedOrderId?.value)
    return Promise.resolve()

  return OrdersStore.get(injectedOrderId?.value)
}

const getStorefrontPlace = () => {
  const ids = [
    ...(order.value?.storefrontPlaceIds || []),
    ...(injectedObjectId?.value ? [injectedObjectId?.value] : []),
  ]

  return StorefrontPlacesStore.find({ query: { _id: { $in: ids } } })
}

const getStaff = () => {
  const staffIds = order.value?.staff.map((staff) => staff.staffId)

  if (!staffIds?.length) return Promise.resolve()

  return UsersStore.find({ query: { _id: { $in: staffIds } } })
}

const fetchData = () => {
  loading.value = false

  return getOrder()
    .then(() => Promise.all([getStaff(), getStorefrontPlace()]))
    .finally(() => (loading.value = false))
}

onMounted(() => fetchData())
</script>
