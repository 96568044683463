<template>
  <AppStack
    align-items="items-center"
    class="py-3 pl-7 pr-3 relative active:opacity-50"
  >
    <StationFadeGradient
      v-if="props.isSelected"
      color="from-bg-elevation-2-alt"
      class="absolute h-full w-[460px] top-0 right-0"
    />
    <AppIcon
      icon="plus"
      size="custom"
      color="text-fg-blue"
      stroke-width="1.8px"
      class="w-7 h-7 relative"
    />
    <AppTypography
      :text="props.text"
      name="text-lg-7-semibold"
      color="text-fg-blue"
      class="ml-2 relative"
    />
  </AppStack>
</template>

<script setup lang="ts">
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import StationFadeGradient from '~/components/StationFadeGradient.vue'

const props = defineProps({
  isSelected: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    required: true,
  },
})
</script>
