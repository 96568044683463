<template>
  <AppStack direction="flex-col" class="px-7">
    <AppStack
      v-if="
        Object.keys(props.order?.appliedPromotions?.promotions || {}).length
      "
      direction="flex-col"
    >
      <AppStack align-items="items-baseline" class="mb-6">
        <AppTypography
          :text="formatPrice(orderTotal)"
          name="heading-205xl-8-bold"
          color="text-fg-elevation-2-primary"
        />
        <AppTypography
          v-if="orderSecondaryTotal !== null"
          :text="formatPrice(orderSecondaryTotal)"
          name="heading-2xl-7-semibold"
          color="text-fg-elevation-2-secondary"
          class="ml-3"
        />
      </AppStack>
    </AppStack>
    <AppStack class="gap-2">
      <button
        variant="custom"
        class="py-[18px] relative rounded-xl overflow-hidden flex-1"
        @click="emit('done')"
      >
        <div
          class="absolute top-0 left-0 w-full h-full bg-bg-elevation-3-alt active:brightness-90"
        />
        <AppTypography
          color="text-fg-elevation-3-primary"
          :text="$t('promotions.done')"
          name="text-lg-7-semibold"
          class="relative"
        />
      </button>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import { type Stores } from '~/composables/useStores'
import useAppHelpers from '~/composables/useAppHelpers'

const { formatPrice } = useAppHelpers()

const emit = defineEmits(['done'])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const orderTotal = computed(() => {
  return typeof props?.order?.appliedPromotions?.total === 'number'
    ? props.order?.appliedPromotions?.total || 0
    : props.order?.total || 0
})

const orderSecondaryTotal = computed(() => {
  return typeof props?.order?.appliedPromotions?.total === 'number'
    ? props.order?.total || 0
    : null
})
</script>
